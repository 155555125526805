import axios from "axios";
import { ENDPOINT } from "../constant/environment";
import { API_HEADER } from "../constant/common";

export async function editUserAPI(
  id: string,
  name: string,
  email: string,
  phone: string,
  profile_pic: string
) {
  const URL = `${ENDPOINT}/app/v1/users`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    id,
    name,
    email,
    phone,
    profile_pic,
  };
  try {
    const response = await axios.put(URL, body, config);
    return response?.data.statusCode;
  } catch (error) {
    // console.log(error);
  }
}

export async function deleteUserApi(
  userId: string,
  isLoading?: any
): Promise<any> {
  const URL = `${ENDPOINT}/app/v1/users/${userId}`;
  const config = {
    headers: API_HEADER,
    // signal: controller.signal,
  };
  try {
    if (isLoading) isLoading(true);
    const response = await axios.delete(URL, config);
    if (isLoading) isLoading(false);
    return response?.data;
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      // console.log("ERROR STATUS", error.response.status);
      // console.log("ERROR", error.response);
    }
    if (isLoading) isLoading(false);
    return null;
  }
}
