import React, { useCallback, useContext, useState } from "react";
import { Button, Stack } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import EditAccount from "../../../routes/editAccount/EditAccount";
import { StateContext } from "../../../context/globalContext/context";
import styles from "./editAccountDialog.module.scss";
import { deleteUserApi } from "../../../api/editUser";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { logoutAPI } from "../../../api/logout";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { LOGGED_OUT } from "../../../constant";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

export default function EditAccountDialog() {
  const [showEditModal, setShowEditModal] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  // Context
  const state = useContext(StateContext);
  const {
    loginCreateAccountState,
    setNav,
    userDetails,
    setLoginCreateAccountState,
    setSignInClick,
    setStartingValue,
    setDestValue,
    setClimate,
    setPopulation,
    setExperience,
    setAccomodations,
    setFoodPreferences,
    setMonths,
    setSelectedDestFlag,
    setUserDetails,
    setTripForNDays,
    setIsLoggedIn,
    setBudget,
    setScreen,
    setFirstScreen,
    setUserInfo,
  }: any = state;

  const { id: userId = "" } = userDetails || {};

  function handleCancel() {
    setSignInClick(false);
    // setSaveScreenState(false)
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    // console.log(loginCreateAccountState);
  }

  const handleLogoutClick = useCallback(async () => {
    const response = await logoutAPI(userDetails.id);

    if (response?.statusCode === 200) {
      // Clear state
      // toastMessage.success("Success", "Logout successful");
      setScreen("");
      setFirstScreen("");
      navigate("/");
      window.localStorage.removeItem("JWT");
      const resetUserInfo = {
        id: "",
        name: "",
        email: "",
        phone: "",
        profile_pic: "",
        loggedIn: false,
        role: 0,
      };

      setUserInfo(resetUserInfo);
      setUserDetails(resetUserInfo);
      setIsLoggedIn(false);

      setNav("LANDING_PAGE");
      setSelectedDestFlag(false);
      setStartingValue({ value: "", flag: false });
      setDestValue({ value: "", flag: false });

      const resetState = (
        setter: React.Dispatch<React.SetStateAction<any[]>>
      ) => setter([]);

      [
        setClimate,
        setPopulation,
        setExperience,
        setFoodPreferences,
        setAccomodations,
        setMonths,
        setBudget,
        setTripForNDays,
      ].forEach(resetState);

      // closeDrawer();

      // Clear localStorage and sessionStorage
      const keysToRemove = [
        "_starting",
        "_destination",
        "_extra_meta",
        "_international",
        "_select_dest_flag",
        "shared_itinerary_id",
        "_itinerary_details_from_list",
        "_itinerary_details",
        "_shared_itinerary_id",
        "_sliderValue",
      ];
      keysToRemove.forEach((key) => {
        window.localStorage.removeItem(key);
        window.sessionStorage.removeItem(key);
      });
    }

    ReactGA.event({
      category: LOGGED_OUT.CATEGORY,
      action: LOGGED_OUT.ACTION,
      label: ` ${LOGGED_OUT.LABEL}  `,
    });

    /**
     * post message data
     */

    /**
     * post message data
     */
    const iframe: HTMLIFrameElement | null =
      document.querySelector("#app_domain");
    const wind = iframe?.contentWindow;
    wind?.postMessage(JSON.stringify({}), "*");

    if (window && window.parent) {
      // console.log("Window.parent", window.parent);
      const message = {
        action: "logout",
        data: {},
      };
      // console.log("MESSAGE", message);
      window.parent.postMessage(JSON.stringify(message)); // or specify a specific origin instead of "*"
    }
  }, [
    navigate,
    setAccomodations,
    setBudget,
    setClimate,
    setDestValue,
    setExperience,
    setFirstScreen,
    setFoodPreferences,
    setIsLoggedIn,
    setMonths,
    setNav,
    setPopulation,
    setScreen,
    setSelectedDestFlag,
    setStartingValue,
    setTripForNDays,
    setUserDetails,
    setUserInfo,
    userDetails?.id,
  ]);

  const deleteAccount = useCallback(async () => {
    if (!userId) return;
    const response = await deleteUserApi(userId, setIsDeleting);
    if (response) {
      triggerGAEvent(GA_LABEL_ENUMS.Profile_yes_delete_account_click);
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
      toastMessage.success("User deleted");
      handleLogoutClick();
    }
    if (!response) toastMessage.error("Unable to delete the user");
  }, [
    handleLogoutClick,
    loginCreateAccountState,
    setLoginCreateAccountState,
    userId,
  ]);

  return (
    <Stack
      zIndex={1}
      sx={{
        minHeight: "100dvh",
        mt: { sm: 0, xs: Number(`${showEditModal ? 2 : 0}`) },
        justifyContent: {
          sm: "center",
          md: "center",
          lg: "center",
          // xs: "center",
        },
      }}
      spacing={6}
      alignItems="center"
      justifySelf="center"
      alignSelf="center">
      {showEditModal ? (
        <>
          <Stack
            sx={{
              width: { xs: "100vw" },
              maxWidth: { md: "350px" },
            }}>
            <Stack
              sx={{ width: "100%" }}
              px={{ xs: 3, sm: 3, md: 0, lg: 0, xl: 0 }}>
              <Stack alignSelf="end" mb={2} mr={1.2}>
                <img
                  onClick={handleCancel}
                  style={{ width: "25px", cursor: "pointer" }}
                  src={updateCloseIcon}
                  alt="cross"
                />
              </Stack>

              <Stack
                sx={{
                  width: "100%",
                  maxHeight: {
                    sm: "80vh",
                    xs: "80vh",
                    md: "100%",
                    lg: "100%",
                  },
                  overflow: "auto",
                  borderRadius: "8px",
                  backgroundColor: "#f8f8f8",
                }}>
                <EditAccount />
              </Stack>
            </Stack>
          </Stack>
          <Button
            onClickCapture={() => {
              triggerGAEvent(GA_LABEL_ENUMS.home_delete_account_click);
              setShowEditModal(false);
            }}
            size="small"
            sx={{
              mb: { xs: "80px", sm: 4, md: 0 },
              bgcolor: "#BF150D",
              color: "#fff",
              borderRadius: "46.5px",
              boxShadow: "none",
              textTransform: "none",
              fontFamily: "Lato",
              fontWeight: "bold",
              fontSize: { xs: "2.694vw", sm: 12 },
              pt: 0.5,
              pb: 0.5,
              pl: { xs: 2, sm: 2, md: 2 },
              pr: { xs: 2, sm: 2, md: 2 },
              "&:hover": {
                bgcolor: "#BF150D",
                color: "#fff",
              },
              width: "max-content",
              alignSelf: "center",
            }}
            variant="text">
            DELETE ACCOUNT
          </Button>
        </>
      ) : (
        <div className={styles.container}>
          <span className={styles.title}>Are you sure?</span>
          ••••••••••
          <span className={styles.subTitle}>
            Your account and all related
            <br /> data will be lost.
          </span>
          <div className={styles.btnCard}>
            <button
              className={styles.cancelBtn}
              onClick={() => {
                triggerGAEvent(GA_LABEL_ENUMS.Profile_delete_cancel_click);
                setShowEditModal(true);
              }}>
              Cancel
            </button>
            <button className={styles.discBtn} onClick={() => deleteAccount()}>
              YES, Delete Account
            </button>
          </div>
        </div>
      )}
    </Stack>
  );
}
