import { Chip } from "@mui/material";
import { ButtonSizes, ButtonVariants } from "../../../enums";
import styles from "./act.module.scss";
import { useEffect, useState } from "react";
import { localDataTypes } from "../../../types";

interface iProps {
  selectedAccomodation: string[];
  setSelectedAccomodation: React.Dispatch<React.SetStateAction<string[]>>;
  setIsFormUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

function Accommodations({
  selectedAccomodation,
  setSelectedAccomodation,
  setIsFormUpdated,
}: iProps): JSX.Element {
  const [accommodationData, setAccommodationData] = useState<localDataTypes[]>(
    []
  );
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };
  // const handleChipClick = (activity: { id: number; data: string }) => {
  //   if (
  //     selectedAccomodation.some((selected) => selected.data === activity.data)
  //   ) {
  //     setSelectedAccomodation(
  //       selectedAccomodation.filter(
  //         (selected) => selected.data !== activity.data
  //       )
  //     );
  //   } else {
  //     setSelectedAccomodation([...selectedAccomodation, activity]);
  //   }
  // };

  const handleChipClick = (activity: localDataTypes) => {
    if (selectedAccomodation.includes(activity.data)) {
      setSelectedAccomodation(
        selectedAccomodation.filter((food) => food !== activity.data)
      );
      
    } else {
      setSelectedAccomodation([...selectedAccomodation, activity.data]);
      setIsFormUpdated(true);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem("_all_category");
    const data = storedData ? JSON.parse(storedData) : [];
    if (data) {
      const accData = data.find(
        (item: { name: string }) => item.name === "Accomodations"
      );

      if (accData && accData.tag) {
        setAccommodationData(accData.tag);
      }
    }
  }, []);

  return (
    <div className={styles.main}>
      <span className={styles.title}>Accommodations</span>
      <div className={styles.chipCard}>
        {accommodationData &&
          accommodationData
            .slice(0, showAll ? accommodationData.length : 6)
            .map((activity, index) => (
              <Chip
                label={activity.data}
                size={ButtonSizes.Medium}
                variant={ButtonVariants.Outlined}
                onClick={() => handleChipClick(activity)}
                key={index}
                className={
                  selectedAccomodation.includes(activity.data)
                    ? styles.activeChip
                    : styles.chip
                }
                style={{
                  backgroundColor:
                    selectedAccomodation.includes(activity.data) === true
                      ? "#A4D1F0"
                      : "#ffff",
                }}
              />
            ))}
      </div>
      {showAll ? (
        <span className={styles.seeMore} onClick={handleSeeMoreClick}>
          See less
        </span>
      ) : (
        <span className={styles.seeMore} onClick={handleSeeMoreClick}>
          See more
        </span>
      )}
    </div>
  );
}

export default Accommodations;
