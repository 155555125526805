import React, { ReactNode } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./propertyPopover.module.scss";
import "react-calendar/dist/Calendar.css";
import { starSharpSolid } from "../../../../constant";

export default function PropertyPopoverPopover({
  popoverTrigger,
  isPopoverOpen,
  setIsPopoverOpen,
  handleApplyButton,
  selectedValues,
  setSelectedValues,
}: {
  popoverTrigger: ReactNode;
  isPopoverOpen: boolean;
  selectedValues: number[];
  setSelectedValues: React.Dispatch<React.SetStateAction<number[]>>;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyButton: () => void;
}) {
  const inputData = [1, 2, 3, 4, 5];

  // const handleInputValueSelection = (selectedValue: number) => {
  //   setInputValue(selectedValue);
  // };

  const handleInputValueSelection = (selectedValue: number) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(selectedValue)) {
        return prevSelectedValues.filter((value) => value !== selectedValue);
      } else {
        return [...prevSelectedValues, selectedValue];
      }
    });
  };

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <div className={styles.content}>
            {inputData.map((i) => (
              <div
                className={`${selectedValues.includes(i) ? styles.selectedCard : styles.starCards}`}
                onClick={() => handleInputValueSelection(i)}>
                <p className={styles.num} key={i}>
                  {i}
                </p>
                <img src={starSharpSolid} alt="" className={styles.starIcon} />
              </div>
            ))}
          </div>
        </div>
      }
      handleApplyButton={handleApplyButton}
    />
  );
}
