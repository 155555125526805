import React from "react";
import CustomDialog from "../../../component/reusableComponent/customDialog/CustomDialog";
import styles from "./messagePopupDialog.module.scss";
import ChatContent from "../../topDestination/chatContent/ChatContent";

export default function MessagePopupDialog({
  open,
  onClose,
  message= "",
}: {
  open: boolean;
  onClose?: any;
  message: string;
}) {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <div className={styles.dialogContainer}>
        <ChatContent message={message} />
      </div>
    </CustomDialog>
  );
}
