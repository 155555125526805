import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./myTrips.module.scss";
import { MY_TRIP_TABS, my_trips_tabs } from "./myTripsConstants";
import TabsContainer from "../reusableComponent/tabsContainer/tabsContainer";
import { ROUTES } from "../../constant";
import EditTripModal from "./components/editTripModal/editTripModal";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import SavedTrips from "./components/savedTrips/savedTrips";
import { ScreenTypes } from "../../enums";
import UpcomingTrips from "./components/upcomingTrips/UpcomingTrips";
import PastCancelledTrips from "./components/pastCancelledTrips/PastCancelledTrips";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import PastTrips from "./components/pastTrip/PastTrips";

function MyTrips(): JSX.Element {
  const globalContext = useContext(StateContext);
  const {
    setPrevRoute,
    isMobile,
    setScreen,
    setTripOrItinearyId,
    myTripActiveTab,
    setMyTripActiveTab,
    // mytripScrollContainerRef
  }: iGlobalContext = globalContext;
  const [openEditModal, setOpenEditModal] = useState(false);
  const navigate = useNavigate();
  const [editTripNameData, setEditTripNameData] = useState<any>(null);
  const [isTripNameEdited, setIsTripNameEdited] = useState(false);

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.my_trips_page_load);
  }, []);

  // console.log("myTripActiveTab", myTripActiveTab);

  const handleTripClick = useCallback((trip: any) => {
    setTripOrItinearyId("c0be0e8f-635e-4cfd-9c94-cc9d1d385256");
    if (isMobile) {
      setPrevRoute(ROUTES.MyTrips);
      navigate(ROUTES.TripDetails);
    } else {
      setScreen(ScreenTypes.TripDetails);
    }
  }, []);

  const renderTripCard = useCallback(() => {
    const handleEditTripName = (trip: any, item: string) => {
      if (item === MY_TRIP_TABS.UPCOMING)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_upcoming_trips_edit_click);
      if (item === MY_TRIP_TABS.PAST)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_past_trips_edit_click);
      if (item === MY_TRIP_TABS.CANCELLED)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_cancelled_trips_edit_click);
      if (item === MY_TRIP_TABS.SAVED)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_saved_trips_edit_click);
      setOpenEditModal(true);
      // console.log(trip, "CURRENT");
      setEditTripNameData({ ...trip });
    };

    switch (myTripActiveTab) {
      case MY_TRIP_TABS.UPCOMING: {
        return (
          <UpcomingTrips
            tripName={tripName}
            editTripNameData={editTripNameData}
            isTripNameEdited={isTripNameEdited}
            handleEditTripName={handleEditTripName}
            mytripScrollContainerRef={mytripScrollContainerRef}
          />
        );
      }
      case MY_TRIP_TABS.PAST: {
        return (
          <PastTrips
            tripName={tripName}
            editTripNameData={editTripNameData}
            isTripNameEdited={isTripNameEdited}
            handleEditTripName={handleEditTripName}
            pastOrCancelled="past"
          />
        );
      }
      case MY_TRIP_TABS.CANCELLED: {
        return (
          <PastCancelledTrips
            tripName={tripName}
            editTripNameData={editTripNameData}
            isTripNameEdited={isTripNameEdited}
            handleEditTripName={handleEditTripName}
            pastOrCancelled="cancelled"
          />
        );
      }
      case MY_TRIP_TABS.SAVED: {
        return (
          <SavedTrips
            tripName={tripName}
            editTripNameData={editTripNameData}
            isTripNameEdited={isTripNameEdited}
            handleEditTripName={handleEditTripName}
          />
        );
      }
      default:
        <div />;
    }
  }, [isTripNameEdited, myTripActiveTab]);

  const handleSetActiveTab = useCallback(
    (item: string) => {
      if (item === MY_TRIP_TABS.UPCOMING)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_upcoming_tab_click);
      if (item === MY_TRIP_TABS.PAST)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_past_tab_click);
      if (item === MY_TRIP_TABS.CANCELLED)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_cancelled_tab_click);
      if (item === MY_TRIP_TABS.SAVED)
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_saved_tab_click);
      setMyTripActiveTab(item);
    },
    [setMyTripActiveTab]
  );
  const mytripScrollContainerRef = useRef<any>();
  const [tripName, setTripName] = useState("");

  return (
    <div className={styles.myTripsContainer} ref={mytripScrollContainerRef}>
      <p className={styles.today}>My Trips</p>
      <TabsContainer
        className={styles.tabsClass}
        activeTab={myTripActiveTab}
        setActiveTab={handleSetActiveTab}
        tabsArr={my_trips_tabs}
      />
      {renderTripCard()}
      <EditTripModal
        tripName={tripName}
        setTripName={setTripName}
        trip={editTripNameData}
        setIsTripNameEdited={setIsTripNameEdited}
        isOpen={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      />
    </div>
  );
}

export default MyTrips;
