import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import { getHistoryAPICall } from "../../api/getHistory";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { apiFailedTryAgain, getConfigMessage } from "../../utils";
import { APP_CONFIG } from "../../constant/common";
import { sortDatesInAscendingOrder } from "../../utils/dateUtils";
import { iHistoryContext } from "./interface";
import { addHistoryAPI } from "../../api/saveHistory";
import { useInView } from "react-intersection-observer";
const HistoryContext = createContext({} as iHistoryContext);
export const HistoryContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [todayHistory, setTodayHistory] = useState<any>([]);
  const [yesterdayHistory, setYesterdayHistory] = useState<any>([]);
  const [oneWeekAgoHistory, setOneWeekAgoHistory] = useState<any>([]);
  const [lastOneMonth, setLastOneMonth] = useState<any>([]);
  const globalContext = useContext(StateContext);
  const { userDetails, appConfig }: iGlobalContext = globalContext;

  const [hasMoreHistory, setHasMoreHistory] = useState(true);
  const [skipLimitHistory, setSkipLimitHistory] = useState({
    skip: 0,
    limit: 50,
  });
  const { ref: historyPaginationLoaderRef, inView } = useInView({});

  useEffect(() => {
    console.log("VIEW", inView, skipLimitHistory);
    if (inView) getHistory();
  }, [inView]);

  async function getHistory(reload?: boolean, openWithSideBar?: boolean) {
    if ((!userDetails?.id || !hasMoreHistory) && !openWithSideBar ) return;
    
    setIsLoading(true);
    const response = await getHistoryAPICall(
      userDetails.id,
      reload ? 0 : skipLimitHistory?.skip,
      reload ? skipLimitHistory?.skip : skipLimitHistory?.limit
    );
    if (response) {
      setIsLoading(false);
      // // console.log(response);
    }
    if (!response) {
      toastMessage.error(
        "OOPS!",
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return;
    }
    // // console.log(response);
    const yesterday = new Date(
      new Date().setDate(new Date().getUTCDate() - 2)
    ).getTime();
    const now = new Date();
    const startOfToday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate()
    ).getTime();
    const endOfToday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      23,
      59,
      59,
      999
    ).getTime();

    const startOfYesterday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - 1
    ).getTime();
    const endOfYesterday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - 1,
      23,
      59,
      59,
      999
    ).getTime();

    // Calculate the start of the day one week ago
    const startOfWeekAgo = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - 7,
      0,
      0,
      0,
      0
    ).getTime();

    if (response?.length === 0) {
      setHasMoreHistory(false);
    }
    setSkipLimitHistory({
      skip: skipLimitHistory.skip + skipLimitHistory.limit,
      limit: 10,
    });

    setTodayHistory([
      ...todayHistory,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() >= startOfToday &&
            new Date(ele.createdAt).getTime() <= endOfToday
        ),
        "createdAt"
      ),
    ]);
    setYesterdayHistory([
      ...yesterdayHistory,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() >= startOfYesterday &&
            new Date(ele.createdAt).getTime() <= endOfYesterday
        ),
        "createdAt"
      ),
    ]);
    setOneWeekAgoHistory([
      ...oneWeekAgoHistory,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() <= yesterday &&
            new Date(ele.createdAt).getTime() >= startOfWeekAgo
        ),
        "createdAt"
      ),
    ]);
    setLastOneMonth([
      ...lastOneMonth,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) => new Date(ele.createdAt).getTime() <= startOfWeekAgo
        ),
        "createdAt"
      ),
    ]);
  }

  /**
   * Save history
   */
  const [callSaveHistoryAPIFlag, setCallSaveHistoryAPIFlag] = useState(false);
  async function saveHistory(chatDataArgs: any) {
    if (!chatDataArgs || !userDetails?.id) return;
    const historyData = {
      id: chatDataArgs[0]?.id || "",
      userId: userDetails?.id || "",
      history: chatDataArgs,
    };
    if (userDetails?.id) {
      addHistoryAPI(historyData);
      // const addHistoryResponse = await apiFailedTryAgain(
      // );
      // if (addHistoryResponse) {
      //   // console.log("HISTORY IS SAVED");
      // }
    }
  }

  const value = {
    hasMoreHistory,
    historyPaginationLoaderRef,
    getHistory,
    isLoading,
    todayHistory,
    yesterdayHistory,
    oneWeekAgoHistory,
    lastOneMonth,
    saveHistory,
    callSaveHistoryAPIFlag,
    setCallSaveHistoryAPIFlag,
  };
  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
export const useHistoryContext = () => useContext(HistoryContext);
