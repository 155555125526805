import styles from "./traveler.module.scss";

interface iProps {
  count: number;
  name: string;
  phone?: string;
  gender: string;
  meal: string;
  email?: string;
  skyMiles?: string;
  specialNeeds?: string[];
}

function TravelerDetails({
  count,
  name,
  phone,
  gender,
  meal,
  email,
  skyMiles,
  specialNeeds,
}: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <p className={styles.title}>Traveler {count + 1}</p>
        <section className={styles.travelerCard}>
          <div className={styles.infoCard}>
            <p className={styles.name}>{name}</p>
            <p className={styles.gender}>{gender.toLowerCase()}</p>
            {skyMiles && (
              <p className={styles.ff}>
                <span className={styles.ffTitle}>FF:</span>
                <span className={styles.skyMiles}> {skyMiles}</span>
              </p>
            )}
            {meal && (
              <p className={styles.ff}>
                <span className={styles.ffTitle}>Meal:</span>{" "}
                <span className={styles.capitalize}>{meal.toLowerCase()}</span>
              </p>
            )}
          </div>
          <div className={styles.infoCard}>
            <p className={styles.name}>{email}</p>
            <p className={styles.name}>{phone}</p>
            {specialNeeds && (
              <div className={styles.specialCard}>
                <p className={styles.special}>Special Needs</p>
                {specialNeeds.map((i, index) => (
                  <ul key={index} className={styles.list}>
                    <li className={styles.listTitle}>{i}</li>
                  </ul>
                ))}
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default TravelerDetails;
