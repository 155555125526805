import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import BackButton from "../../component/backBtn/backBtn";
import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";
import { personWalkingLuggage, ROUTES } from "../../constant";
import styles from "./traveler.module.scss";
import TravelerInformationForma from "../../component/booking/travelerInformation/travelerInformationForm";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { useRefLocalStorage } from "../../hooks/useRefLocalStorage";
import { toastMessage } from "../../helpers/toast/toastMessage";
import toast from "react-hot-toast";
import { APP_CONFIG, emailRegex, phoneRegex } from "../../constant/common";
import { ScreenTypes } from "../../enums";
import {
  bookHotelRoomAPI,
  iHotelRoomBookArgs,
  PassengerDetail,
} from "../../api/hotelDetailsAPI";
import { bookFlightAPI } from "../../api/booking/bookFlightAPI";
import BookedSuccessDialog from "../../component/dialog/bookedSuccessDialog/BookedSuccessDialog";
import {
  getConfigMessage,
  updateDaywiseTripDate,
  updateDaywiseTripDateForMetaData,
} from "../../utils";
import { getBookedFlightDetailsAPI } from "../../api/booking/getBookedFlightDetailsAPI";
import { saveBookedTransactionDetailsAPI } from "../../api/booking/saveBookedTransactionDetailsAPI";
import { gender, mealPref, options } from "../../constant/dummyData";
import axios from "axios";
import { ENDPOINT } from "../../constant/environment";
import { bookFlightHotelAPI } from "../../api/booking/bookFlightHotelAPI";

function TravelerInformation(): JSX.Element {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    flightAvailabilityData,
    userDetails,
    isMobile,
    setScreen,
    // selectedHotelRoom,
    selectedFlightOptions,
    appConfig,
    selectedChatData,
    setSelectedChatData,
    selectedHotelRoomArr,
    setTripOrItinearyId,
    setUserDetails,
    userInfo,
    setMyTripActiveTab,
    setFirstScreen,
  }: iGlobalContext = state;
  const navigate = useNavigate();
  const handleBackButton = () => {
    if (!isMobile) {
      setScreen(ScreenTypes.Booking);
      return;
    }
    navigate(ROUTES.Booking);
  };

  const [isLoading, setIsloading] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const { adultsCount, childrenCount, seniorsCount } = bookingDetails;

  let travelerCount = adultsCount + childrenCount + seniorsCount;
  const [showErrorMessageFlag, setShowErrorMessageFlag] = useState(false);

  const validateForm = () => {
    travelerInfoArrayDataRef?.current?.forEach((ele: any, index: number) => {});
    for (let i = 0; i < travelerInfoArrayDataRef?.current?.length; i++) {
      const ele = travelerInfoArrayDataRef?.current[i];
      if (ele?.firstName === "") {
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} first name`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (ele?.lastName === "") {
        const emptyFieldKey =
          Object.keys(ele).find((key) => ele[key] === "") || "";
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} last name`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (
        ele?.dateOfBirthMonth === "" ||
        ele?.dateOfBirth === "" ||
        ele?.dateOfYear === ""
      ) {
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} DOB`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (Number(ele?.dateOfYear) < Number(new Date().getFullYear() - 150)) {
        toast.dismiss();
        toastMessage.error(`DOB year is not valid`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (ele?.gender === "") {
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} gender`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (ele?.email === "") {
        toast.dismiss();
        toastMessage.error(`Enter the Traveler ${i + 1} email address`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (!emailRegex.test(ele?.email)) {
        toast.dismiss();
        toastMessage.error(`Traveler ${i + 1} email address is not valid`);
        setShowErrorMessageFlag(true);
        return false;
      }
      if (!phoneRegex.test(ele?.phoneNumber)) {
        toast.dismiss();
        toastMessage.error(`Traveler ${i + 1} phone number is not valid`);
        setShowErrorMessageFlag(true);
        return false;
      }
    }
    setShowErrorMessageFlag(false);
    return true;
  };

  const parsedTravellerInformation = localStorage.getItem(
    "_traveler_info_array"
  )
    ? JSON.parse(localStorage.getItem("_traveler_info_array") || "")
    : [];

  /**
   * Booking Flight API
   */
  const [bookedFlightSuccess, setBookedFlightSuccess] = useState({
    openDialog: false,
    bookedId: "",
  });

  const bookFlightHotel = async () => {
    const passengerDetails = travelerInfoArrayDataRef?.current?.map(
      (ele: any) => {
        let localMealPreference = ele?.mealPref !== "" ? [ele?.mealPref] : [];
        // console.log("matchedMeals", localMealPreference);
        if (localMealPreference.length === 0) {
          const matchedMeals: any =
            userDetails?.preferences?.foodPreferences?.filter((meal: any) =>
              flightAvailabilityData?.extraServiceDetails?.mealsPreference.some(
                (item: any) =>
                  item.label.toLowerCase().includes(meal.toLowerCase())
              )
            );
          localMealPreference = matchedMeals || [];
          // console.log({ matchedMeals }, { localMealPreference });
        }
        return {
          paxType: "ADT",
          gender: ele?.gender?.toLowerCase() || "male",
          leadPax: true,
          title: ele?.gender === "MALE" ? "Mr" : "Ms",
          firstName: ele?.firstName,
          lastName: ele?.lastName,
          email: ele?.email,
          countryCode: ele?.countryCode
            ? ele?.countryCode.substring(1, ele.countryCode.length)
            : "1",
          mobileNumber: ele?.phoneNumber,
          dateOfBirth: `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`, // YYYY-MM-DD
          infantData: {
            title: "Mr",
            firstName: "Azam",
            lastName: "Mohammed",
            dateOfBirth: "12-13-2023",
            document: {
              type: "Passport",
              number: "ZN2323111",
              nationality: "AF",
              issuedCountry: "AF",
              issuedDate: "12-13-2023",
              expiryDate: "06-06-2024",
            },
          },
          document: {
            type: "Passport",
            number: "ZNC123433",
            nationality: "AF",
            issuedCountry: "AF",
            issuedDate: "1996-12-13",
            expiryDate: "2033-06-06",
          },
          operationalReference: {
            specialAssistance: "",
          },
          frequentFlyerinfo: {
            flyerNumber: "",
            frequentFlyerProgram: ele?.programName || null,
            airline: "",
          },
          meals: localMealPreference || "",
        };
      }
    );
    let payload = {
      clientId: "uuid",
      userId: 1012,
      paymentDetails: {
        successUrl: "https://app.zenvoya.com/success",
        cancelUrl: "https://app.zenvoya.com/cancel",
        amount: "100.00",
        currency: "USD",
        remarks: "",
      },
      flight: flightAvailabilityData.transactionId,
      hotel: [
        {
          prebookId: "x3JEuy0iA",
          sessionId: "TQJXBd1vDOl5hFgV",
        },
        {
          prebookId: "eDlSc-Pua",
          sessionId: "qdoN0QAfJDBL22Jh",
        },
      ],
      passengerDetails: passengerDetails,
    };
    const response = await bookFlightHotelAPI(payload);
    if (response?.statusCode === 200) {
      setBookedFlightSuccess({
        ...bookedFlightSuccess,
        openDialog: true,
        bookedId: response?.data?.itineraryID || "",
      });
      const { itineraryID, transactionId } = response?.data;
      // console.log(itineraryID, transactionId);
      await saveBookedTransactionDetails({
        isFlight: true,
        bookedTransactionId: transactionId,
        bookedItineraryId: itineraryID,
        startDate: bookingDetails.travelFromDate,
        endDate: bookingDetails.travelToDate,
      });
      return true;
    }
  };

  const bookFlight = async () => {
    const passengerDetails = travelerInfoArrayDataRef?.current?.map(
      (ele: any) => {
        let localMealPreference = ele?.mealPref !== "" ? [ele?.mealPref] : [];
        // console.log("matchedMeals", localMealPreference);
        if (localMealPreference.length === 0) {
          const matchedMeals: any =
            userDetails?.preferences?.foodPreferences?.filter((meal: any) =>
              flightAvailabilityData?.extraServiceDetails?.mealsPreference.some(
                (item: any) =>
                  item.label.toLowerCase().includes(meal.toLowerCase())
              )
            );
          localMealPreference = matchedMeals || [];
          // console.log({ matchedMeals }, { localMealPreference });
        }
        return {
          passengerId: 1234,
          title: ele?.gender === "MALE" ? "Mr" : "Ms",
          paxType: "ADT",
          leadPax: true,
          firstName: ele?.firstName,
          lastName: ele?.lastName,
          email: ele?.email,
          countryCode: ele?.countryCode
            ? ele?.countryCode.substring(1, ele.countryCode.length)
            : "1",
          phoneNumber: ele?.phoneNumber,
          dateOfBirth: `${ele?.dateOfYear}-${options.find((option) => option.label === ele?.dateOfBirthMonth)?.value}-${ele?.dateOfBirth?.length === 1 ? "0" + ele?.dateOfBirth : ele?.dateOfBirth}`, // YYYY-MM-DD
          infantData: {},
          document: {
            type: "Passport",
            number: "ZNC123433",
            nationality: "AF",
            issuedCountry: "AF",
            issuedDate: "1996-12-13",
            expiryDate: "2033-06-06",
          },
          // operationalReference: {
          //   specialAssistance: "",
          // },
          operationalReference: {
            specialAssistance: null,
            // Object.keys(ele?.selectedOptions).filter(
            //   (key) => ele?.selectedOptions[key]
            // ) || [],
          },
          frequentFlyerinfo: {
            flyerNumber: "",
            frequentFlyerProgram: ele?.programName || null,
            airline: "",
          },
          baggage: [],
          meals: localMealPreference || "",
          seats: [],
        };
      }
    );
    let payload = {
      transactionId: flightAvailabilityData.transactionId,
      clientId: "uuid",
      userId: 1012,
      passengerDetails: passengerDetails,
    };
    // console.log("payload", travelerInfoArrayDataRef?.current);
    // console.log({ payload });

    const response = await bookFlightAPI(payload);
    // console.log(response?.data);
    if (response?.statusCode === 200) {
      setBookedFlightSuccess({
        ...bookedFlightSuccess,
        openDialog: true,
        bookedId: response?.data?.itineraryID || "",
      });
      const { itineraryID, transactionId } = response?.data;
      // console.log(itineraryID, transactionId);
      await saveBookedTransactionDetails({
        isFlight: true,
        bookedTransactionId: transactionId,
        bookedItineraryId: itineraryID,
        startDate: bookingDetails.travelFromDate,
        endDate: bookingDetails.travelToDate,
      });
      return true;
    }
  };

  /**
   * Form data
   */
  const [travelerInfoArrayDataRef, setTravelerInfoArrayDataRef] =
    useRefLocalStorage(
      "_traveler_info_array",
      travelerCount === parsedTravellerInformation?.length
        ? parsedTravellerInformation
        : Array(travelerCount).fill({
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirthMonth: "",
            dateOfBirth: "",
            dateOfYear: "",
            gender: "",
            email: userDetails?.email || "",
            countryCode: "",
            phoneNumber: userDetails?.phone || userInfo?.phone || "",
            programName: "",
            frequentFlyerNumber: "",
            passportNumber: "",
            mealPref: "",
            selectedOptions: {},
          })
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveBookedTransactionDetails = async ({
    isFlight,
    bookedTransactionId,
    bookedItineraryId,
    startDate,
    endDate,
  }: {
    isFlight: boolean;
    bookedTransactionId: string;
    bookedItineraryId: string;
    startDate: string;
    endDate: string;
  }) => {
    const itineraryData = updateDaywiseTripDate(
      selectedChatData?.results,
      startDate
    );
    const metaData: any = {
      // chatData: chatData,
      selectedChatData: {
        ...selectedChatData,
        destinationList: updateDaywiseTripDateForMetaData(
          selectedChatData?.destinationList,
          startDate
        ),
        prompt: [],
        postText: "",
        preText: "",
        results: itineraryData || selectedChatData?.results,
      },
    };

    let travelerInfo = {
      adultsCount,
      childrenCount,
      seniorsCount,
      travelersDetailsList: travelerInfoArrayDataRef?.current || [],
    };

    const saveItineraryDetailsResponse = await saveBookedTransactionDetailsAPI(
      isFlight,
      selectedChatData.id,
      userDetails.id,
      metaData,
      "booked",
      bookedTransactionId,
      bookedItineraryId,
      startDate,
      endDate,
      travelerInfo,
      itineraryData || selectedChatData?.results
    );
    if (
      saveItineraryDetailsResponse?.message === "Itineraries has been saved"
    ) {
      // console.log("DONE");
    } else {
      // console.log("Failed");
    }
  };

  /**
   * Booking hotel API
   */
  // const { preBookId = "", sessionId = "" } = selectedHotelRoom || {};
  // const {} = selectedHotelRoomArr || {};

  const bookHotel = useCallback(async () => {
    // // console.log("Booking hotel");
    // if (!preBookId) return;
    const areAllRoomsSelected = selectedHotelRoomArr.every((eachHotel) => {
      if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
        // console.log("entered here");
        toast.dismiss();
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      if (
        !eachHotel?.selectedHotelRoom?.preBookId ||
        !eachHotel?.selectedHotelRoom?.sessionId
      ) {
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      return true;
    });
    // console.log(areAllRoomsSelected, "areallrooms");
    if (!areAllRoomsSelected) return;
    const response = await bookHotelRoomAPI(
      {
        clientId: "4f9b4522-4810-4718-a1b1-f08322bc0bd1",

        userId: 1,
        passengerDetails: travelerInfoArrayDataRef?.current?.map(
          (traveler: any): PassengerDetail => {
            const {
              countryCode = null,
              email = null,
              firstName = null,
              lastName = null,
              phoneNumber = null,
              gender = null,
            } = traveler || {};
            return {
              countryCode: countryCode
                ? countryCode.substring(1, countryCode.length)
                : "1",
              email,
              firstName,
              lastName,
              leadPax: true,
              passengerId: 1234,
              paxType: "ADT",
              phoneNumber,
              room_no: 1,
              title: gender === "FEMALE" ? "Mrs" : "Mr",
            };
          }
        ),
        hotels: selectedHotelRoomArr?.map((item): iHotelRoomBookArgs => {
          const { preBookId = "", sessionId = "" } =
            item?.selectedHotelRoom || {};
          return {
            prebookId: preBookId,
            sessionId,
          };
        }),
      },
      setIsBooking
    );
    // console.log({ response });
    if (response) {
      // // console.log(response, "response from hotel book");
      const { itineraryID, transactionId } = response;
      // console.log({ response });
      setBookedFlightSuccess({
        ...bookedFlightSuccess,
        openDialog: true,
        bookedId: response?.itineraryID || "",
      });
      await saveBookedTransactionDetails({
        isFlight: false,
        bookedTransactionId: transactionId,
        bookedItineraryId: itineraryID,
        startDate: bookingDetails.travelFromDate,
        endDate: bookingDetails.travelToDate,
      });
      // return;
      return true;
    }
  }, [
    bookedFlightSuccess,
    bookingDetails?.travelFromDate,
    bookingDetails?.travelToDate,
    saveBookedTransactionDetails,
    selectedHotelRoomArr,
    travelerInfoArrayDataRef,
    userDetails?.id,
  ]);

  /**
   * Handling on clicking Continue for Booking Flight or Hotel
   */
  const isThisAMulticityTrip =
    selectedChatData.destinationList &&
    selectedChatData.destinationList.length > 1;

  const handleSubmit = async () => {
    toast.loading("Booking in progress...");
    setIsloading(true);
    const isValid = validateForm();
    if (!isValid) {
      setIsloading(false);
      return;
    }
    if (
      selectedFlightOptions.hotels &&
      !selectedFlightOptions.flights
      // && !isThisAMulticityTrip
    ) {
      const isComplete = await bookHotel();
      toast.dismiss();

      if (isComplete) console.log("SUCCESS", "Hotel is booked");
      else
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
    } else if (!selectedFlightOptions.hotels && selectedFlightOptions.flights) {
      const isComplete = await bookFlight();
      toast.dismiss();

      if (isComplete) console.log("SUCCESS", "Flight is booked");
      else
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
    } else if (selectedFlightOptions.hotels && selectedFlightOptions.flights) {
      const isCompleteFlightBooked = await bookFlight();
      toast.dismiss();
      if (!isCompleteFlightBooked) {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
        setIsloading(false);
        return;
      }
      const isCompleteHotelBooked = await bookHotel();
      toast.dismiss();
      if (!isCompleteHotelBooked) {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
        setIsloading(false);
        return;
      }
      toast.dismiss();

      if (isCompleteFlightBooked && isCompleteHotelBooked)
        console.log("SUCCESS", "Flight and hotel is booked");
      else
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
    }
    setIsloading(false);
  };

  return (
    <div className={styles.main}>
      <BookedSuccessDialog
        bookedId={bookedFlightSuccess.bookedId}
        open={bookedFlightSuccess.openDialog}
        onClose={() => {
          setBookedFlightSuccess({
            ...bookedFlightSuccess,
            openDialog: false,
          });
          setTripOrItinearyId(selectedChatData?.id);
          if (isMobile) navigate(ROUTES.MyTrips);
          else {
            setScreen("");
            setFirstScreen(ScreenTypes.MyTrips);
          }
          setMyTripActiveTab("UPCOMING");
          toast.dismiss();
        }}
        handleViewItinerary={() => {
          setTripOrItinearyId(selectedChatData?.id);
          if (isMobile) navigate(ROUTES.TripDetails);
          else {
            setFirstScreen(ScreenTypes.TripDetails);
            setScreen("");
          }
          // if (isMobile) navigate(ROUTES.MyTrips);
          // else {
          //   setFirstScreen(ScreenTypes.MyTrips);
          //   setScreen("");
          // }
          toast.dismiss();
          setMyTripActiveTab("UPCOMING");
        }}
      />
      <div className={styles.container}>
        <header className={styles.header}>
          <BackButton onClick={handleBackButton} />
          <p className={styles.title}>Traveler Information</p>
        </header>
        <section className={styles.content}>
          {/* {Array.from({ length: travelerCount }).map((_, index) => ( */}
          {travelerInfoArrayDataRef.current.map((ele: any, index: any) => (
            <CustomAccordion
              className={styles.customStyles}
              summaryClass={styles.summaryClass}
              accordianDetailsClass={styles.accordianDetailsClass}
              defaultExpanded={index === 0}
              headingComponent={
                <div className={styles.heading}>
                  <p
                    className={styles.accordHeading}
                  >{`Traveler ${index + 1}`}</p>
                  <img
                    src={personWalkingLuggage}
                    alt=""
                    className={styles.travelerIcon}
                  />
                </div>
              }
              bodyComponent={
                <TravelerInformationForma
                  frequentFlyerProgram={
                    flightAvailabilityData?.extraServiceDetails
                      ?.frequentFlyerProgram
                  }
                  mealsPreference={
                    flightAvailabilityData?.extraServiceDetails?.mealsPreference
                  }
                  seatsPreference={
                    flightAvailabilityData?.extraServiceDetails?.seatsPreference
                  }
                  specialAssistance={
                    flightAvailabilityData?.extraServiceDetails
                      ?.specialAssistance
                  }
                  formIndex={index}
                  formData={ele}
                  travelerInfoArrayDataRef={travelerInfoArrayDataRef}
                  setTravelerInfoArrayDataRef={setTravelerInfoArrayDataRef}
                  // ref={formRef}
                  showErrorMessageFlag={showErrorMessageFlag}
                  setShowErrorMessageFlag={setShowErrorMessageFlag}
                />
              }
              zeroMarginOnExpand
              borderBottom
            />
          ))}
          <div className={styles.btnCard}>
            <Button
              className={styles.continueBtn}
              fullWidth
              // onClick={handleSubmit}
              onClick={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
              disabled={isLoading}
            >
              Continue
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TravelerInformation;

const bookingPayloadTest = {
  clientId: "uuid",
  userId: 1012,
  paymentDetails: {
    successUrl: "https://app.zenvoya.com/success",
    cancelUrl: "https://app.zenvoya.com/cancel",
    amount: "100.00",
    currency: "USD",
    remarks: "",
  },
  passengerDetails: [
    {
      paxType: "ADT",
      gender: "male",
      leadPax: true,
      title: "Mr",
      firstName: "Azam",
      lastName: "Mohammed",
      email: "azam95mohd@gmail.com",
      countryCode: "93",
      mobileNumber: "8978554727",
      dateOfBirth: "12-13-1995",
      infantData: {
        title: "Mr",
        firstName: "Azam",
        lastName: "Mohammed",
        dateOfBirth: "12-13-2023",
        document: {
          type: "Passport",
          number: "ZN2323111",
          nationality: "AF",
          issuedCountry: "AF",
          issuedDate: "12-13-2023",
          expiryDate: "06-06-2024",
        },
      },
      document: {
        type: "Passport",
        number: "ZNC123433",
        nationality: "AF",
        issuedCountry: "AF",
        issuedDate: "12-13-1996",
        expiryDate: "06-06-2033",
      },
      operationalReference: {
        specialAssistance: "",
      },
      frequentFlyerinfo: {
        flyerNumber: "",
        frequentFlyerProgram: "",
        airline: "",
      },
      meals: [],
    },
  ],
  flight: "MUI5mWLax80J68vD_1",
  hotel: [
    {
      prebookId: "x3JEuy0iA",
      sessionId: "TQJXBd1vDOl5hFgV",
    },
    {
      prebookId: "eDlSc-Pua",
      sessionId: "qdoN0QAfJDBL22Jh",
    },
  ],
};
