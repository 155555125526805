import DialogPopup from "../dialogPopup/dialogPopup";
import Loader from "../loader/Loader";
import styles from "./NewLoader.module.scss";

interface iProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  loaderIcon1?: any;
  text: string;
  loaderIcon2?: any;
  cancelButtonClassName?: string;
}
function NewLoader(props: iProps): JSX.Element {
  const {
    className = "",
    isOpen,
    onClose,
    loaderIcon1,
    loaderIcon2,
    text = "",
    cancelButtonClassName,
  } = props;
  return (
    <DialogPopup className={`${styles.newLoaderContainer}`} isOpen={isOpen}>
      <div className={`${className} ${styles.newLoaderComponent}`}>
        <div className={styles.imageContainer}>
          {loaderIcon1 && (
            <img
              className={styles.loaderIcon}
              src={loaderIcon1}
              alt="loading icon"
            />
          )}
          {loaderIcon2 && (
            <img
              className={styles.loaderIcon}
              src={loaderIcon2}
              alt="loading icon"
            />
          )}
        </div>

        {text && <p className={styles.loaderText}>{text}</p>}
        <Loader />
        <button
          className={`${styles.cancelBtn} ${cancelButtonClassName}`}
          onClick={onClose}>
          Cancel
        </button>
      </div>
    </DialogPopup>
  );
}

export default NewLoader;
