import { useCallback, useContext, useEffect, useState } from "react";
import { AirPort, BookingDetails, ModifiedDataTypes } from "../../../../types";
import AirPortPicker from "../../inputFields/airportPicker/airportPicker";
import DateRangePicker from "../../inputFields/dateRangePicker/dateRangePicker";
import Duration from "../../inputFields/duration/duration";
import RouteAndClass from "../../inputFields/routeAndClassNew/routeAndClass";
import SearchButton from "../../inputFields/searchButton/searchButton";
import styles from "./flight.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import Travelers from "../../inputFields/travelers/travelers";
import PropertyType from "../../inputFields/propertyType/propertyType";
import CityLocation from "../../inputFields/location/location";
import Stepper from "../flightSingleCityInputFields/stepper/stepper";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
interface iProps {
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  searchFlights: () => void;
  inputFieldData: ModifiedDataTypes[];
  searchHotels: () => void;
  getTravelData: () => void;
}

function FlightHotelInputFields({
  bookingDetails,
  setBookingDetails,
  searchFlights,
  inputFieldData,
  searchHotels,
  getTravelData,
}: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const isThisMultiCityTrip =
    selectedChatData?.destinationList?.length > 1 || false;

  const dataTest = selectedChatData?.destinationList?.map((i) => i.tripDetails);

  function transformData(
    inputFieldData: ModifiedDataTypes[],
    bookingDetails: BookingDetails,
    setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>
  ): void {
    const tripData = isThisMultiCityTrip
      ? bookingDetails.travelJourneyData
      : bookingDetails.travelJourneyData.slice(0, 1);
    const updatedAirPortData = tripData
      .filter(
        (journey, index, self) =>
          index ===
          self?.findIndex(
            (t) => JSON?.stringify(t) === JSON?.stringify(journey)
          )
      )
      .map((journey, index) => {
        const arrivalCity = inputFieldData.find(
          (city) =>
            city.city === journey.arrivalCityAddress.split(", ")[0] &&
            city.countryFullName ===
              journey.arrivalCityAddress.split(", ").slice(-1)[0]
        );

        const departureCity = inputFieldData.find(
          (city) =>
            city.city === journey.departureCityAddress.split(", ")[0] &&
            city.countryFullName ===
              journey.departureCityAddress.split(", ").slice(-1)[0]
        );
        return {
          arrival: bookingDetails.airPort[index]?.arrival || "",
          departure: bookingDetails.airPort[index]?.departure || "",
          fromCity: journey.departureCityAddress,
          destinationCity: journey.arrivalCityAddress,
          departureDate: journey.departureDate,
          returnDate: bookingDetails.travelJourneyData[index]?.returnDate || "",
          departureAirportFullName:
            bookingDetails.airPort[index]?.departureAirportFullName || "",
          arrivalAirportFullName:
            bookingDetails.airPort[index]?.arrivalAirportFullName || "",
          arrivalCityLatitude: arrivalCity ? arrivalCity.latitude : "",
          arrivalCityLongitude: arrivalCity ? arrivalCity.longitude : "",
          departureCityLatitude: departureCity ? departureCity.latitude : "",
          departureCityLongitude: departureCity ? departureCity.longitude : "",
          noOfDays: journey.totalDays || 0,
        };
      });
    setBookingDetails((prevBookingDetails) => ({
      ...prevBookingDetails,
      airPort: updatedAirPortData,
    }));
  }

  const checkTravelJourneyData = bookingDetails.travelJourneyData?.some(
    (i) =>
      i.arrivalCityAddress ||
      i.departureCityAddress ||
      i.departureDate ||
      i.flightCheckOut
  );

  const handleUpdateTravelData = useCallback(async () => {
    await getTravelData();
    if (inputFieldData.length > 0 || checkTravelJourneyData) {
      transformData(inputFieldData, bookingDetails, setBookingDetails);
    }
  }, [
    getTravelData,
    inputFieldData,
    checkTravelJourneyData,
    bookingDetails,
    setBookingDetails,
  ]);

  useEffect(() => {
    handleUpdateTravelData();
  }, [checkTravelJourneyData]);

  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const handleFlightHotelInputValidation = useCallback(() => {
    const {
      airPort,
      adultsCount,
      childrenCount,
      seniorsCount,
      travelFromDate,
    } = bookingDetails || {};
    setBookingDetails((prevData) => ({
      ...prevData,
      selectedFlightId: "",
    }));
    // const checkDepartureDate = airPort?.some((flight) => flight?.departureDate);
    const checkDeparture = airPort?.some((flight) => flight.departure);
    const checkArrival = airPort?.some((flight) => flight.arrival);
    if (travelFromDate === "") {
      toastMessage.error("", "Please select a travel date.");
      setEnableBtn(true);
      return;
    }
    if (!checkDeparture) {
      toastMessage.error("", "Please select a departure airport.");
      setEnableBtn(true);
      return;
    }
    if (!checkArrival) {
      toastMessage.error("", "Please select a arrival airport.");
      setEnableBtn(true);
      return;
    }

    const totalAdults = adultsCount + seniorsCount;

    if (totalAdults === 0) {
      toastMessage.error("", "At least 1 adult or senior is required.");
      setEnableBtn(true);
      return;
    }

    if (childrenCount > 0 && totalAdults === 0) {
      toastMessage.error(
        "",
        "A child must be accompanied by at least 1 adult or senior."
      );
      setEnableBtn(true);
      return;
    }

    searchFlights();
    searchHotels();
  }, [bookingDetails, searchFlights, searchHotels]);

  useEffect(() => {
    const {
      airPort,
      adultsCount,
      seniorsCount,
      childrenCount,
      travelFromDate,
      numberOfRoomCount,
    } = bookingDetails || {};

    const isFieldMissing =
      !airPort?.length ||
      airPort.some((flight) => {
        return !flight.departure || !flight.arrival || !flight.departureDate;
      });

    const isTravelDateMissing = !travelFromDate;
    const isRoomCountInvalid = numberOfRoomCount < 1;

    const shouldDisableButton =
      isFieldMissing ||
      isTravelDateMissing ||
      (childrenCount === 1 && adultsCount < 1 && seniorsCount < 1) ||
      (adultsCount < 1 && seniorsCount < 1) ||
      isRoomCountInvalid;

    setEnableBtn(shouldDisableButton);
  }, [bookingDetails]);

  const addFinalTrip = (trips: AirPort[] | undefined | null): AirPort[] => {
    if (!trips?.length) return [];

    const [firstTrip, lastTrip] = [trips[0], trips[trips.length - 1]];

    const expectedFinalTrip = {
      arrival: firstTrip.departure,
      departure: lastTrip.arrival,
      fromCity: lastTrip.destinationCity,
      destinationCity: firstTrip.fromCity,
      departureDate: lastTrip.returnDate,
      returnDate: lastTrip.returnDate,
      departureAirportFullName: lastTrip.arrivalAirportFullName,
      arrivalAirportFullName: firstTrip.departureAirportFullName,
      arrivalCityLatitude: "",
      arrivalCityLongitude: "",
      departureCityLatitude: "",
      departureCityLongitude: "",
      noOfDays: 0,
    };

    const isDuplicate = trips.some(
      (trip) =>
        trip.fromCity === expectedFinalTrip.fromCity &&
        trip.destinationCity === expectedFinalTrip.destinationCity &&
        trip.departureDate === expectedFinalTrip.departureDate
    );

    if (
      !isDuplicate &&
      expectedFinalTrip.fromCity !== expectedFinalTrip.destinationCity
    ) {
      return [...trips, expectedFinalTrip];
    }

    return trips;
  };

  const hasFlightActivityOnLastDay = (dataTest: string | any[]) => {
    if (!dataTest || dataTest.length === 0) {
      return;
    }
    const lastDayArray = dataTest[dataTest.length - 1];
    if (!lastDayArray || lastDayArray?.length === 0) {
      return;
    }

    const lastDay = lastDayArray[lastDayArray.length - 1];
    if (lastDay?.itinerary_by_time_of_the_day) {
      return lastDay?.itinerary_by_time_of_the_day.some(
        (activity: { activity_type: string }) =>
          activity.activity_type === "transportation.flight"
      );
    }
    return false;
  };

  const result = hasFlightActivityOnLastDay(dataTest);

  useEffect(() => {
    if (isThisMultiCityTrip && result) {
      const updatedAirPort = addFinalTrip(bookingDetails.airPort);
      if (updatedAirPort !== bookingDetails.airPort) {
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airPort: updatedAirPort,
        }));
      }
    }
  }, [isThisMultiCityTrip, bookingDetails.airPort, setBookingDetails, result]);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.newInputCard1}>
        {bookingDetails && (
          <DateRangePicker
            startDate={bookingDetails.travelFromDate}
            endDate={bookingDetails.airportTo}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            handleUpdateData={() => {
              getTravelData();
              handleUpdateTravelData();
            }}
          />
        )}
        <div className={styles.card3}>
          {bookingDetails && (
            <Duration
              bookingDetails={bookingDetails}
              flight={true}
              hotel={true}
            />
          )}
        </div>
        {bookingDetails && (
          <div className={styles.card1}>
            <Travelers
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              showRoom={true}
            />
          </div>
        )}
      </div>
      <div className={styles.newInputCard2}>
        {bookingDetails && (
          <div className={styles.card4}>
            <PropertyType
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
            />
          </div>
        )}
        {selectedChatData?.destinationList &&
        selectedChatData?.destinationList.length > 1 ? (
          <RouteAndClass
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            tripType={3}
          />
        ) : (
          <div className={styles.locationCard}>
            <CityLocation city={bookingDetails?.citiesInput || ""} />
          </div>
        )}
      </div>
      <div className={styles.newInputCard3}>
        {bookingDetails && (
          <div className={styles.card4}>
            {selectedChatData?.destinationList?.length < 2 && (
              <RouteAndClass
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                tripType={bookingDetails.tripType}
              />
            )}
          </div>
        )}

        {bookingDetails &&
          bookingDetails?.airPort?.map((i, index) => (
            <>
              <div className={styles.card2}>
                {selectedChatData.destinationList &&
                  selectedChatData.destinationList.length === 1 && (
                    <AirPortPicker
                      key={index}
                      arrival={i.arrival}
                      departure={i.departure}
                      bookingDetails={bookingDetails}
                      setBookingDetails={setBookingDetails}
                      fromCity={i.departureAirportFullName}
                      destinationCity={i.arrivalAirportFullName}
                      handleUpdateData={() => {
                        getTravelData();
                        handleUpdateTravelData();
                      }}
                      arrivalCity={i.destinationCity}
                      departureCity={i.fromCity}
                    />
                  )}
              </div>
            </>
          ))}
      </div>
      {selectedChatData.destinationList &&
        selectedChatData.destinationList.length > 1 && (
          <div className={styles.stepper}>
            <section className={styles.stepperDetails}>
              {bookingDetails.travelFromDate && bookingDetails.travelToDate && (
                <Stepper
                  steps={bookingDetails.airPort}
                  initialStep={0}
                  finalStep={bookingDetails.airPort.length - 1}
                  showAccomodation={true}
                />
              )}
            </section>
          </div>
        )}
      <div
        className={`${selectedChatData?.destinationList?.length > 1 ? styles.searchBox : styles.singleCitySearch}`}>
        <SearchButton
          onClick={handleFlightHotelInputValidation}
          disableButton={enableBtn}
        />
      </div>
    </div>
  );
}

export default FlightHotelInputFields;
