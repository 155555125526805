import { Chip } from "@mui/material";
import styles from "./act.module.scss";
import { useCallback, useEffect, useState } from "react";
import { localDataTypes } from "../../../types";
import { getCategoryAPICall } from "../../../api/getCategory";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { StateContext } from "../../../context/globalContext/context";
import React from "react";
import { getCategoryArrayObj } from "../../../utils/categoryUtils";
import { getCurrentMonthWithTheNextTweleMonthAPI } from "../../../api/geCurrentMonthWithTheNextTwelveMonth";

interface iProps {
  selectedFood: string[];
  setSelectedFood: React.Dispatch<React.SetStateAction<string[]>>;
  setIsFormUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

function FoodPreferences({
  selectedFood,
  setIsFormUpdated,
  setSelectedFood,
}: iProps): JSX.Element {
  const state = React.useContext(StateContext);
  const {
    climate,
    setClimate,
    population,
    setPopulation,
    experience,
    setExperience,
    budget,
    setBudget,
    months,
    setMonths,
  }: iGlobalContext = state;
  const [foodPreferData, setFoodPreferData] = useState<localDataTypes[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleChipClick = useCallback(
    (activity: localDataTypes) => () => {
      if (selectedFood.includes(activity.data)) {
        setSelectedFood(selectedFood.filter((food) => food !== activity.data));
      } else {
        setSelectedFood([...selectedFood, activity.data]);
        setIsFormUpdated(true);
      }
    },
    [selectedFood, setIsFormUpdated, setSelectedFood]
  );
  const getCategory = useCallback(async () => {
    const response = await getCategoryAPICall();
    if (response && response) {
      window.localStorage.setItem("_all_category", JSON.stringify(response));
    }

    if (!response) {
      console.error("Failed to fetch the data");
      return;
    }
    for (let i = 0; i < response.length; i++) {
      if (response[i].name === "Climate" && climate.length === 0)
        setClimate(getCategoryArrayObj(response[i]));
      if (response[i].name === "Population" && population.length === 0)
        setPopulation(getCategoryArrayObj(response[i]));
      if (response[i].name === "Experiences" && experience.length === 0)
        setExperience(getCategoryArrayObj(response[i]));
      if (response[i].name === "Budget" && budget.length === 0)
        setBudget(response[i]);
    }
    if (months.length === 0)
      setMonths(getCurrentMonthWithTheNextTweleMonthAPI());
    else if (months[1].monthIndex < new Date().getUTCMonth())
      setMonths(getCurrentMonthWithTheNextTweleMonthAPI());
  }, [
    months,
    setMonths,
    climate.length,
    setClimate,
    population.length,
    setPopulation,
    experience.length,
    setExperience,
    budget.length,
    setBudget,
  ]);

  useEffect(() => {
    try {
      const storedData = localStorage.getItem("_all_category");
      const data = storedData ? JSON.parse(storedData) : [];

      if (Array.isArray(data) && data.length > 0) {
        const accData = data.find(
          (item: { name: string }) => item.name === "Food Preferences"
        );

        if (accData && accData.tag) {
          setFoodPreferData(accData.tag);
        }
      } else {
        getCategory();
      }
    } catch (error) {
      console.error("Error parsing localStorage data: ", error);
      getCategory();
    }
  }, [getCategory, selectedFood]);

  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={styles.main}>
      <span className={styles.title}>Food Preferences</span>
      <div className={styles.chipCard}>
        {foodPreferData &&
          foodPreferData
            .slice(0, showAll ? foodPreferData.length : 6)
            .map((activity, index) => (
              <Chip
                label={activity.data}
                size="medium"
                variant="outlined"
                onClick={handleChipClick(activity)}
                key={index}
                className={
                  selectedFood.includes(activity.data)
                    ? styles.activeChip
                    : styles.chip
                }
                style={{
                  backgroundColor:
                    selectedFood.includes(activity.data) === true
                      ? "#A4D1F0"
                      : "#ffff",
                }}
              />
            ))}
      </div>
      {showAll ? (
        <span className={styles.seeMore} onClick={handleSeeMoreClick}>
          See less
        </span>
      ) : (
        <span className={styles.seeMore} onClick={handleSeeMoreClick}>
          See more
        </span>
      )}
    </div>
  );
}

export default FoodPreferences;
