export function formatDate(dateString: string) {
  const date = new Date(dateString);

  // Get month, day, and year
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
}

export function formatDateMMddyyyy(dateString: string) {
  const date = new Date(dateString);

  const monthName = date.toLocaleString("default", { month: "short" });
  const dateDay = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${monthName} ${dateDay}, ${year}`;
}

export function sortDatesInAscendingOrder(array: any, dateKey: string) {
  return array.sort(
    (a: any, b: any) =>
      new Date(b[dateKey]).valueOf() - new Date(a[dateKey]).valueOf()
  );
}

export function sortDatesOldtoNew(array: any[], dateKey: string) {
  return array.sort(
    (a, b) =>
      new Date(a[dateKey]).valueOf() - new Date(b[dateKey]).valueOf()
  );
}

// retrun YYYY
export function getYear(dateStr: string) {
  const date = new Date(dateStr);
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${year}`;
}

// return DD YYYY
export function getDDYYYY(dateStr: string) {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${day}, ${year}`;
}

// Return Aug 19
export function getUTCMonthDate(dateStr: string, capitalize?: boolean) {
  if (!dateStr) return "";
  // // console.log(
  //   { dateStr },
  //   new Date(dateStr).toLocaleDateString("en-US", {
  //     timeZone: "UTC",
  //     month: "short",
  //     day: "numeric",
  //   })
  // );

  if (capitalize)
    return new Date(dateStr).toLocaleDateString("en-US", {
      timeZone: "UTC",
      month: "short",
      day: "numeric",
    });
  return new Date(dateStr)
    .toLocaleDateString("en-US", {
      timeZone: "UTC",
      month: "short",
      day: "numeric",
    })
    .toUpperCase();
}
// return YYYY
export function getYearYYYY(dateStr: string) {
  if (!dateStr) return "";
  return new Date(dateStr).getUTCFullYear();
}

// Return Fri
export function getDayOfWeek(dateStr: string, type?: "long" | "short") {
  if (!type) type = "short";
  if (!dateStr) return "";
  return new Date(dateStr).toLocaleDateString("en-US", {
    timeZone: "UTC",
    weekday: type === "long" ? "long" : "short",
  });
}

// Return true if two dates are same
export function isSameDate(dateStr1: string, dateStr2: string) {
  // Create date objects and set time to midnight UTC
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Set the time to midnight UTC for comparison
  const utcDate1 = Date.UTC(
    date1.getUTCFullYear(),
    date1.getUTCMonth(),
    date1.getUTCDate()
  );
  const utcDate2 = Date.UTC(
    date2.getUTCFullYear(),
    date2.getUTCMonth(),
    date2.getUTCDate()
  );

  // Compare the two dates
  return utcDate1 === utcDate2;
}

// return time in 12 hours EG: 4:32pm
export function getTwelveHourTimeFormat(dateStr: string) {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0, change it to 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${formattedMinutes} ${amOrPm}`;
}

// get the time difference
export function getTimeDifference(startDateStr: string, endDateStr: string) {
  if (!startDateStr || !endDateStr) return "";
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(endDate.getTime() - startDate.getTime());

  // Convert milliseconds to hours and minutes
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  return `${hours}h ${minutes}m`;
}
