import { Dispatch, SetStateAction, useEffect, useState } from "react";
// import { Chip } from "@mui/material";
// import { ButtonSizes, ButtonVariants } from "../../../enums";
// import edit from "../../../assets/edit.svg";
// import closeIcon from "../../../assets/close.png";
import CustomSlider from "../../slider/slider";
import { UpdateStateTypes } from "../../../types";
import styles from "./leave.module.scss";
interface iProps {
  update: UpdateStateTypes;
  setUpdate: Dispatch<SetStateAction<UpdateStateTypes>>;
  selectedMonth: string[];
  setSelectedMonth: React.Dispatch<React.SetStateAction<string[]>>;
  scrollValue: number;
  setScrollValue: Dispatch<SetStateAction<number>>;
  setIsFormUpdated: Dispatch<SetStateAction<boolean>>;
}

function Leave({
  // selectedMonth,
  // setSelectedMonth,
  // update,
  // setUpdate,
  setScrollValue,
  scrollValue,
  setIsFormUpdated,
}: iProps): JSX.Element {
  const [months, setMonths] = useState([]);

  // const handleUpdate = () => {
  //   setUpdate({ ...update, duration: !update.duration });
  // };

  useEffect(() => {
    const storedData = localStorage.getItem("_all_category");
    const data = storedData ? JSON.parse(storedData) : [];
    if (data) {
      const timeframes = data.find(
        (item: { name: string }) => item.name === "Timeframes"
      );

      if (timeframes && timeframes.tag) {
        setMonths(timeframes.tag);
      }
    }
  }, []);

  // const handleChipClick = (activity: string) => {
  //   setSelectedMonth([activity]);
  // };

  return (
    <div className={styles.main}>
      <div className={styles.slider}>
        <section className={styles.updateCard}>
          <span className={styles.duration}>Ideal vacation duration:</span>
          <CustomSlider
            scrollValue={scrollValue}
            setScrollValue={setScrollValue}
            setIsFormUpdated={setIsFormUpdated}
          />
        </section>
      </div>

      {/* <div className={styles.titleCard}>
        <span className={styles.title}>I would like to leave:</span>
      </div>
      {update.duration ? (
        <section className={styles.updateCard}>
          <div className={styles.climateHeader}>
            <p className={styles.climateTitle}>Departure</p>
            <div className={styles.closeCard} onClick={handleUpdate}>
              <img src={closeIcon} alt="" className={styles.closeIcon} />
            </div>
          </div>
          <div className={styles.selectBox}>
            <div className={styles.selectChipCard}>
              {months &&
                months.map((activity, index) => (
                  <Chip
                    label={activity}
                    size={ButtonSizes.Medium}
                    variant={ButtonVariants.Outlined}
                    onClick={() => handleChipClick(activity)}
                    key={index}
                    className={
                      selectedMonth.includes(activity)
                        ? styles.selectedChip
                        : styles.notSelectedChip
                    }
                  />
                ))}
            </div>
          </div>
          <div className={styles.slider}>
            <p className={styles.duration}>Ideal vacation duration</p>
            <CustomSlider
              scrollValue={scrollValue}
              setScrollValue={setScrollValue}
            />
          </div>
        </section>
      ) : (
        <section className={styles.content}>
          <div className={styles.chipCard}>
            {selectedMonth && (
              <Chip
                label={selectedMonth}
                size={ButtonSizes.Medium}
                variant={ButtonVariants.Outlined}
                onClick={() => {}}
                className={styles.activeChip}
              />
            )}

            <p className={styles.upTo}>for up to:</p>
            <Chip
              label={scrollValue + " Days"}
              size={ButtonSizes.Medium}
              variant={ButtonVariants.Outlined}
              onClick={() => {}}
              className={styles.activeChip}
            />
          </div>

          <div className={styles.editCard}>
            <img src={edit} alt="icon" onClick={handleUpdate} />
          </div>
        </section>
      )} */}
    </div>
  );
}

export default Leave;
