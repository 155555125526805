import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import BackButtonHandler from "../../component/BackButtonHandler";
import { APP_URL } from "../../constant/environment";
import { iGlobalContext } from "../../context/globalContext/interface";
import ReactGA from "react-ga4";
import {
  API_FAILURE_MESSAGE,
  closeWhiteIcon,
  ROUTES,
  shareIcon,
} from "../../constant";
import { iChatData, iCityDetailsResult } from "../../types";
import styles from "./savedItineraryDetail.module.scss";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import Loader from "../../component/reusableComponent/loader/Loader";
import CityTabs from "../itineraryDetails/cityTabs/CityTabs";
import { Button } from "@mui/material";
import { RWebShare } from "react-web-share";
import { APP_CONFIG } from "../../constant/common";
import { getConfigMessage } from "../../utils";
import { toastMessage } from "../../helpers/toast/toastMessage";

export default function SavedItineraryDetail(): JSX.Element {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    sharedItineraryId,
    setShareClick,
    setChatData,
    setSelectedChatData,
    selectedChatData,
    setIsItinerarySavedFlag,
    setScreen,
    appConfig,
    userDetails,
  }: iGlobalContext = state;
  // Axios controller to cancel API call
  const controller = new AbortController();
  const controllerAbortUpdateTrip = new AbortController();
  const [isLoading, setIsLoading] = useState(true);
  const [noOfCitySelected, setNoOfCitySelected] = useState(1);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setIsItinerarySavedFlag(true);
    console.count("RENDER SAVED DETAILS");
    async function getSavedItineraryDetails() {
      setIsLoading(true);
      setSelectedChatData({} as iChatData);
      const response = await getItineraryDetailsAPI(
        sharedItineraryId.split("/")[0]
      );
      if (!response) {
        toastMessage.error(
          "OOPS!",
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
        return;
      }
      const parseMetaData = JSON.parse(response?.data.metaData);
      // console.log(parseMetaData);
      if (!parseMetaData) {
        toastMessage.error("OOPS!", APP_URL);
        return;
      }
      // setChatData(parseMetaData[0].chatData);
      setSelectedChatData(parseMetaData[0].selectedChatData);
      setIsLoading(false);
      // console.log("DETAILS", parseMetaData[0].selectedChatData);
      // setSharedItineraryId(parseMetaData[0]?.selectedChatData?.id || "")
    }

    // if (sharedItineraryId) getSavedItineraryDetails();
    // else setIsLoading(false);
    getSavedItineraryDetails();

    return () => {
      controllerAbortUpdateTrip.abort();
      controller.abort();
    };
  }, [sharedItineraryId]);

  // Onclick function to handle Back button click
  function handleBackButton() {
    navigate(ROUTES.Home);
    setScreen("");
  }

  useEffect(() => {
    setNoOfCitySelected(
      selectedChatData?.destinationList?.reduce(
        (acc: number, destListEle: iCityDetailsResult) => {
          if (destListEle.checkedFlag) return acc + 1;
          else return acc;
        },
        0
      )
    );
  }, [selectedChatData]);

  function handleOnShare() {
    ReactGA.event({
      category: "Button Click",
      action: `Click on Share Trip Details`,
      label: `User is sharing the itinerary ${APP_URL}/shared/${sharedItineraryId}`,
    });
  }

  const [tempSelectedChatData, setTempSelectedChatData] = useState(
    [] as iCityDetailsResult[]
  );
  useEffect(() => {
    if (selectedChatData)
      setTempSelectedChatData(
        selectedChatData?.destinationList?.filter(
          (ele: iCityDetailsResult) => ele.checkedFlag
        )
      );
  }, [selectedChatData]);

  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);


  if (isLoading || JSON.stringify(selectedChatData) === "{}")
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.mainContainer}>
      <BackButtonHandler onBack={handleBackButton} />
      {/* Itinerary Details */}
      <div className={styles.stickyHeading}>
        <div className={styles.closeBtnContainer}>
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}
          >
            <img
              className={styles.iconButton}
              src={closeWhiteIcon}
              alt="Close"
            />
            <p>CLOSE</p>
          </Button>
          {/* {selectedChatData?.destinationList[0]
            ?.complete_itinerary_flights_cost && (
            <div className={styles.priceContainer}>
              <h1>from</h1>
              <h1 className={styles.price}>
                $
                {currencyToNumber(
                  selectedChatData?.destinationList[0]
                    ?.complete_itinerary_flights_cost?.value
                ) +
                  currencyToNumber(
                    selectedChatData?.destinationList[0]
                      ?.complete_itinerary_hotels_cost?.value
                  )}
              </h1>
            </div>
          )} */}
        </div>
        <div className={styles.buttonContainer}>
          <RWebShare
            data={{
              url: `${APP_URL}shared/${selectedChatData?.id}`,
              title: "Zenvoya",
            }}
            onClick={handleOnShare}
          >
            <Button className={`${styles.rowAlignButton}`}>
              <img className={styles.iconButton} src={shareIcon} alt="share" />
              SHARE
            </Button>
          </RWebShare>
        </div>
      </div>

      {/* <div className={styles.headingContainer}>
        {noOfCitySelected === 1 && tempSelectedChatData?.length > 0 && (
          <div className={styles.headingText}>
            <h1>{tempSelectedChatData[0]?.cityName?.split(",")[0]}</h1>
            <h2>
              {
                tempSelectedChatData[0]?.cityName?.split(",")[
                  tempSelectedChatData[0]?.cityName?.split(",").length - 1
                ]
              }
            </h2>
          </div>
        )}
      </div> */}

      {/* Tab container */}
      <CityTabs
        itineraryDescription={selectedChatData?.complete_itinerary_description}
        destinationList={tempSelectedChatData}
        isSavedActivityFlag={true}
        showEditActivityFlag = {showEditActivityFlag}
        setShowEditActivityFlag = {setShowEditActivityFlag}
      />
    </div>
  );
}
