import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function getHistoryAPICall(
  userId: string,
  skip: number,
  take: number
) {
  const URL = `${ENDPOINT}/app/v1/users/history?userId=${userId}&skip=${skip}&take=${take}`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  try {
    const response = await axios.get(URL, config);
    return response?.data.data;
  } catch (error: any) {
    // console.log("ERROR", error);
    // console.log("ERROR", error.message);
    return null;
  }
}
