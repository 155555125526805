import {
  bedWhite,
  expandWhiteIcon,
  planeEnginesWhite,
} from "../../../constant";
import {
  BookingDetails,
  HotelInputTypes,
  ISelectedOptions,
} from "../../../types";
import styles from "./travel.module.scss";

interface iProps {
  handleShowDetails: () => void;
  bookingDetails: BookingDetails;
  inputFieldData: HotelInputTypes[];
  selectedOptions: ISelectedOptions;
}

function TravelDetails({
  handleShowDetails,
  bookingDetails,
  inputFieldData,
  selectedOptions,
}: iProps): JSX.Element {
  const handleFlightClass = (flightClass: string) => {
    switch (flightClass) {
      case "E":
        return "Economy";
      case "P":
        return "Premium Plus";
      case "B":
        return "Business";
      case "F":
        return "First Class";
      default:
        return;
    }
  };

  // const formatDateRange = (
  //   startDate: string,
  //   endDate: string
  // ): string | null => {
  //   if (
  //     !startDate ||
  //     !endDate ||
  //     isNaN(Date.parse(startDate)) ||
  //     isNaN(Date.parse(endDate))
  //   ) {
  //     return "";
  //   }

  //   const options: Intl.DateTimeFormatOptions = {
  //     month: "short",
  //     day: "2-digit",
  //   };
  //   const start = new Date(startDate).toLocaleDateString("en-US", options);
  //   const end = new Date(endDate).toLocaleDateString("en-US", options);
  //   return `${start} - ${end}`;
  // };
  const formatDateRange = (
    startDate: string,
    endDate: string
  ): string | null => {
    if (
      !startDate ||
      !endDate ||
      isNaN(Date.parse(startDate)) ||
      isNaN(Date.parse(endDate))
    ) {
      return "";
    }

    // Format both dates to ISO with "T00:00:00"
    const formatISODate = (dateStr: string): string => {
      return new Date(dateStr).toISOString().split("T")[0] + "T00:00:00";
    };

    const formattedStartDate = formatISODate(startDate);
    const formattedEndDate = formatISODate(endDate);

    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "2-digit",
    };

    const start = new Date(formattedStartDate).toLocaleDateString(
      "en-US",
      options
    );
    const end = new Date(formattedEndDate).toLocaleDateString("en-US", options);

    return `${start} - ${end}`;
  };

  return (
    <div className={styles.main}>
      <div className={styles.container} onClick={handleShowDetails}>
        <section className={styles.card1}>
          {selectedOptions.flights && !selectedOptions.hotels && (
            <img src={planeEnginesWhite} alt="" className={styles.bedIcon} />
          )}
          {!selectedOptions.flights && selectedOptions.hotels && (
            <img src={bedWhite} alt="" className={styles.bedIcon} />
          )}
          {selectedOptions.flights && selectedOptions.hotels && (
            <>
              <img src={planeEnginesWhite} alt="" className={styles.bedIcon} />
              <img src={bedWhite} alt="" className={styles.bedIcon} />
            </>
          )}

          <div className={styles.details}>
            <div className={styles.detailsCard}>
              {" "}
              {inputFieldData &&
                inputFieldData.map((i, index) => (
                  <p className={styles.city} key={index}>
                    {i.city}
                    {index < inputFieldData.length - 1 && "-"}
                  </p>
                ))}
            </div>

            <p className={styles.desc}>
              {formatDateRange(
                bookingDetails.travelFromDate,
                bookingDetails.travelToDate
              )}{" "}
              {bookingDetails.adultsCount > 0 && (
                <span>
                  • {bookingDetails.adultsCount}{" "}
                  {bookingDetails.adultsCount > 1 ? "adults" : "adult"}
                </span>
              )}
              {bookingDetails.childrenCount > 0 && (
                <span>
                  , {bookingDetails.childrenCount}{" "}
                  {bookingDetails.childrenCount > 1 ? "children" : "child"}
                </span>
              )}
              {bookingDetails.seniorsCount > 0 && (
                <span>
                  , {bookingDetails.seniorsCount}{" "}
                  {bookingDetails.seniorsCount > 1 ? "seniors" : "senior"}
                </span>
              )}{" "}
              {bookingDetails.flightClass && (
                <span>• {handleFlightClass(bookingDetails.flightClass)}</span>
              )}
            </p>
          </div>
        </section>
        <section className={styles.dropDowIcon} onClick={handleShowDetails}>
          <img
            src={expandWhiteIcon}
            alt="dropIcon"
            className={styles.dropIcon}
          />
        </section>
      </div>
    </div>
  );
}

export default TravelDetails;
