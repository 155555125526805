import { useContext, useState } from "react";
import { arrowRightArrowLeftRegular } from "../../../../constant";
import { AirportDataTypes, BookingDetails } from "../../../../types";
import AirportSelectionPopover from "../airportSelectionPopover/AirportSelectionPopover";
import styles from "./flight.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";

interface iProps {
  className?: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  hideTitle?: boolean;
  departure: string;
  arrival: string;
  fromCity: string;
  destinationCity: string;
  indexNumber: number;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityLatitude: string;
  departureCityLongitude: string;
  departureAirportFullName: string;
  arrivalAirportFullName: string;
}

function AirPortPicker({
  className,
  bookingDetails,
  setBookingDetails,
  hideTitle,
  departure,
  arrival,
  fromCity,
  destinationCity,
  indexNumber,
  arrivalCityLatitude,
  arrivalCityLongitude,
  departureCityLatitude,
  departureCityLongitude,
  arrivalAirportFullName,
  departureAirportFullName,
}: iProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const state = useContext(StateContext);
  const { airPorts }: iGlobalContext = state;

  const filterCities = (
    data: AirportDataTypes[] | undefined | null,
    fromCity: string,
    destinationCity: string
  ) => {
    if (!data) {
      console.error("Airport data is undefined or null");
      return [];
    }

    return data.filter(
      (city) => city.city === fromCity || city.city === destinationCity
    );
  };

  const airPortData = filterCities(airPorts, fromCity, destinationCity);

  return (
    <div className={`${className} ${styles.travelCard}`}>
      <AirportSelectionPopover
        airportData={airPortData}
        indexNumber={indexNumber}
        arrival={arrival}
        departure={departure}
        fromCity={fromCity}
        destinationCity={destinationCity}
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        arrivalCityLatitude={arrivalCityLatitude}
        arrivalCityLongitude={arrivalCityLongitude}
        departureCityLatitude={departureCityLatitude}
        departureCityLongitude={departureCityLongitude}
        popoverTrigger={
          <div className={`${className} ${styles.main}`}>
            <p className={styles.label}>AIRPORTS</p>
            <div className={`${isPopoverOpen ? styles.activeBtn : styles.btn}`}>
              {!departure ? (
                <section className={styles.card}>
                  <p className={styles.btnName}>
                    {" "}
                    {bookingDetails.airportFrom
                      ? bookingDetails.airportFrom
                      : ""}
                  </p>
                  <p className={styles.city}></p>
                </section>
              ) : (
                <div className={styles.card}>
                  <p className={styles.btnName}>{departure}</p>
                  <p className={styles.city}>{departureAirportFullName}</p>
                </div>
              )}
              <img
                src={arrowRightArrowLeftRegular}
                className={styles.arrowIcon}
                alt=""
              />
              {!arrival ? (
                <section className={styles.card}>
                  <p className={styles.btnName}>
                    {" "}
                    {bookingDetails.airportTo ? bookingDetails.airportTo : ""}
                  </p>
                  <p className={styles.city}></p>
                </section>
              ) : (
                <section className={styles.card}>
                  <p className={styles.btnName}>{arrival}</p>
                  <p className={styles.city}>{arrivalAirportFullName}</p>
                </section>
              )}
            </div>
            {(!departure || !arrival) && (
              <p className={styles.airportNotAvail}>
                Airport unavailable. Please try a different destination.
              </p>
            )}
          </div>
        }
      />
    </div>
  );
}

export default AirPortPicker;
