import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ItineraryDetails from "./itineraryDetails/ItineraryDetails";
import EditAccount from "./editAccount/EditAccount";
import ResetPasswordPage from "./resetPassword/ResetPasswordPage";
import ViewShareItinerary from "./viewSharedItineraryDetails/ViewShareItinerary";
import TopDestination from "./topDestination/TopDestination";
import NotFound from "../component/NotFound/NotFound";
import RecommendedTrips from "./recommendedTrips/RecommendedTrips";
import AdjustTrip from "./adjustTrip/adjustTrip";
import PreviewPreference from "./previewPreference/previewPreference";
import { ROUTES } from "../constant";
import ReorderMultiCityTrip from "./reorderMultipCityTrip/ReorderMultiCityTrip";
import SavedItineraryDetail from "./savedItineraryDetails/SavedItineraryDetail";
import { StateContext } from "../context/globalContext/context";
import { iGlobalContext } from "../context/globalContext/interface";
import ProtectedRoute from "./protectedRoute/ProtectedRoute";
import NewTrip from "./newTrip/newTrip";
import FaqAccordion from "./faqsAccordion/faqsAccordion";
import Booking from "./booking/booking";
import HotelDetails from "../component/hotelDetails";
import TodayTrip from "../routes/todayTrip/todayTrip";
import TravelerInformation from "../routes/travelerInformation/travellerInformation";
import WeatherAdvisory from "./weatherAdvisory/weatherAdvisory";
import FlightDelay from "./flightDelay/flightDelay";
import FlightSelect from "./flightSelect/flightSelect";
import ExploreTripAndAssistance from "../component/exploreTripAndAssistance/ExploreTripAndAssistance";
import FaqAssistance from "../component/faqAssistance/FaqAssistance";
import MyTrips from "../component/myTripsPage/myTrips";
import TripDetailsPage from "../component/tripDetailsPage/TripDetailsPage";
import RecommendedTripDetailsPage from "../component/recommendedTripDetailsPage/RecommendedTripDetailsPage";
import { getUserDetailsApi } from "../api/getUserDetails";
import { getConfigMessage } from "../utils";
import { toastMessage } from "../helpers/toast/toastMessage";
import { APP_CONFIG } from "../constant/common";
import { APP_URL, environment, WEB_URL } from "../constant/environment";
import AboutUs from "../component/aboutUs/aboutUs";
import Cookies from "js-cookie";
import StripeSuccess from "./payment-success";

export default function AppRoutes() {
  const context = useContext(StateContext);
  const {
    userInfo,
    setUserInfo,
    setUserDetails,
    appConfig,
    userDetails,
  }: iGlobalContext = context;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function getUserDetails(userId: string) {
    const response = await getUserDetailsApi(userId);
    if (response) {
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        id: response.id,
        name: response.name,
        email: response.email,
        phone: response.phone,
        profile_pic: response.profile_pic,
        loggedIn: true,
        role: response?.role || 0,
      }));

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        id: response.id,
        name: response.name,
        email: response.email,
        phone: response.phone,
        profile_pic: response.profile_pic,
        loggedIn: true,
        role: response?.role || 0,
      }));
    }
    if (!response) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return;
    }
  }

  useEffect(() => {
    if (window && typeof window !== "undefined") {
      window?.addEventListener("message", (event) => {
        if (`${event.origin}/` !== WEB_URL) {
          return;
        }
        const data = JSON.parse(event.data);
        if (typeof data?.id !== "undefined" && data?.loggedIn) {
          setUserDetails(data);
          getUserDetails(data.id);
          // store the JWT to local storage
          window.localStorage.setItem("JWT", JSON.stringify(data.jwt));
          // window.location.reload();
          return;
          // localStorage.setItem("name", data.name);
          // // console.log("Name is set: " + data.name);
        }
        setUserDetails({});
        window.localStorage.removeItem("JWT");
      });
    }
  }, [getUserDetails, setUserDetails]);

  useEffect(() => {
    // Load the todos on mount
    // const todosString = localStorage.getItem("todos");
    // if (todosString) {
    //     const todos = JSON.parse(todosString);
    //     setTodos(todos);
    // }
    // Respond to the `storage` event
    const userData = Cookies.get(`${environment}userData`);
    if (userData) {
      const data = JSON.parse(userData);
      if (typeof data?.id !== "undefined" && data?.loggedIn) {
        setUserDetails(data);
        getUserDetails(data.id);
        // store the JWT to local storage
        window.localStorage.setItem("JWT", JSON.stringify(data.jwt));
        // window.location.reload();
        return;
        // localStorage.setItem("name", data.name);
        // // console.log("Name is set: " + data.name);
      }
    }

    if (!userData) {
      setUserDetails({});
      setUserInfo({
        id: "",
        name: "",
        email: "",
        phone: "",
        profile_pic: "",
        loggedIn: false,
        role: 0,
      });
      window.localStorage.removeItem("JWT");
    }
    // function storageEventHandler(event: any) {
    //   if (
    //     event.key === "_trip_trove_user_details" &&
    //     window.location.origin === APP_URL.slice(0, -1)
    //   ) {
    //     // console.log(window.location.origin, "origin");
    //     // const todos = JSON.parse(event.newValue);
    //     window.location.reload();
    //   }
    // }
    // // Hook up the event handler
    // window.addEventListener("storage", storageEventHandler);
    // return () => {
    //   // Remove the handler when the component unmounts
    //   window.removeEventListener("storage", storageEventHandler);
    // };
  }, []);

  return (
    <Routes>
      <Route path={ROUTES.Home} element={<RecommendedTrips />} />
      <Route path={ROUTES.ItineraryDetails} element={<ItineraryDetails />} />
      <Route
        path={ROUTES.EditAccount}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={EditAccount}
          />
        }
      />
      <Route path={ROUTES.AboutUs} element={<AboutUs />} />
      <Route path="/reset-password/:id" element={<ResetPasswordPage />} />
      <Route path="/shared/:id" element={<ViewShareItinerary />} />
      <Route path={ROUTES.TopDestination} element={<TopDestination />} />
      <Route
        path={ROUTES.SavedItineraryDetails}
        element={<SavedItineraryDetail />}
      />

      {/* <Route path={ROUTES.RecommendedTrips} element={<RecommendedTrips />} /> */}

      <Route path={ROUTES.AdjustTrip} element={<AdjustTrip />} />
      <Route
        path={ROUTES.PreviewPreference}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={PreviewPreference}
          />
        }
      />
      <Route
        path={ROUTES.ReorderMultiCityTrip}
        element={<ReorderMultiCityTrip />}
      />
      <Route path={ROUTES.Faqs} element={<FaqAccordion />} />
      <Route
        path={ROUTES.StripeSuccess}
        element={
          <StripeSuccess
          // ProtectedRoute
          // isLoggedIn={userInfo.loggedIn}
          // component={StripeSuccess}
          />
        }
      />
      <Route path={ROUTES.NewTrip} element={<NewTrip />} />
      <Route
        path={ROUTES.Booking}
        element={
          <ProtectedRoute isLoggedIn={userInfo.loggedIn} component={Booking} />
        }
      />
      <Route
        path={ROUTES.HotelDetails}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={HotelDetails}
          />
        }
      />
      <Route
        path={ROUTES.TravelerInformation}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={TravelerInformation}
          />
        }
      />
      <Route
        path={"/today"}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={TodayTrip}
          />
        }
      />
      <Route
        path={ROUTES.MyTrips}
        element={
          <ProtectedRoute isLoggedIn={userInfo.loggedIn} component={MyTrips} />
        }
      />
      <Route
        path={ROUTES.weatherAdvisory}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={WeatherAdvisory}
          />
        }
      />
      <Route
        path={ROUTES.FlightDelay}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={FlightDelay}
          />
        }
      />
      <Route
        path={ROUTES.FlightSelect}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={FlightSelect}
          />
        }
      />
      <Route
        path={ROUTES.ExploreCity}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={ExploreTripAndAssistance}
          />
        }
      />
      <Route
        path={ROUTES.FaqAssistance}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={FaqAssistance}
          />
        }
      />
      <Route path={ROUTES.TripDetails} element={<TripDetailsPage />} />
      <Route
        path={ROUTES.RecommendedTripDetails}
        element={<RecommendedTripDetailsPage />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
