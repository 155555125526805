import { useCallback, useContext } from "react";
import { StateContext } from "../context/globalContext/context";
import { iGlobalContext } from "../context/globalContext/interface";

export function useResetState() {
  const state = useContext(StateContext);
  const {
    setScreen,
    setFlightData,
    setSelectedFlightOptions,
    setHotelData,
    setShowMore,
    setAirPorts,
    setBookingDetails,
    bookingDetails,
    setHotelPagination,
  }: iGlobalContext = state;
  const resetState = useCallback(() => {
    setScreen("");
    setFlightData(null);
    setHotelData(null);
    setSelectedFlightOptions({ flights: true, hotels: true });
    setShowMore({ visibleList: { flight: 4, hotel: 4, package: 4 } });
    setAirPorts([]);
    setHotelPagination([]);
    setBookingDetails((prevData) => ({
      ...prevData,
      adultsCount: 1,
      childrenCount: 0,
      seniorsCount: 0,
      airportFrom: "",
      airportTo: "",
      travelFromDate: "",
      travelToDate: "",
      routeType: "2",
      flightClass: "E",
      cities: ["Barcelona"],
      checkIn: "",
      checkOut: "",
      numberOfRoomCount: 1,
      tripType: 2,
      update: false,
      selectedFlightId: "",
      airlines: "",
      hotelChains: "",
      flightDayPart: "",
      noMoreFlights: false,
      expandedAccordions: [],
      airPort: [
        {
          arrival: "",
          departure: "",
          fromCity: "",
          destinationCity: "",
          departureDate: "",
          returnDate: "",
          departureAirportFullName: "",
          arrivalAirportFullName: "",
          arrivalCityLatitude: "",
          arrivalCityLongitude: "",
          departureCityLatitude: "",
          departureCityLongitude: "",
          noOfDays: 0,
        },
      ],
      travelJourneyData: [
        {
          departureCityAddress: "",
          arrivalCityAddress: "",
          departureDate: "",
          returnDate: "",
          days: [],
          flightCheckIn: "",
          flightCheckOut: "",
          totalDays: 0,
        },
      ],
      visibleList: {
        flight: 0,
        hotel: 0,
        package: 2,
      },
      filters: {
        starRating: [3],
      },
    }));
  }, [
    setScreen,
    setFlightData,
    setHotelData,
    setSelectedFlightOptions,
    setShowMore,
    setAirPorts,
    setHotelPagination,
    setBookingDetails,
    bookingDetails,
  ]);

  return resetState;
}
