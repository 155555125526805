import ReactGA from "react-ga4";
import { login_popup_events } from "./login-events";
import { home_and_sideBar_events } from "./home-and-sidebar-events";
import { edit_profile_popup_events } from "./edit-profile-events";
import { my_trips_events } from "./my-trips-events";
import { preview_preferences_events } from "./preview-preference-events";
import { current_trips_events } from "./current-trips-events";
import { flight_delay_events } from "./flight-delay-events";
import { flight_select_events } from "./flight-select-page-events";
import { weather_advisory_events } from "./weather-advisory-events";
import { explore_city_events } from "./explore-city-events";
import { trip_details_events } from "./trip-details-events";

export enum GA_LABEL_ENUMS {
  // home page events
  Loads_Home_page = "Loads_Home_page",
  Clicks_Recommended_Trips_card = "Clicks_Recommended_Trips_card",
  Copying_Suggested_Trips = "Copying_Suggested_Trips",
  New_Trip_Click = "New_Trip_Click",
  Suggested_Trip_Click = "Suggested_Trip_Click",
  Send_Prompt_click = "Send_Prompt_click",
  Stop_click = "Stop_click",
  Show_more_click = "Show_more_click",
  home_upcoming_trip_click = "home_upcoming_trip_click",
  home_side_bar_open_click = "home_side_bar_open_click",
  home_side_bar_close_click = "home_side_bar_close_click",
  home_help_click = "home_help_click",
  home_preferences_click = "home_preferences_click",

  // navbar and side bar events
  Logo_Click = "Logo_Click",
  Terms_of_service_click = "Terms_of_service_click",
  Contact_us_click = "Contact_us_click",
  Login_in_click = "Login_in_click",
  My_trips_click = "My_trips_click",
  Current_trips_click = "Current_trips_click",
  History_click = "History_click",
  History_delete_click = "History_delete_click",
  Privacy_polcy_click = "Privacy_polcy_click",
  Logout = "Logout",
  Itinrary_click = "Itinrary_click",

  // login poup events
  Facebook_login_click = "Facebook_login_click",
  Google_login_click = "Google_login_click",
  Apple_login_click = "Apple_login_click",
  Forgot_password_click = "Forgot_password_click",
  Create_new_account_click = "Create_new_account_click",
  Login_with_email_click = "Login_with_email_click",

  // sign up events
  Sign_up_with_email = "Sign_up_with_email",

  // edit account popup events
  home_edit_profile_click = "home_edit_profile_click",
  home_submit_edit_profile_click = "home_submit_edit_profile_click",
  home_upload_image_click = "home_upload_image_click",
  home_delete_account_click = "home_delete_account_click",
  Profile_yes_delete_account_click = "Profile_yes_delete_account_click",
  Profile_delete_cancel_click = "Profile_delete_cancel_click",

  // my trips page events
  my_trips_page_load = "my_trips_page_load",
  my_trips_upcoming_tab_click = "my_trips_upcoming_tab_click",
  my_trips_past_tab_click = "my_trips_past_tab_click",
  my_trips_cancelled_tab_click = "my_trips_cancelled_tab_click",
  my_trips_saved_tab_click = "my_trips_saved_tab_click",
  my_trips_upcoming_trips_card_click = "my_trips_upcoming_trips_card_click",
  my_trips_past_trips_card_click = "my_trips_past_trips_card_click",
  my_trips_cancelled_trips_card_click = "my_trips_cancelled_trips_card_click",
  my_trips_saved_trips_card_click = "my_trips_saved_trips_card_click",
  my_trips_upcoming_trips_edit_click = "my_trips_upcoming_trips_edit_click",
  my_trips_past_trips_edit_click = "my_trips_past_trips_edit_click",
  my_trips_cancelled_trips_edit_click = "my_trips_cancelled_trips_edit_click",
  my_trips_saved_trips_edit_click = "my_trips_saved_trips_edit_click",
  my_trips_past_trips_book_again_click = "my_trips_past_trips_book_again_click",
  my_trips_cancelled_trips_book_again_click = "my_trips_cancelled_trips_book_again_click",
  my_trips_saved_trips_book_click = "my_trips_saved_trips_book_click",
  edit_trip_name_save_changes = "edit_trip_name_save_changes",

  // preview preferences events
  preview_preference_load = "preview_preference_load",
  preview_preference_close_click = "preview_preference_close_click",
  preview_preferences_edit_click = "preview_preferences_edit_click",
  preview_preferences_save_changes = "preview_preferences_save_changes",

  // current trips/today's page events
  current_trips_page_loads = "current_trips_page_loads",
  today_flight_delay_click = "today_flight_delay_click",
  today_weather_advisory_click = "today_weather_advisory_click",
  today_explore_city_click = "today_explore_city_click",
  today_view_trip_details_click = "today_view_trip_details_click",
  today_zenvoya_assistance_click = "today_zenvoya_assistance_click",

  // flight delay events
  flight_delay_page_load = "flight_delay_page_load",
  flight_delay_back_click = "flight_delay_back_click",
  flight_delay_details_click = "flight_delay_details_click",
  flight_delay_show_more_click = "flight_delay_show_more_click",
  flight_delay_select_click = "flight_delay_select_click",

  // Flight select page events
  flight_select_page_load = "flight_select_page_load",
  flight_select_back_click = "flight_select_back_click",
  flight_select_selected_flight_accordion_click = "flight_select_selected_flight_accordion_click",
  flight_select_selected_flight_details_accordion_click = "flight_select_selected_flight_details_accordion_click",
  flight_select_selected_flight_collapse_accordion_click = "flight_select_selected_flight_collapse_accordion_click",
  flight_select_suggested_changes_your_plan_accordion_click = "flight_select_suggested_changes_your_plan_accordion_click",
  flight_select_suggested_changes_your_plan_checkbox_click = "flight_select_suggested_changes_your_plan_checkbox_click",
  flight_select_confirm_click = "flight_select_confirm_click",

  // weather advisory events
  weather_advisory_page_load = "weather_advisory_page_load",
  weather_advisory_back_click = "weather_advisory_back_click",
  weather_advisory_suggested_changes_your_plan_accordion_click = "weather_advisory_suggested_changes_your_plan_accordion_click",
  weather_advisory_suggested_changes_your_plan_checkbox_click = "weather_advisory_suggested_changes_your_plan_checkbox_click",
  weather_advisory_apply_changes_click = "weather_advisory_apply_changes_click",

  // Explore city evetns
  explore_city_page_load = "explore_city_page_load",
  explore_city_back_click = "explore_city_back_click",
  explore_city_input_click = "explore_city_input_click",
  explore_city_stop_click = "explore_city_stop_click",
  explore_city_mic_send_click = "explore_city_mic_send_click",
  explore_city_mic_stop_click = "explore_city_mic_stop_click",
  explore_city_new_trip_click = "explore_city_new_trip_click",

  // Trip details page events
  trip_details_page_load = "trip_details_page_load",
  trip_details_back_click = "trip_details_back_click",
  trip_details_share_click = "trip_details_share_click",
  trip_details_new_trip_click = "trip_details_new_trip_click",
  trip_details_daily_plans_click = "trip_details_daily_plans_click",
  trip_details_daily_plans_flight_accordion_click = "trip_details_daily_plans_flight_accordion_click",
  trip_details_daily_plans_flight_deatils_accordion_click = "trip_details_daily_plans_flight_deatils_accordion_click",
  trip_details_daily_plans_flight_collapse_accordion_click = "trip_details_daily_plans_flight_collapse_accordion_click",
  trip_details_daily_plans_hotel_accordion_click = "trip_details_daily_plans_hotel_accordion_click",
  trip_details_daily_plans_hotel_view_deatils_accordion_click = "trip_details_daily_plans_hotel_view_deatils_accordion_click",
  trip_details_activities_click = "trip_details_activities_click",
  trip_details_daily_activity_accordion_click = "trip_details_daily_activity_accordion_click",
  trip_details_activities_modify_activities_click = "trip_details_activities_modify_activities_click",
  trip_details_activities_done_click = "trip_details_activities_done_click",
  trip_details_flights_click = "trip_details_flights_click",
  trip_details_flights_cancel_option_click = "trip_details_flights_cancel_option_click",
  trip_details_flights_change_flight_click = "trip_details_flights_change_flight_click",
  trip_details_flight_details_accordion_click = "trip_details_flight_details_accordion_click",
  trip_details_flight_collapse_accordion_click = "trip_details_flight_collapse_accordion_click",
  trip_details_flight_traveler_accordion_click = "trip_details_flight_traveler_accordion_click",
  trip_details_hotel_accordion_click = "trip_details_hotel_accordion_click",
  trip_details_hotel_view_deatils_accordion_click = "trip_details_hotel_view_deatils_accordion_click",
  trip_details_hotel_cancel_option_click = "trip_details_hotel_cancel_option_click",
  trip_details_hotel_change_hotel_click = "trip_details_hotel_change_hotel_click",
}

export const GA_EVENTS: any = {
  ...home_and_sideBar_events,
  ...login_popup_events,
  ...edit_profile_popup_events,
  // sign up event
  Sign_up_with_email: {
    category: "User signing up with email and password",
    action: "User clicks on create account to sign up with email and password",
    label: "Sign-up-with-email",
  },
  ...my_trips_events,
  ...preview_preferences_events,
  ...current_trips_events,
  ...flight_delay_events,
  ...flight_select_events,
  ...weather_advisory_events,
  ...explore_city_events,
  ...trip_details_events,
};

export const triggerGAEvent = (
  GAlabel: GA_LABEL_ENUMS,
  custom_label?: string
) => {
  //   console.log(GA_EVENTS[GAlabel]?.category, "Google events");
  const { category = "", action = "", label = "" } = GA_EVENTS[GAlabel] || {};
  ReactGA.event({
    category,
    action: label,
    label: action,
  });
};
