import React from "react";
import { AirPort, TravelJourney } from "../../../../../types";
import { arrowRight, planeArrivalRegular } from "../../../../../constant";
import FlightHotelDetails from "../flightHotelDetails/flightHotelDetails";
import styles from "./step.module.scss";

type FlightInfo = {
  arrival: string;
  departure: string;
  fromCity: string;
  destinationCity: string;
  departureDate: string;
  returnDate: string;
  departureAirportFullName: string;
  arrivalAirportFullName: string;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityLatitude: string;
  departureCityLongitude: string;
};

type CityInfo = {
  name: string;
  latitude: string;
  longitude: string;
};

interface StepperProps {
  steps?: AirPort[];
  initialStep: number;
  finalStep: number;
  showAccomodation: boolean;
}

const Stepper: React.FC<StepperProps> = ({
  steps = [],
  initialStep = 0,
  finalStep = 0,
  showAccomodation,
}) => {
  const [showAllSteps, setShowAllSteps] = React.useState(true);
  
  const handleViewDetails = () => {
    setShowAllSteps((prev) => !prev);
  };

  if (steps.length === 0) {
    return <div>No data found</div>;
  }  
  const addFinalTrip = (trips: AirPort[] | undefined | null) => {
    if (!trips || trips.length === 0) {
      return [];
    }
    const newTrips = [...trips];
    const lastTrip = trips[trips.length - 1];
    const finalDestinationCity = lastTrip.destinationCity;
    const finalTrip = {
      arrival: "",
      departure: "",
      fromCity: "",
      destinationCity: finalDestinationCity,
      departureDate: "",
      returnDate: "",
      departureAirportFullName: "",
      arrivalAirportFullName: "",
      arrivalCityLatitude: "",
      arrivalCityLongitude: "",
      departureCityLatitude: "",
      departureCityLongitude: "",
      noOfDays: 0,
    };
    newTrips.push(finalTrip);
    return newTrips;
  };

  const handleStops = (indexNumber: number, indexLength: number): string => {
    let displayText;
    if (indexNumber === 0) {
      displayText = `stop ${indexNumber + 1}`;
    } else if (indexNumber === addFinalTrip(steps)?.length - 1) {
      displayText = "";
    } else {
      displayText = `Flight ${indexNumber < indexLength ? `0${indexNumber + 1}` : indexNumber + 1}`;
    }
    return displayText;
  };

  function getCityInfo(flights: FlightInfo[] | null | undefined): CityInfo[] {
    if (!Array.isArray(flights) || flights.length === 0) {
      return [];
    }

    const cityInfoArray: CityInfo[] = [];

    flights.forEach((flight, index) => {
      if (!flight) return;
      let departureLatitude = flight.departureCityLatitude || "";
      let departureLongitude = flight.departureCityLongitude || "";
      if ((!departureLatitude || !departureLongitude) && index > 0) {
        const prevFlight = flights[index - 1];
        departureLatitude = prevFlight?.arrivalCityLatitude || "";
        departureLongitude = prevFlight?.arrivalCityLongitude || "";
      }
      cityInfoArray.push({
        name: flight.fromCity || "",
        latitude: departureLatitude,
        longitude: departureLongitude,
      });
      cityInfoArray.push({
        name: flight.destinationCity || "",
        latitude: flight.arrivalCityLatitude || "",
        longitude: flight.arrivalCityLongitude || "",
      });
    });

    return cityInfoArray;
  }

  // const getAirportData = async () => {
  //   const requestBody = {
  //     cities: getCityInfo(steps),
  //     radius: "100 miles",
  //   };

  //   try {
  //     const data = await getAirports(requestBody);
  //     // console.log("Response", data);
  //     return data;
  //   } catch (err) {
  //     toastMessage.error("Failed to fetch flight data");
  //     return [];
  //   }
  // };
  const hasFulSteps = steps?.some(
    (step) =>
      step.fromCity ||
      step.destinationCity ||
      step.departureDate ||
      step.returnDate
  );

  if (!hasFulSteps) return null
  
  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        {steps &&
          addFinalTrip(steps)?.map((step, index) => {
            // if (index < initialStep || index > finalStep) return null;
            const shouldDisplayStep =
              showAllSteps ||
              index === initialStep ||
              index === finalStep ||
              steps.length <= 2;

            if (!shouldDisplayStep) return null;

            return (
              <React.Fragment key={index}>
                <div className={styles.step}>
                  <div className={styles.circle}></div>
                  {step.fromCity ? (
                    <div className={styles.stepContent}>
                      <p className={styles.stops}>
                        {handleStops(index, addFinalTrip(steps)?.length)}
                      </p>
                      <section className={styles.airportCard}>
                        {step.fromCity && (
                          <p className={styles.city}>{step.fromCity}</p>
                        )}
                        <img
                          src={arrowRight}
                          alt=""
                          className={styles.arrowIcon}
                        />
                        {step.destinationCity && (
                          <p className={styles.city}>{step.destinationCity}</p>
                        )}
                      </section>
                      {showAllSteps && (
                        <div className={styles.card}>
                          <div>
                            <FlightHotelDetails
                              returnDate={step.returnDate}
                              departureDate={step.departureDate}
                              arrival={step.arrival}
                              departure={step.departure}
                              fromCity={step.fromCity}
                              destinationCity={step.destinationCity}
                              showAccomodation={showAccomodation}
                              indexNumber={index}
                              arrivalCityLatitude={step.arrivalCityLatitude}
                              arrivalCityLongitude={step.arrivalCityLongitude}
                              departureCityLatitude={step.departureCityLatitude}
                              departureCityLongitude={
                                step.departureCityLongitude
                              }
                              arrivalAirportFullName={
                                step.arrivalAirportFullName
                              }
                              departureAirportFullName={
                                step.departureAirportFullName
                              }
                              hotelStayCount={step.noOfDays}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={styles.lastStep}>
                      <img
                        src={planeArrivalRegular}
                        alt=""
                        className={styles.arrivalImg}
                      />
                      {step.destinationCity && (
                        <p className={styles.destination}>
                          {step.destinationCity}
                        </p>
                      )}
                    </div>
                  )}
                  {index < addFinalTrip(steps).length - 1 && (
                    <div className={styles.line} />
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default Stepper;
