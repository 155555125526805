import React from "react";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import BookingCounter from "./bookingCounter/BookingCounter";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./travelerPopover.module.scss";
interface iProps {
  showRoom?: boolean;
  popoverTrigger: ReactNode;
  adultsCount: number;
  childrenCount: number;
  seniorsCount: number;
  setAdultsCount: Dispatch<SetStateAction<number>>;
  setChildrenCount: Dispatch<SetStateAction<number>>;
  setSeniorsCount: Dispatch<SetStateAction<number>>;
  numberOfRoomCount?: number;
  setNumberOfRoomCount: Dispatch<SetStateAction<number>>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TravelerPopover({
  showRoom,
  popoverTrigger,
  adultsCount,
  childrenCount,
  seniorsCount,
  setAdultsCount,
  setChildrenCount,
  setSeniorsCount,
  numberOfRoomCount = 0,
  setNumberOfRoomCount,
  isPopoverOpen,
  setIsPopoverOpen,
}: iProps) {
  const [count, setCount] = useState({
    adultCount: 0,
    childCount: 0,
    seniorCount: 0,
    roomCount: 0,
  });

  const handleUpdateData = () => {
    setAdultsCount(count.adultCount);
    setChildrenCount(count.childCount);
    setSeniorsCount(count.seniorCount);
    setNumberOfRoomCount(count.roomCount);
  };

  useEffect(() => {
    if (isPopoverOpen) {
      setCount({
        adultCount: adultsCount,
        childCount: childrenCount,
        seniorCount: seniorsCount,
        roomCount: numberOfRoomCount,
      });
    }
  }, [
    adultsCount,
    childrenCount,
    seniorsCount,
    numberOfRoomCount,
    isPopoverOpen,
  ]);

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <h1 className={styles.heading}>Travelers</h1>
          <div className={styles.counterContainer}>
            <BookingCounter
              heading1="Adults"
              heading2="Age 18+"
              count={count}
              setCount={setCount}
              roomType={false}
              category="adult"
            />
            <BookingCounter
              heading1="Children"
              heading2="Age 0-17"
              count={count}
              setCount={setCount}
              roomType={false}
              category="child"
            />
            <BookingCounter
              heading1="Seniors"
              heading2="Age 60+"
              count={count}
              setCount={setCount}
              roomType={false}
              category="senior"
            />
          </div>
          {showRoom && (
            <React.Fragment>
              <h1 className={styles.heading}>Rooms</h1>
              <div className={styles.counterContainer}>
                <BookingCounter
                  heading1="Number of Rooms"
                  heading2="Max 4 rooms"
                  count={count}
                  setCount={setCount}
                  roomType={true}
                  category="room"
                />
              </div>
            </React.Fragment>
          )}
        </div>
      }
      handleApplyButton={handleUpdateData}
    />
  );
}
