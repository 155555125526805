import React from "react";
import { Stack, Button, TextField } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  getUserDetailByTokenAPI,
  resetPassworAPI,
} from "../../api/resetPassword";
import { useParams } from "react-router-dom";
import { iGlobalContext } from "../../context/globalContext/interface";
import styles from "./resetPasswordPage.module.scss";
import { toastMessage } from "../../helpers/toast/toastMessage";

export default function ResetPasswordPage() {
  const state = React.useContext(StateContext);
  const {
    setPrevNav,
    nav,
    setNav,
    resetPasswordToken,
    setResetPasswordToken,
  }: iGlobalContext = state;

  const { id } = useParams();

  React.useEffect(() => {
    setResetPasswordToken(id);
    if (nav !== "") setPrevNav(nav);
    setNav("");
  }, []);

  React.useEffect(() => {
    if (id) getUserDetails();
    async function getUserDetails() {
      const response = await getUserDetailByTokenAPI(id || "");
      setUserId(response?.data.id);
      // console.log(response?.data.id);
    }
  }, [id]);

  // password state
  const [userId, setUserId] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const navigate = useNavigate();

  async function handleUpdateClick() {
    if (newPassword !== confirmPassword) {
      toastMessage.error("","New password not matching");
      return;
    }
    if (newPassword.length < 6) {
      toastMessage.error("","Password length should be atleast 6 character");
      return;
    }

    const response = await resetPassworAPI(userId, newPassword.trim());
    if (response === "Password updated successfully") {
      toastMessage.success("Success","Password updated, return to previous tab to continue.");
      setTimeout(() => {
        navigate("/");
        setNav("LANDING_PAGE");
      }, 4000);
    } else {
      toastMessage.error("OOPS!",response);
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.inputLabelContainer}>
        <label className={styles.label}>NEW PASSWORD</label>
        <TextField
          type="password"
          size="small"
          placeholder="Enter your new password"
          value={newPassword}
          onChange={(e: any) => {
            setNewPassword(e.target.value);
          }}
          InputProps={{
            style: {
              height: "30px",
              width: "280px",
              fontSize: "12px",
              color: "#696969",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px",
            },
          }}
        />
      </div>
      <div className={styles.inputLabelContainer}>
        <label className={styles.label}>CONFIRM PASSWORD</label>
        <TextField
          size="small"
          type="password"
          placeholder="Confirm your new password"
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
          InputProps={{
            style: {
              height: "30px",
              width: "280px",
              fontSize: "12px",
              color: "#696969",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px",
            },
          }}
        />
      </div>

      <Button
        onClick={handleUpdateClick}
        size="small"
        className={styles.button}
        sx={{
          color: "secondary.contrastText",
          backgroundColor: "secondary.main",
          ":hover": { bgcolor: "secondary.light" },
        }}
      >
        Save changes
      </Button>
    </div>
  );
}
