import axios from "axios";
import { ENDPOINT } from "../../constant/environment";

export async function modifyItineraryAPI(
  userId: string,
  userInput: string,
  messages: any,
  currentItineray: any,
  signal?: AbortSignal,
//   iteneryInput?: any,
) {
  const URL = `${ENDPOINT}/app/v1/itineraries/modifySearch`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    signal,
  };
  let body = {
    userId,
    userInput,
    messages,
    currentItineray,
    // iteneryInput,
  };
  try {
    if (!userId) return;
    // console.log(body);
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
