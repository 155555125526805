import React, { useContext } from "react";
import { arrowIcon, ROUTES, slidersRegular } from "../../../constant";
import styles from "./sidebar.module.scss";
import { SideBarData } from "../../../constant/dummyData";
import { useNavigate } from "react-router-dom";
import { iCurrentTripContext } from "../../../context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "../../../context/currentTripContext/currentTripContext";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

interface iProps {
  onClick: () => void;
}
function SideBarOptions({ onClick }: iProps): JSX.Element {
  const navigate = useNavigate();
  const globalContext = useContext(StateContext);
  const { setMyTripActiveTab }: iGlobalContext = globalContext;

  const handleNavigate = (pathInfo: string) => {
    if (pathInfo === ROUTES.Today)
      triggerGAEvent(GA_LABEL_ENUMS.Current_trips_click);
    if (pathInfo === ROUTES.MyTrips)
      triggerGAEvent(GA_LABEL_ENUMS.My_trips_click);
    if (pathInfo === ROUTES.Faqs)
      triggerGAEvent(GA_LABEL_ENUMS.home_help_click);
    if (pathInfo === ROUTES.PreviewPreference)
      triggerGAEvent(GA_LABEL_ENUMS.home_preferences_click);
    navigate(pathInfo);
    setMyTripActiveTab("UPCOMING");
    onClick();
  };
  const { currentTripData }: iCurrentTripContext = useCurrentTripContext();
  return (
    <div className={styles.main}>
      {JSON.stringify(currentTripData) !== "{}" && (
        <div
          className={styles.container}
          onClick={() => handleNavigate(ROUTES.Today)}
        >
          <img src={slidersRegular} alt="" className={styles.optionIcon} />
          <section className={styles.optionsCard}>
            <div className={styles.descCard}>
              <p className={styles.tripTitle}>Current Trip</p>
              <p className={styles.tripDesc}>
                Today’s details about your {currentTripData?.tripName} trip
              </p>
            </div>
            <div className={styles.arrowCard}>
              <img
                src={arrowIcon}
                alt=""
                className={styles.arrowIcon}
                // onClick={() => handleNavigate(i.routInfo)}
              />
            </div>
          </section>
        </div>
      )}
      {SideBarData.map((i, index) => (
        <div
          className={styles.container}
          key={index}
          onClick={() => handleNavigate(i.routInfo)}
        >
          <img src={i.icon} alt="" className={styles.optionIcon} />
          <section className={styles.optionsCard}>
            <div className={styles.descCard}>
              <p className={styles.tripTitle}>{i.title}</p>
              <p className={styles.tripDesc}>{i.desc}</p>
            </div>
            <div className={styles.arrowCard}>
              <img
                src={arrowIcon}
                alt=""
                className={styles.arrowIcon}
                // onClick={() => handleNavigate(i.routInfo)}
              />
            </div>
          </section>
        </div>
      ))}
    </div>
  );
}

export default SideBarOptions;
