import React, { useContext, useEffect, useState } from "react";
import styles from "./dailyTabs.module.scss";
import CustomAccordion from "../../reusableComponent/customAccordion/CustomAccordion";
import { Stack } from "@mui/material";
import Markdown from "react-markdown";
import { planeDepartureRegular } from "../../../constant";
import {
  getDayOfWeek,
  getUTCMonthDate,
  isSameDate,
} from "../../../utils/dateUtils";
import { ACTIVITY_TYPE_IDENTIFIER } from "../../../enums";
import BookedFlightAccordion from "../../reusableComponent/bookedFlightAccordion/BookedFlightAccordion";
import BookedHotelAccordion from "../../reusableComponent/bookedHotelCardList/BookedHotelAccordion";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

export default function DailyTabs({
  // dayWiseItinerary,
  flightDetails,
  startDate,
  hotelDetails,
}: {
  // dayWiseItinerary?: any;
  flightDetails?: any;
  startDate?: any;
  hotelDetails?: any;
}) {
  const globalContext = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = globalContext;

  function isShowingPlaneIcon(array: any) {
    return (
      array?.itinerary_by_time_of_the_day?.some((ele: any) =>
        Object.values(ele).includes(ACTIVITY_TYPE_IDENTIFIER.FlightIcon)
      ) || false
    );
  }

  function returnTimeOfDay(dayItinerary: any, activityType: any) {
    const tempData = dayItinerary?.itinerary_by_time_of_the_day?.find(
      (ele: any) => ele?.activity_type === activityType
    );
    if (tempData) return tempData?.time_of_the_day?.toLowerCase();
    return "";
  }

  console.log({ flightDetails });
  const inboundFlight =
    flightDetails &&
    flightDetails?.sectors?.length > 0 &&
    flightDetails?.sectors[0]?.inbound?.length > 0 &&
    // flightDetails?.bookingEngineData &&
    flightDetails?.sectors[0]?.inbound;
  const outboundFlight =
    flightDetails &&
    flightDetails?.sectors?.length > 0 &&
    flightDetails?.sectors[0]?.outbound?.length > 0 &&
    // flightDetails?.bookingEngineData &&
    flightDetails?.sectors[0]?.outbound;

  // console.log({ inboundFlight }, { outboundFlight });
  return (
    <div>
      {/* {flightDetails && (
        <BookedFlightAccordion
          hideExpandIcon={false}
          flightData={outboundFlight}
          flightImage={""}
        />
      )} */}
      {selectedChatData?.destinationList?.map((destinationEle: any) =>
        destinationEle?.tripDetails?.map((ele: any) => {
          const match = ele?.day.match(/\d+/); // Matches any digit(s) in the string
          let index = match ? parseInt(match[0], 10) - 1 : 0;
          // const activityDate = new Date(
          //   new Date(startDate).setDate(new Date(startDate).getUTCDate() + index)
          // );
          const outboundFlightDate =
            outboundFlight?.length > 0 && outboundFlight[0]?.departureDate;
          const inboundFlightDate =
            inboundFlight?.length > 0 && inboundFlight[0]?.departureDate;
          const hotelDate =
            hotelDetails?.length > 0 && hotelDetails[0]?.checkIn;
          return (
            <div key={index}>
              {outboundFlightDate &&
                outboundFlight?.map(
                  (outboundFlightEle: any, index: number) =>
                    isSameDate(
                      String(ele?.date),
                      outboundFlightEle?.departureDate
                    ) &&
                    (returnTimeOfDay(
                      ele,
                      ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                    ) === "morning" ||
                      returnTimeOfDay(
                        ele,
                        ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                      ) === "afternoon") && (
                      <div key={index} className={styles.flightAccord}>
                        <BookedFlightAccordion
                          hideExpandIcon={false}
                          flightData={outboundFlightEle}
                          flightImage={""}
                        />
                      </div>
                    )
                )}
              {inboundFlightDate &&
                inboundFlight?.map(
                  (inboundFlightEle: any, index: number) =>
                    isSameDate(
                      String(ele?.date),
                      inboundFlightEle?.departureDate
                    ) &&
                    (returnTimeOfDay(
                      ele,
                      ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                    ) === "morning" ||
                      returnTimeOfDay(
                        ele,
                        ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                      ) === "afternoon") && (
                      <div className={styles.flightAccord} key={index}>
                        <BookedFlightAccordion
                          hideExpandIcon={false}
                          flightData={inboundFlightEle}
                          flightImage={""}
                        />
                      </div>
                    )
                )}

              {hotelDate &&
                hotelDetails?.length > 0 &&
                hotelDetails?.map((hotel: any, index: number) => {
                  // const {
                  //   checkIn = "",
                  //   checkOut = "",
                  //   city = "",
                  //   hotelInformation = {},
                  //   roomInfo = {},
                  // } = ele;
                  // const { stars, name, hotelMainImage, address, hotelId } =
                  //   hotelInformation;
                  // const { noOfRooms, bedInformation = [] } = roomInfo;

                  // console.log({ hotel });
                  const {
                    // hotelMainImage,
                    // stars,
                    // name,
                    // address,
                    hotelInformation,
                    rating,
                    amenities,
                    totalPrice,
                    cancellationPolicy,
                    hotelBookId,
                    checkIn = "",
                    checkOut = "",
                    city = "",
                  } = hotel;
                  const {
                    stars,
                    name,
                    hotelMainImage,
                    address,
                    hotelId,
                    reviewCount,
                    reviewHeading,
                  } = hotelInformation;
                  if (isSameDate(String(ele?.date), hotel?.checkIn))
                    return (
                      <BookedHotelAccordion
                        checkIn={checkIn}
                        checkOut={checkOut}
                        // stars={stars}
                        // name={name}
                        // hotelMainImage={hotelMainImage}
                        // address={address}
                        // noOfRooms={noOfRooms}
                        // bedInformation={bedInformation}
                        // city={city}
                        // hotelId={hotelId}
                        hotelAccordionClick={() =>
                          triggerGAEvent(
                            GA_LABEL_ENUMS.trip_details_hotel_accordion_click
                          )
                        }
                        hotelViewDetailsClick={() =>
                          triggerGAEvent(
                            GA_LABEL_ENUMS.trip_details_hotel_view_deatils_accordion_click
                          )
                        }
                        id={index}
                        hotelMainImage={hotelMainImage}
                        star={stars}
                        hotelName={name}
                        hotelLocation={address}
                        hotelRating={rating}
                        reviewCount={reviewCount}
                        cost={totalPrice?.price}
                        cancellation={cancellationPolicy}
                        Amenity={amenities}
                        hotelList={true}
                        discount={""}
                        discountCost={""}
                        taxesIncluded={totalPrice?.taxesIncluded}
                        reviewHeading={reviewHeading}
                        hotelId={hotelId}
                        hotelDetails={hotel}
                        city={city}
                      />
                    );
                })}
              <CustomAccordion
                borderBottom
                zeroMarginOnExpand
                defaultExpanded={false}
                headingComponent={
                  <div
                    className={styles.mainHeaderContainer}
                    onClick={() => {
                      triggerGAEvent(
                        GA_LABEL_ENUMS.trip_details_daily_plans_flight_accordion_click
                      );
                    }}
                  >
                    <div className={styles.headingContainer}>
                      <div className={styles.subHeadingContainer}>
                        <h1>
                          {getDayOfWeek(ele?.date)} &nbsp;{" "}
                          {getUTCMonthDate(ele?.date)}
                          {/* {getDayOfWeek(String(activityDate))} &nbsp;{" "}
                          {getUTCMonthDate(String(activityDate))} */}
                        </h1>
                      </div>
                      <h2>{ele?.day_itinerary_description}</h2>
                    </div>
                    {/* {isShowingPlaneIcon(ele) && (
                      <img
                        src={planeDepartureRegular}
                        alt="plane"
                        className={styles.planeDepartureIcon}
                      />
                    )} */}
                  </div>
                }
                bodyComponent={
                  <Stack pt={1} spacing={1}>
                    {ele?.itinerary_by_time_of_the_day?.map(
                      (subEle: any, index: number) => {
                        return (
                          <div key={index} className={styles.activityContainer}>
                            <div className={styles.timeInADay}>
                              <h1 className={styles.title}>
                                {subEle?.time_of_the_day}
                              </h1>
                              <Markdown className={styles.description}>
                                {subEle?.description}
                              </Markdown>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Stack>
                }
              />
              {outboundFlightDate &&
                outboundFlight?.map(
                  (outboundFlightEle: any, index: number) =>
                    isSameDate(
                      String(ele?.date),
                      outboundFlightEle?.departureDate
                    ) &&
                    (returnTimeOfDay(
                      ele,
                      ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                    ) === "evening" ||
                      returnTimeOfDay(
                        ele,
                        ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                      ) === "night") && (
                      <div key={index} className={styles.flightAccord}>
                        <BookedFlightAccordion
                          hideExpandIcon={false}
                          flightData={outboundFlightEle}
                          flightImage={""}
                        />
                      </div>
                    )
                )}
              {inboundFlightDate &&
                inboundFlight?.map(
                  (inboundFlightEle: any, index: number) =>
                    isSameDate(
                      String(ele?.date),
                      inboundFlightEle?.departureDate
                    ) &&
                    (returnTimeOfDay(
                      ele,
                      ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                    ) === "evening" ||
                      returnTimeOfDay(
                        ele,
                        ACTIVITY_TYPE_IDENTIFIER.FlightIcon
                      ) === "night") && (
                      <div className={styles.flightAccord} key={index}>
                        <BookedFlightAccordion
                          hideExpandIcon={false}
                          flightData={inboundFlightEle}
                          flightImage={""}
                        />
                      </div>
                    )
                )}
            </div>
          );
        })
      )}
      {/* <div className={styles.flightAccord}>
        {flightDetails && (
          <BookedFlightAccordion
            hideExpandIcon={false}
            flightData={inboundFlight}
            flightImage={""}
          />
        )}
      </div>{" "} */}
    </div>
  );
}

const flight = {
  status: 200,
  data: [
    {
      transactionId: "vqkDQJSpuUTdIqFp_1",
      departure: {
        airport: "LHR",
        airportName: "London HeathRow Airport",
        date: "2024-11-20T12:35:00",
      },
      airlinePnr: "DXCB7G",
      totalTime: "04:00",
      flightNumber: "292",
      arrival: {
        airport: "DXB",
        airportName: "Dubai International Airport",
        date: "2024-11-23T12:35:00",
      },
      stops: [],
      airline: {
        code: "BA",
        name: "British Airways",
        image: "https://zenvoya-backend.s3.amazonaws.com/airline-images/BA.png",
      },
    },
    {
      transactionId: "vqkDASWEsssUTdIqFp_1",
      departure: {
        airport: "DXB",
        airportName: "Dubai International Airport",
        date: "2024-11-23T12:35:00",
      },
      airlinePnr: "DXCB7G",
      totalTime: "04:00",
      flightNumber: "252",
      arrival: {
        airport: "LHR",
        airportName: "London HeathRow Airport",
        date: "2024-11-20T12:35:00",
      },
      stops: [
        {
          airport: "DOH",
          airportName: "Doha International Airport",
          layover: "0:45",
        },
      ],
      airline: {
        code: "BA",
        name: "British Airways",
        image: "https://zenvoya-backend.s3.amazonaws.com/airline-images/BA.png",
      },
    },
  ],
};
