import React, { ChangeEvent } from "react";
import styles from "./CustomCheckbox.module.scss";

interface CustomCheckboxProps {
  label: string;
  name: string;
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedOptions: { [key: string]: boolean };
  travelerForm?: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  name,
  handleCheckboxChange,
  selectedOptions,
  travelerForm,
}) => {
  return (
    <label className={styles.customCheckboxContainer}>
      <section className={styles.inputBox}>
        <input
          type="checkbox"
          name={name}
          checked={selectedOptions[name]}
          onChange={handleCheckboxChange}
          className={styles.customCheckbox}
        />
        <span className={styles.checkmark}></span>
      </section>
      {travelerForm ? (
        <p
          className={`${styles.label} ${selectedOptions[name] ? styles.selected : ""}`}>
          {label}
        </p>
      ) : (
        <p className={styles.label}>{label}</p>
      )}
    </label>
  );
};

export default CustomCheckbox;
