export function updateDaywiseTripDate(itinerary: any, startDate: string) {
  if (!itinerary) return null;
  return {
    ...itinerary,
    city_wise_itinerary: itinerary?.city_wise_itinerary?.map((cityELE: any) =>
      cityELE?.city_itinerary?.map((ele: any) => {
        const match = ele?.day.match(/\d+/); // Matches any digit(s) in the string
        let count = match ? parseInt(match[0], 10) - 1 : 0;
        return {
          ...ele,
          date: getYYYYMMDD(
            String(
              new Date(
                new Date(startDate).setDate(
                  new Date(startDate).getUTCDate() + count
                )
              )
            )
          ),
        };
      })
    ),
  };
}

export function updateDaywiseTripDateForMetaData(
  destinationList: any,
  startDate: string
) {
  if (!destinationList || destinationList?.length === 0) return null;
  return destinationList?.map((destEle: any) => ({
    ...destEle,
    tripDetails: destEle?.tripDetails?.map((ele: any) => {
      const match = ele?.day.match(/\d+/); // Matches any digit(s) in the string
      let count = match ? parseInt(match[0], 10) - 1 : 0;
      return {
        ...ele,
        date: getYYYYMMDD(
          String(
            new Date(
              new Date(startDate).setDate(new Date(startDate).getUTCDate() + count)
            )
          )
        ),
      };
    }),
  }));
}

function getYYYYMMDD(dateStr: string) {
  return new Date(dateStr).toLocaleDateString("en-CA");
}
