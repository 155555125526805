import React from "react";
import styles from "./stripeSuccess.module.scss";
import { bookedSuccesIcon } from "../../constant";
import { TOAST_MESSAGES } from "../../constant/toastMessages";
import { Button } from "@mui/material";

export default function StripeSuccess() {
  return (
    <div className={styles.successContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.booking}>
          <img src={bookedSuccesIcon} alt="booked" />
          <h1>{TOAST_MESSAGES.BOOKING.BOOK.bookingSuccess.messageTitle}</h1>
        </div>
        <p className={styles.informationText}>
          {TOAST_MESSAGES.BOOKING.BOOK.bookingSuccess.message}
        </p>
        <h1 className={styles.confirmationNumber}>SDF-154-S85</h1>
        <Button className={styles.button} onClick={() => {}}>
          View Itinerary
        </Button>
      </div>
    </div>
  );
}
