import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { Stack, Button, TextField } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import fb from "../../../assets/fb.svg";
import google from "../../../assets/google.svg";
import { StateContext } from "../../../context/globalContext/context";
import FacebookLogin from "@greatsumini/react-facebook-login";
import GoogleLogin from "@leecheuk/react-google-login";
import { createUserAPI } from "../../../api/createUser";
import { checkLogin } from "../../../utils/loginAndLocalStorageutils";
import {
  APP_CONFIG,
  DialogSelected,
  emailRegex,
} from "../../../constant/common";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { getCategoryAPICall } from "../../../api/getCategory";
import { getCategoryArrayObj } from "../../../utils/categoryUtils";
import { getCurrentMonthWithTheNextTweleMonthAPI } from "../../../api/geCurrentMonthWithTheNextTwelveMonth";
import { getUserDetailsApi } from "../../../api/getUserDetails";
import styles from "./create.module.scss";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { getConfigMessage } from "../../../utils";
import AppleSignInButton from "react-apple-signin-auth";
import { appleIcon } from "../../../constant";
import jwtDecode from "jwt-decode";
import { APP_URL, WEB_URL } from "../../../constant/environment";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import { TOAST_MESSAGES } from "../../../constant/toastMessages";
import toast from "react-hot-toast";

export default function CreateAccountDialog() {
  const [userName, setUserName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const emailInputRef = useRef<HTMLDivElement>(null);
  const passwordInputRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  let phone = "";
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const state = React.useContext(StateContext);
  const {
    setLoginCreateAccountState,
    loginCreateAccountState,
    setUserDetails,
    setIsLoggedIn,
    signInClick,
    setSignInClick,
    climate,
    setClimate,
    population,
    setPopulation,
    experience,
    setExperience,
    budget,
    setBudget,
    months,
    setMonths,
    setUserInfo,
    userInfo,
    appConfig,
    userDetails,
    setIsFetchingAirports,
  }: iGlobalContext = state;
  const CATEGORY_KEY = "_all_category";
  const loginFailureMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.LOGIN)?.value ??
    "Incorrect Email or Password";
  const signUpErrorMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.SIGN_UP)?.value ??
    "Email already exists, please go to login screen.";
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    toast.dismiss();
    if (value.length > 100) {
      setError("Name cannot exceed 100 characters.");
    } else {
      setError("");
    }
    setUserName(value);
  };

  const handleNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      emailInputRef.current?.focus();
    }
  };

  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!emailRegex.test(email)) {
        setEmailError("Enter a valid email address");
      } else {
        setEmailError("");
        passwordInputRef.current?.focus();
      }
    }
  };

  function handleLogin() {
    triggerGAEvent(GA_LABEL_ENUMS.Login_in_click);
    setLoginCreateAccountState({
      ...loginCreateAccountState,
      page: DialogSelected.LOGIN,
    });
  }

  function handleCancel() {
    setSignInClick(false);
    setIsFetchingAirports(false);
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
  }

  async function getUserDetails(userId: string) {
    const response = await getUserDetailsApi(userId);
    if (response) {
      setUserInfo({
        ...userInfo,
        name: response.name,
        email: response.email,
        phone: response.phone,
        profile_pic: response.profile_pic,
        loggedIn: true,
      });
    }
    if (!response) {
      console.error("Something went wrong");
      return;
    }
  }

  const getCategoryAPI = useCallback(async () => {
    const storedCategory = window.localStorage.getItem(CATEGORY_KEY);

    if (storedCategory) {
      return;
    }

    try {
      const response = await getCategoryAPICall();
      if (response && response) {
        window.localStorage.setItem(CATEGORY_KEY, JSON.stringify(response));
      } else {
        console.error("Failed to fetch the data");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching the category data:",
        error
      );
    }
  }, []);

  async function handleCreateAccount() {
    if (!emailRegex.test(email)) {
      setEmailError(
        TOAST_MESSAGES.AUTHENTICATION.accountCreationInvalidEmail.message
      );
      return;
    }
    if (userName === "") {
      setError(
        TOAST_MESSAGES.AUTHENTICATION.accountCreationInvalidName.message
      );
      return;
    }
    if (password === "" || password.length < 6) {
      setPasswordError(
        TOAST_MESSAGES.AUTHENTICATION.accountCreationInvalidPassword.message
      );
      return;
    }

    triggerGAEvent(GA_LABEL_ENUMS.Create_new_account_click);

    const response = await createUserAPI(
      email.trim(),
      userName.trim(),
      password.trim(),
      phone
    );
    // Null check for the API response
    // console.log({ response });
    if (response && response?.statusCode === 200) {
      setIsLoggedIn(true);
      getCategoryAPI();
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.ADD_PREFER,
      });
      if (response?.data && response?.data.userId)
        getUserDetails(response?.data.userId);
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        name: userName,
        email: email,
        phone: phone,
        loggedIn: true,
      }));

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        name: userName,
        email: email,
        id: response?.data.userId,
        phone: phone,
        loggedIn: true,
      }));

      ReactGA.event({
        category: "Button Click",
        action: `Click on Create Account`,
        label: `Create account pop up dialog`,
      });
      /**
       * post message data
       */
      if (window && window.parent) {
        // console.log("Window.parent", window.parent);
        const message = {
          action: "CreateAccount",
          data: {
            ...userDetails,
            name: userName,
            email: email,
            id: response?.data.userId,
            phone,
            loggedIn: true,
          },
        };
        // console.log("MESSAGE", message);
        window.parent.postMessage(JSON.stringify(message)); // or specify a specific origin instead of "*"
      }
    } else if (response?.statusCode === 400) {
      toastMessage.error(
        TOAST_MESSAGES.AUTHENTICATION.accountCreationEmailExist.messageTitle,
        TOAST_MESSAGES.AUTHENTICATION.accountCreationEmailExist.message
      );
    } else {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    }
  }

  async function getCategory() {
    const response = await getCategoryAPICall();
    // Save Category to Local
    if (response && response) {
      window.localStorage.setItem(CATEGORY_KEY, JSON.stringify(response));
    }

    if (!response) {
      console.error("Failed to fetch the data");
      return;
    }
    // Check if the state of the category are empty, then get them from the API
    for (let i = 0; i < response.length; i++) {
      if (response[i].name === "Climate" && climate.length === 0)
        setClimate(getCategoryArrayObj(response[i]));
      if (response[i].name === "Population" && population.length === 0)
        setPopulation(getCategoryArrayObj(response[i]));
      if (response[i].name === "Experiences" && experience.length === 0)
        setExperience(getCategoryArrayObj(response[i]));
      if (response[i].name === "Budget" && budget.length === 0)
        setBudget(response[i]);
    }
    if (months.length === 0)
      setMonths(getCurrentMonthWithTheNextTweleMonthAPI());
    else if (months[1].monthIndex < new Date().getUTCMonth())
      setMonths(getCurrentMonthWithTheNextTweleMonthAPI());
  }

  useEffect(() => {
    const storedData = localStorage.getItem(CATEGORY_KEY);
    const data = storedData ? JSON.parse(storedData) : [];

    if (data && data.length > 0) {
      const accData = data.find(
        (item: { name: string }) => item.name === "Food Preferences"
      );

      if (accData && accData.tag) {
        // console.log(accData.tag);
      }
    } else {
      getCategory();
    }
  }, []);

  const handlePasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleCreateAccount();
    }
  };

  return (
    <Stack
      className={styles.main}
      sx={{
        mt: { sm: 0, xs: 2 },
      }}
    >
      <Stack
        sx={{
          width: { xs: "100vw" },
          maxWidth: { md: "350px" },
        }}
      >
        <Stack
          className={styles.mainCard}
          px={{ xs: 3, sm: 3, md: 3, lg: 0, xl: 0 }}
        >
          <Stack alignSelf="end" mb={2} mr={1.2}>
            <img
              onClick={handleCancel}
              src={updateCloseIcon}
              alt="cross"
              className={styles.closeIcon}
            />
          </Stack>

          <Stack
            sx={{
              maxHeight: {
                sm: "90vh",
                xs: "90vh",
                md: "calc(100% - 15%)",
                lg: "90vh",
              },
            }}
            className={styles.container}
          >
            <Stack spacing={3} className={styles.card}>
              <Stack
                alignItems="center"
                spacing={2}
                className={styles.socialMedia}
              >
                <FacebookLogin
                  appId="1477646912639104"
                  onSuccess={(response) => {
                    setUserDetails((prevDetails) => ({
                      ...prevDetails,
                      name: "",
                      email: "",
                    }));
                  }}
                  onFail={(error) => {
                    console.error("Login Failed!", error);
                  }}
                  onProfileSuccess={(response: any) => {
                    login(response.email, response.name);
                    async function login(email: string, name: string) {
                      await checkLogin(
                        email.toLowerCase().trim(),
                        "Google",
                        name,
                        "",
                        setUserDetails,
                        setIsLoggedIn,
                        signInClick,
                        setLoginCreateAccountState,
                        loginCreateAccountState,
                        setSignInClick,
                        loginFailureMessage
                      );
                    }
                  }}
                  className={styles.facebookBtn}
                >
                  <Stack
                    className={styles.facebookCard}
                    onClick={() =>
                      triggerGAEvent(GA_LABEL_ENUMS.Facebook_login_click)
                    }
                  >
                    <img src={fb} alt={fb} className={styles.fbImg} />
                    <h3 className={styles.facebookTitle}>
                      LOG IN WITH FACEBOOK
                    </h3>
                  </Stack>
                </FacebookLogin>

                <GoogleLogin
                  clientId="643694378557-60uta6pjlhds86si515v007r4ecuulrg.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      className={styles.googleBtn}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <Stack
                        className={styles.googleCard}
                        onClick={() =>
                          triggerGAEvent(GA_LABEL_ENUMS.Google_login_click)
                        }
                      >
                        <img
                          className={styles.googleImg}
                          src={google}
                          alt={google}
                        />
                        <h3 className={styles.googleTitle}>
                          LOG IN WITH GOOGLE
                        </h3>
                      </Stack>
                    </button>
                  )}
                  onSuccess={(response: any) => {
                    // console.log("GOOGLE RES", response);

                    const name =
                      response.profileObj.givenName +
                      " " +
                      response.profileObj.familyName;
                    const email = response.profileObj.email;
                    login();
                    async function login() {
                      await checkLogin(
                        email.toLowerCase().trim(),
                        "Google",
                        name,
                        "",
                        setUserDetails,
                        setIsLoggedIn,
                        signInClick,
                        setLoginCreateAccountState,
                        loginCreateAccountState,
                        setSignInClick,
                        loginFailureMessage
                      );
                    }

                    setLoginCreateAccountState({
                      ...loginCreateAccountState,
                      flag: false,
                    });
                  }}
                  onFailure={(error: any) => {
                    // console.log(error);
                  }}
                  cookiePolicy={"single_host_origin"}
                  style={{
                    width: "100%",
                    visibility: "hidden",
                    backgroundColor: "black",
                  }}
                ></GoogleLogin>
                <AppleSignInButton
                  /** Auth options passed to AppleID.auth.init() */
                  authOptions={{
                    clientId: "com.example.web",
                    scope: "email name",
                    redirectURI: APP_URL,
                    state: "",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  onSuccess={(response: any) => {
                    // console.log(response);
                    const decodedUserDetails: any = jwtDecode(
                      response?.authorization?.id_token
                    );
                    if (response?.user) {
                      login(response?.user?.name?.firstName);

                      setLoginCreateAccountState({
                        ...loginCreateAccountState,
                        flag: false,
                      });
                      return;
                    }
                    if (response?.authorization) {
                      login("");
                      setLoginCreateAccountState({
                        ...loginCreateAccountState,
                        flag: false,
                      });
                      return;
                    }
                    async function login(userName: string) {
                      const userId = await checkLogin(
                        decodedUserDetails?.email.toLowerCase().trim(),
                        "Apple",
                        userName,
                        "",
                        setUserDetails,
                        setIsLoggedIn,
                        signInClick,
                        setLoginCreateAccountState,
                        loginCreateAccountState,
                        setSignInClick,
                        loginFailureMessage
                      );
                      if (userId) getUserDetails(userId);
                    }
                  }}
                  /** Called upon signin error */
                  onError={(error: any) => console.error(error)}
                  /** General props */
                  uiType="dark"
                  /** className */
                  className="apple-auth-btn"
                  /** Allows to change the button's children, eg: for changing the button text */
                  buttonExtraChildren="Continue with Apple"
                  render={(renderProps: any) => (
                    <button
                      style={{
                        border: "1px solid #979797",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      {...renderProps}
                      // onClick={renderProps.onClick}
                      // disabled
                    >
                      <Stack
                        direction="row"
                        height={42}
                        borderRadius={1}
                        alignItems="center"
                        spacing={0}
                        bgcolor=" #ffff"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          triggerGAEvent(GA_LABEL_ENUMS.Apple_login_click)
                        }
                      >
                        <img
                          style={{ height: "42px" }}
                          src={appleIcon}
                          alt={appleIcon}
                        />
                        <h3
                          style={{
                            marginLeft: "-14px",
                            color: "#272727",
                            fontSize: "11px",
                            fontFamily: "Lato",
                            letterSpacing: "0.5px",
                            width: "100%",
                            textAlign: "center",
                            alignSelf: "center",
                          }}
                        >
                          LOG IN WITH APPLE
                        </h3>
                      </Stack>
                    </button>
                  )}
                />
              </Stack>

              <Stack className={styles.orCard}>
                <h5 className={styles.orTitle}>OR</h5>
              </Stack>

              <Stack spacing={2} className={styles.inputCard}>
                <Stack className={styles.nameCard}>
                  <h2 className={styles.name}>YOUR NAME</h2>
                  <TextField
                    size="small"
                    placeholder="enter your first and last name"
                    value={userName}
                    onChange={handleNameChange}
                    onKeyDown={handleNameKeyDown}
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#696969",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    error={!!error}
                    helperText={error}
                  />
                </Stack>
                <Stack className={styles.emailCard}>
                  <h2 className={styles.email}>EMAIL ADDRESS</h2>
                  <TextField
                    inputRef={emailInputRef}
                    type="email"
                    size="small"
                    placeholder="enter your email"
                    value={email}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                      setEmailError("");
                      toast.dismiss();
                    }}
                    onKeyDown={handleEmailKeyDown}
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#696969",
                        textDecoration: "none",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    error={!!emailError}
                    helperText={emailError}
                  />
                </Stack>
                <Stack className={styles.emailCard}>
                  <h2 className={styles.email}>PASSWORD</h2>
                  <TextField
                    type="password"
                    size="small"
                    placeholder="enter password"
                    inputRef={passwordInputRef}
                    value={password}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                      toast.dismiss();
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "100%",
                        fontSize: "12px",
                        backgroundColor: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onKeyDown={handlePasswordKeyDown}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Stack>
              </Stack>

              <Stack className={styles.privacyCard} spacing={2}>
                <h6 className={styles.createAccount}>
                  By creating an account, you agree to our{" "}
                  <a
                    href={`${WEB_URL}privacy-policy`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      triggerGAEvent(GA_LABEL_ENUMS.Privacy_polcy_click);
                    }}
                  >
                    <b className={styles.privacy}>privacy policy</b>
                  </a>
                </h6>
                <h6 className={styles.createAccount}>
                  Already have an account?{" "}
                  <b onClick={handleLogin} className={styles.privacy}>
                    Log in
                  </b>
                </h6>
              </Stack>
            </Stack>
            <Button
              variant="contained"
              className={styles.createBtn}
              onClick={handleCreateAccount}
            >
              <h5 className={styles.btnName}>CREATE ACCOUNT</h5>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
