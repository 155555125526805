import React, { useContext, useEffect, useRef, useState } from "react";
import { Stack, Button } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { useLocation, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { checkIfUserIsLoggedIn } from "../../utils/loginAndLocalStorageutils";
import BackButtonHandler from "../../component/BackButtonHandler";
import { APP_URL } from "../../constant/environment";
import styles from "./itineraryDetails.module.scss";
import { iGlobalContext } from "../../context/globalContext/interface";
import ReactGA from "react-ga4";
import { APP_CONFIG, DialogSelected } from "../../constant/common";
import CityTabs from "./cityTabs/CityTabs";
import {
  backIcon,
  buyIcon,
  cartIcon,
  closeWhiteIcon,
  doneIcon,
  editIcon,
  ROUTES,
  saveIcon,
  SENDING_CHAT_MESSAGE,
  shareIcon,
} from "../../constant";
import { BookingDetails, iChatData, iCityDetailsResult } from "../../types";
import {
  apiFailedTryAgain,
  checkIfThereIsDestination,
  getConfigMessage,
  getItineraryVersion,
  scrollToTop,
} from "../../utils";
import { ScreenTypes } from "../../enums";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { v4 } from "uuid";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import { modifyItineraryAPI } from "../../api/llm/modifyItinerary";
import toast from "react-hot-toast";
import { saveItineraryDetailsAPI } from "../../api/saveItineraryDetails";
import { iHistoryContext } from "../../context/historyContext/interface";
import { useHistoryContext } from "../../context/historyContext/historyContext";
import { getAirports } from "../../api/booking";
import { getPromptAPI } from "../../api/getPrompt";
import Loader from "../../component/reusableComponent/loader/Loader";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";
import { TOAST_MESSAGES } from "../../constant/toastMessages";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import MessagePopupDialog from "./messagePopupDialog/MessagePopupDialog";
import useValidateDate from "../../hooks/useValidateDate";

let abortPlanTripDetails = new AbortController();

export default function ItineraryDetails() {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setSaveScreenState,
    setLoginCreateAccountState,
    setNav,
    setIsLoggedIn,
    setMenuOpenState,
    tripForNDays,
    setTripForNDays,
    singleItineraryDetails,
    setExtraMeta,
    extraMeta,
    sharedItineraryId,
    setSharedItineraryId,
    setUserDetails,
    setDataStream,
    query,
    setShareClick,
    isItinerarySavedFlag,
    setIsItinerarySavedFlag,
    setSelectedChatData,
    chatData,
    selectedChatData,
    scrollContainerRef,
    setScreen,
    isMobile,
    screen,
    setChatData,
    userInfo,
    setUserInfo,
    setModifyItineraryPrompt,
    userDetails,
    selectedCityListPrompContext,
    setBookingDetails,
    setSelectedCityListPrompContext,
    setSelectedFlightOptions,
    selectedFlightOptions,
    setFlightData,
    setHotelData,
    appConfig,
    setShowMore,
    setAirPorts,
    isFetchingAirports,
    setIsFetchingAirports,
    setPrevRoute,
    setHotelPagination,
    prevRoute,
  }: iGlobalContext = state;
  const { saveHistory }: iHistoryContext = useHistoryContext();
  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const validateDate = useValidateDate();
  // Axios controller to cancel API call
  const controller = new AbortController();
  const controllerAbortUpdateTrip = new AbortController();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [openModifyItineraryDialog, setOpenModifyItineraryDialog] = useState({
    isOpen: false,
    message: "",
  });
  const isDesktopOrTablet = window.matchMedia("(min-width: 768px)").matches;
  React.useEffect(() => {
    scrollToTop(scrollContainerRef);
    window.scrollTo(0, 0);
    setNav("");
    setTripForNDays([]);
    setDataStream("");
    const localPlanTrip = window.sessionStorage.getItem("_extra_meta");
    if (localPlanTrip !== null) {
      setTripForNDays(JSON.parse(localPlanTrip).planTripArray);
    }
    if (!userInfo.loggedIn) checkIfUserIsLoggedIn(setUserInfo);
    return () => {
      controllerAbortUpdateTrip.abort();
      controller.abort();
      setDataStream("");
    };
  }, []);
  React.useEffect(() => {
    if (sharedItineraryId !== "") setIsItinerarySavedFlag(true);
    else {
      setIsItinerarySavedFlag(false);
    }
  }, [sharedItineraryId]);

  // Save Itinerary
  function handleSaveItinerary() {
    if (selectedChatData?.isSaved) return;
    setExtraMeta({
      ...extraMeta,
      planTripArray: tripForNDays,
      query: query,
    });

    setMenuOpenState(false);
    setSaveScreenState(true);
    if (userInfo.loggedIn)
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.SAVE_ITINERARY,
      });
    else setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
  }

  // Onclick function to handle Back button click
  function handleBackButton() {
    // console.log("YES");
    if (isMobile) {
      // navigate(prevRoute || ROUTES.TopDestination);
      window?.history?.back();
    } else {
      // if (screen === ScreenTypes.SingleItinerary) {
      //   setScreen("");
      //   return;
      // }
      // setScreen(ScreenTypes.Reorder);
      setScreen("");
    }
  }
  interface iCity extends Array<iCityDetailsResult> {}

  const transformData = (data: iCity) => {
    const homeCity = {
      name: selectedChatData?.fromCity || selectedChatData?.from || "",
      latitude: selectedChatData.from_latitude || "",
      longitude: selectedChatData.from_longitude || "",
    };
    if (data)
      return [
        homeCity,
        ...data?.map((city) => ({
          // name:`${city.city_name}, ${city.city_state}, ${city.city_country}` || city.cityName,
          name: city.city_state
            ? `${city.city_name}, ${city.city_state}, ${city.city_country}`
            : `${city.city_name || city.destination_city}, ${city.city_country || city.destination_city}`,
          latitude: city.city_latitude || "",
          longitude: city.city_longitude || "",
        })),
      ];
    else return [homeCity];
  };
  let fetchAirportController: any = useRef(null);
  const transformedData =
    transformData(selectedChatData?.destinationList) || [];
  const uniqueLocations =
    Array.isArray(transformedData) && transformedData.length > 0
      ? transformedData.filter(
          (location, index, self) =>
            index ===
            self.findIndex(
              (loc) =>
                loc.latitude === location?.latitude &&
                loc.longitude === location?.longitude
            )
        )
      : [];

  const getAirportData = async () => {
    if (!uniqueLocations || uniqueLocations.length === 0) {
      console.error("No valid locations to fetch airport data.");
      setShowLoader(false);
      return;
    }

    const requestBody = {
      cities: uniqueLocations,
      radius: "100 miles",
    };
    fetchAirportController.current = new AbortController();

    setShowLoader(true);

    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current
      );
      if (fetchAirportController.current.signal.aborted) {
        setShowLoader(false);
        return null;
      }
      setAirPorts(data);
      setShowLoader(false);
      setIsFetchingAirports(false);
      return data;
    } catch (err) {
      setShowLoader(false);
      setAirPorts([]);
      setIsFetchingAirports(false);
      console.error("Failed to fetch flight data");
      return [];
    }
  };
  const [itineraryInfo, setItineraryInfo] = useState<BookingDetails>();

  // const getItineraryDetails = async () => {
  //   let itineraryId = selectedChatData.id;
  //   if (!itineraryId) {
  //     return;
  //   }

  //   const response = await getItineraryDetailsAPI(itineraryId);
  //   if (response?.statusCode !== 200) {
  //     return;
  //   }
  //   // // console.log(
  //   //   { response },
  //   //   JSON.parse(response?.data?.metaData)[0]?.selectedChatData
  //   // );

  //   setSelectedChatData(
  //     JSON.parse(response?.data?.metaData)[0]?.selectedChatData
  //   );

  //   const updatedBookingDetails = {
  //     ...response.data.bookFilter,
  //   };
  //   setItineraryInfo(updatedBookingDetails);
  //   // setBookingDetails(updatedBookingDetails);
  // };

  const getItineraryDetails = async () => {
    const { id: itineraryId } = selectedChatData;
    if (!itineraryId) return;

    try {
      const response = await getItineraryDetailsAPI(itineraryId);
      if (response?.statusCode !== 200) return;

      const metaData = JSON.parse(response?.data?.metaData);
      const chatData = metaData?.[0]?.selectedChatData;
      if (chatData) setSelectedChatData(chatData);

      const updatedBookingDetails = { ...response.data.bookFilter };
      setItineraryInfo(updatedBookingDetails);
    } catch (error) {
      console.error("Error fetching itinerary details:", error);
    }
  };

  useEffect(() => {
    getItineraryDetails();
  }, []);

  async function handleShare() {
    if (!selectedChatData?.isSaved) {
      setShareClick(true);
      setTimeout(() => {
        handleSaveItinerary();
      }, 1000);
      // toastMessage.info("Please save the Itinerary before sharing!");
    }
  }

  const [noOfCitySelected, setNoOfCitySelected] = useState(1);
  useEffect(() => {
    setNoOfCitySelected(
      selectedChatData?.destinationList?.reduce(
        (acc: number, destListEle: iCityDetailsResult) => {
          if (destListEle.checkedFlag) return acc + 1;
          else return acc;
        },
        0
      )
    );
  }, [selectedChatData]);

  function handleOnShare() {
    ReactGA.event({
      category: "Button Click",
      action: `Click on Share Trip Details`,
      label: `User is sharing the itinerary ${APP_URL}/shared/${selectedChatData?.id}`,
    });
    setTimeout(() => {
      toastMessage.success(
        TOAST_MESSAGES.ShareItinerary.shareSuccess.messageTitle,
        TOAST_MESSAGES.ShareItinerary.shareSuccess.message
      );
    }, 100);
  }

  const [tempSelectedChatData, setTempSelectedChatData] = useState(
    selectedChatData?.destinationList?.filter(
      (ele: iCityDetailsResult) => ele.checkedFlag
    )
  );

  useEffect(() => {
    setTempSelectedChatData(
      selectedChatData?.destinationList?.filter(
        (ele: iCityDetailsResult) => ele.checkedFlag
      )
    );
  }, [selectedChatData]);

  function handleEdit() {
    if (isMobile) navigate(ROUTES.TopDestination);
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    );
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: inputValue,
        isUserMessage: true,
        prompt: selectedChatData?.prompt,
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: selectedChatData?.prompt,
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
  }

  async function handleSendMessage() {
    toast.dismiss();
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    ); //  used to enable auto scroll in Destop
    // setCallSaveHistoryAPIFlag(false);
    // scrollToBottom(desktopScrollContainerRef);
    if (chatData.length > 0 && chatData[chatData.length - 1].isLoading) {
      toastMessage.info(TOAST_MESSAGES.SaveItinerary.inputFieldLoad.message);
      return;
    }
    if (inputValue === "") {
      toastMessage.info(TOAST_MESSAGES.SaveItinerary.inputFieldEnter.message);
      return;
    }
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: inputValue,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setInputValue("");
    setDisableInput(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    // const modifyItineraryPromptResponse = await getPromptAPI(
    //   APP_CONFIG.MODIFY_ITINERARY_PROMPT
    // );
    // const modifyItineraryPrompt = modifyItineraryPromptResponse.filter(
    //   (ele: any) => ele.key === APP_CONFIG.MODIFY_ITINERARY_PROMPT
    // );
    // // console.log({ modifyItineraryPromptResponse }, { modifyItineraryPrompt });
    // const backupPrompt = `Above is the data and the response for single or multiple city, modify it as instructed, and also with the correct number of days mentioned and update the itinerary_version and if there are multiple city, the day wise activity should be overall and also each day should have atleast morning, afternoon and evening activity. Also return the response in the same format without missing data. Note: If there are multiple city do not remove any city from the trip when modifying the number of days`;
    // const promptContext = [
    //   // selectedCityListPrompContext ||
    //   selectedChatData?.prompt[selectedChatData?.prompt.length - 1] ||
    //     selectedChatData?.itineraryPromptContext,
    //   {
    //     role: "user",
    //     // content: selectedChatData?.tripName,
    //     // content: `Above is the data and the response for ${selectedChatData?.tripName}, modify it as instructed and update the itinerary_version`,
    //     content:
    //       modifyItineraryPrompt?.length > 0
    //         ? modifyItineraryPrompt[0]?.value || backupPrompt
    //         : backupPrompt,
    //   },
    // ];
    let response = await modifyItineraryAPI(
      userDetails?.id || "",
      inputValue,
      // selectedChatData?.prompt,
      // promptContext,
      [],
      selectedChatData?.results || {},
      abortController.signal
      // selectedChatData?.iteneryInput || null
    );
    // let response = await chatInteractionAPI(
    //   userDetails?.id || "",
    //   selectedChatData?.iteneryInput
    //     ? "For " + selectedChatData?.tripName + ". " + inputValue
    //     : inputValue,
    //   selectedChatData?.prompt,
    //   abortController.signal,
    //   // selectedChatData?.iteneryInput || null
    //   null
    // );

    // console.log({ response }, abortController.signal.aborted);

    if (!response && !abortController.signal.aborted) {
      setDisableInput(false);
      setChatData((prev: iChatData[]) => [...prev.splice(0, prev.length - 1)]);
      toastMessage.error(
        "OOPS!",
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return;
    }
    response = checkIfThereIsDestination(response);
    if (response === "JSON_ERROR") {
      setChatData((prev: iChatData[]) => [...prev.splice(0, prev.length - 1)]);
      setDisableInput(false);
      toastMessage.error(
        TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
        // getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        TOAST_MESSAGES.SaveItinerary.destinationError.message
      );
      return;
    }
    let citiesList = [];
    if (response?.data?.cities) {
      citiesList = response?.data?.cities;
    }
    processedAndGetDestinationImageURL(citiesList, response, false);
    setDisableInput(false);
    // scrollToBottom(desktopScrollContainerRef);
    // setCallSaveHistoryAPIFlag(true);
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${inputValue}`,
    });
  }
  async function processedAndGetDestinationImageURL(
    citiesList: iCityDetailsResult[],
    response: any,
    showMore: boolean
  ) {
    if (citiesList.length > 0) {
      setSelectedCityListPrompContext(null);
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        // checkedFlag: citiesList.length === 1 ? true : false,
        // checkedFlag: response?.data?.singleCity,
        checkedFlag: true,
        locations: [],
      }));

      const newId = v4();
      const itineraryVersion = getItineraryVersion(
        chatData,
        response?.data?.results
      );
      setSelectedChatData({
        ...response?.data,
        city_wise_itinerary: [],
        cities: [],
        messages: [],
        result: "",
        fromCity: response?.data?.from,
        noOfDays: response?.data?.numberOfdays,
        id: newId,
        message: "",
        preText: response?.data?.preText,
        postText: response?.data?.postText,
        // prompt: response?.data?.messages,
        prompt: [],
        isUserMessage: false,
        isLoading: false,
        singleSelectedDestination: response?.data?.singleCity,
        destinationList: tempResponse,
        itinerary_version: itineraryVersion,
      });
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          ...response?.data,
          city_wise_itinerary: [],
          cities: [],
          messages: [],
          result: "",
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: showMore ? prev[prev.length - 1].id : newId,
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          // prompt: response?.data?.messages,
          prompt: [],
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: showMore
            ? [...prev[prev.length - 1].destinationList, ...tempResponse]
            : tempResponse,
          itinerary_version: itineraryVersion,
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName = currentItineraryList[i].cityName;
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 3) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          // console.log("RESPONSE TOP Error");
        }
      }

      ReactGA.event({
        category: "Top Destination",
        action: `User has recieved the itinerary list created`,
        label: `User prompt is ${response?.data?.query}`,
      });
    } else {
      setChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 2),
        // {
        //   id: v4(),
        //   message: response?.data?.result,
        //   // prompt: response?.data?.messages,
        //   prompt: [],
        //   isUserMessage: false,
        //   isLoading: false,
        //   destinationList: [],
        //   singleSelectedDestination: false,
        // },
      ]);
      // console.log({ response });
      setOpenModifyItineraryDialog({
        message: response?.data?.result || "",
        isOpen: true,
      });
      // toastMessage.error(
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
    }
  }

  useEffect(() => {
    if (!isFetchingAirports) {
      fetchAirportController?.current?.abort();
    }
  }, [isFetchingAirports]);
  /**
   * chat input
   */
  const [inputValue, setInputValue] = useState("");

  /**
   * Handling cancelling Send message or
   * open Itinerary details or Show More
   */
  const [disableInput, setDisableInput] = useState<boolean>(false);

  const [showChatLoaderFlag, setShowChatLoaderFlag] = useState(false);
  function handleCancel() {
    setShowChatLoaderFlag(false);
    setScreen("");
    if (chatData[chatData.length - 1].prompt.length === 0) {
      const tempChatData = chatData;
      setChatData(tempChatData.slice(0, -2));
    } else {
      setChatData(
        chatData.map((eleChatData: iChatData) => ({
          ...eleChatData,
          isLoading: false,
        }))
      );
    }
    abortPlanTripDetails.abort();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setDisableInput(false);
    }
  }

  /**
   * Editing activity
   */
  function handleEditActivity(data: any) {
    // console.log(data);
  }

  if (JSON.stringify(selectedChatData) === "{}")
    return (
      <Stack mt={5}>
        <Loader />
      </Stack>
    );

  const handleNav = () => {
    isMobile ? navigate(ROUTES.AdjustTrip) : setScreen(ScreenTypes.Adjust);
  };

  // Save itinerary
  async function saveItineraryBeforeBooking() {
    if (!userInfo.loggedIn) {
      setShowLoader(false);
      setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
      return false;
    }
    // if (selectedChatData?.isSaved) return true;
    toast.dismiss();
    const metaData: any = {
      // chatData: chatData,
      selectedChatData: {
        ...selectedChatData,
        isSaved: true,
        // itineraryPromptContext:
        //   selectedChatData?.prompt[selectedChatData?.prompt?.length - 1] || [],
        prompt: [],
        postText: "TEST",
        preText: "TEST",
      },
    };
    const response = await saveItineraryDetailsAPI(
      selectedChatData?.tripName || "",
      selectedChatData.id,
      userDetails.id,
      metaData,
      "draft",
      Number(selectedChatData.noOfDays),
      "",
      "",
      userDetails?.role === 1,
      // "",
      // "",
      // "",
      // "",
      // "",
      // "",
      selectedChatData?.results || null
    );
    if (response?.message === "Itineraries has been saved") {
      const tempChatData = chatData.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === selectedChatData.id)
          return {
            ...chatDataEle,
            isSaved: true,
          };
        return chatDataEle;
      });
      setChatData(tempChatData);
      setSelectedChatData({
        ...selectedChatData,
        isSaved: true,
      });
      saveHistory(tempChatData);
      setIsItinerarySavedFlag(true);
      return true;
    } else {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      return false;
    }
  }
  const selectedClass = selectedChatData.flight_cabin_class || "Economy";

  const handleFlightClass = (selectedClass: string) => {
    switch (selectedClass) {
      case "Economy":
        return "E";
      case "economy":
        return "E";
      case "Premium":
        return "P";
      case "premium":
        return "P";
      case "Business":
        return "B";
      case "business":
        return "B";
      case "First Class":
        return "F";
      default:
        return "E";
    }
  };
  const hotelRatingParse = (input: string): number[] => {
    const regex = /^\d+(,\d+)*$/;

    if (regex.test(input)) {
      return input.split(",").map(Number);
    } else {
      return [3];
    }
  };

  const handleBook = async () => {
    localStorage.removeItem("_traveler_info_array");
    setShowLoader(true);
    if (isDesktopOrTablet) {
      setIsFetchingAirports(true);
    }
    const isValid = await saveItineraryBeforeBooking();
    if (!isValid) return;
    let airPortApiCheck = [];
    try {
      airPortApiCheck = await getAirportData();
    } catch (error) {
      console.warn("Airport API failed:", error);
    }
    setPrevRoute(ROUTES.ItineraryDetails);

    // if (airPortApiCheck.length === 0) return;
    if (!airPortApiCheck || airPortApiCheck.length === 0) {
      console.warn("No airport data found or invalid data returned.");
    }
    const extractCityNames =
      selectedChatData?.destinationList
        ?.map((item) => item.cityName || item.destination_city)
        .join(", ") || "";

    if (isMobile) {
      navigate(ROUTES.Booking);
    } else {
      setScreen(ScreenTypes.Booking);
    }
    setShowMore({ visibleList: { flight: 0, hotel: 4, package: 4 } });
    setHotelPagination([]);

    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      citiesInput: itineraryInfo?.citiesInput || extractCityNames,
      adultsCount:
        itineraryInfo?.adultsCount ||
        parseInt(selectedChatData?.number_of_adults as string) ||
        1,
      childrenCount:
        itineraryInfo?.childrenCount ||
        parseInt(selectedChatData?.number_of_children as string) ||
        0,
      seniorsCount:
        itineraryInfo?.seniorsCount ||
        parseInt(selectedChatData?.number_of_senior_citizens as string) ||
        0,
      airportFrom: "",
      airportTo: "",
      travelFromDate:
        validateDate(
          itineraryInfo?.travelFromDate || selectedChatData.start_date
        ) || "",
      travelToDate: "",
      routeType: "",
      flightClass:
        itineraryInfo?.flightClass || handleFlightClass(selectedClass),
      checkIn: "",
      checkOut: "",
      numberOfRoomCount:
        itineraryInfo?.numberOfRoomCount ||
        Number(selectedChatData?.number_of_rooms) ||
        1,
      tripType: selectedChatData?.destinationList.length > 1 ? 3 : 2,
      update: false,
      selectedFlightId: "",
      isFlightAvailable: false,
      airlines: selectedChatData.airlines || "",
      hotelChains: selectedChatData.hotels || "",
      flightDayPart: selectedChatData.flight_day_part || "",
      noMoreFlights: false,
      hotelUniqueId: "",
      airPort: [
        {
          arrival: "",
          departure: "",
          fromCity: "",
          destinationCity: "",
          departureDate: "",
          returnDate: "",
          departureAirportFullName: "",
          arrivalAirportFullName: "",
          arrivalCityLatitude: "",
          arrivalCityLongitude: "",
          departureCityLatitude: "",
          departureCityLongitude: "",
          noOfDays: 0
        },
      ],
      travelJourneyData: [
        {
          departureCityAddress: "",
          arrivalCityAddress: "",
          departureDate: "",
          returnDate: "",
          days: [],
          flightCheckIn: "",
          flightCheckOut: "",
          totalDays: 0,
        },
      ],
      visibleList: {
        flight: 0,
        hotel: 4,
        package: 2,
      },
      filters: {
        starRating: selectedChatData?.hotel_star_ratings
          ? hotelRatingParse(selectedChatData.hotel_star_ratings)
          : hotelRatingParse(""),
      },
    }));

    setSelectedFlightOptions({
      ...selectedFlightOptions,
      flights: true,
      hotels: true,
    });
    setFlightData(null);
    setHotelData(null);
  };

  if (showLoader)
    return (
      <div className={styles.newLoaderContainer}>
        <NewLoader
          text="Please wait"
          className={styles.loaderMain}
          cancelButtonClassName={styles.cancelBtn}
          isOpen={true}
          onClose={() => {
            if (fetchAirportController?.current)
              fetchAirportController?.current.abort();
            setShowLoader(false);
          }}
        />
      </div>
    );

  return (
    <div className={styles.desktopScrollContainer}>
      {/* {disableInput && (
        <div className={styles.overlay}>
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        </div>
      )} */}
      <MessagePopupDialog
        open={openModifyItineraryDialog?.isOpen}
        message={openModifyItineraryDialog?.message || ""}
        onClose={() => {
          // console.log("DFDF")
          setOpenModifyItineraryDialog({
            ...openModifyItineraryDialog,
            isOpen: false,
          });
        }}
      />
      <div className={styles.mainContainer}>
        <BackButtonHandler
          onBack={() => {
            handleBackButton();
            setAirPorts([]);
          }}
        />
        {/* Save and Share Button */}
        <div className={styles.shareSaveAdjustContainer}>
          {noOfCitySelected > 1 &&
            !selectedChatData.singleSelectedDestination && (
              <Button
                onClick={handleNav}
                className={`${styles.button} ${styles.adjustButton}`}>
                <img className={styles.iconButton} src={editIcon} alt="share" />
                ADJUST
              </Button>
            )}
        </div>

        {/* Itinerary Details */}
        <div className={styles.stickyHeading}>
          <div className={styles.closeBtnContainer}>
            <Button
              className={`${styles.columnAlignButton}`}
              onClick={handleBackButton}>
              <img src={backIcon} alt="Close" />
              <p>BACK</p>
            </Button>
          </div>
          <div className={styles.buttonContainer}>
            {/* <Button className={`${styles.rowAlignButton}`} onClick={handleEdit}>
            <img className={styles.iconButton} src={editIcon} alt="edit" />
            EDIT
          </Button> */}

            <Button
              onClick={() => {
                handleSaveItinerary();
                setShareClick(false);
              }}
              disabled={selectedChatData?.isSaved}
              className={`${styles.rowAlignButton} ${styles.saveBtn}`}>
              <img className={styles.iconButton} src={saveIcon} alt="save" />
              <span>{selectedChatData?.isSaved ? "SAVED" : "SAVE"}</span>
            </Button>

            {selectedChatData?.isSaved ? (
              <RWebShare
                data={{
                  url: `${APP_URL}shared/${selectedChatData?.id}`,
                  title: "Zenvoya",
                }}
                onClick={handleOnShare}>
                <Button
                  className={`${styles.rowAlignButton}`}
                  onClick={handleShare}>
                  <img
                    className={styles.iconButton}
                    src={shareIcon}
                    alt="share"
                  />
                  SHARE
                </Button>
              </RWebShare>
            ) : (
              <Button
                className={`${styles.rowAlignButton}`}
                onClick={handleShare}>
                <img
                  className={styles.iconButton}
                  src={shareIcon}
                  alt="share"
                />
                SHARE
              </Button>
            )}

            <Button className={`${styles.rowAlignButton}`} onClick={handleBook}>
              <img
                className={styles.iconButton}
                src={true ? cartIcon : buyIcon}
                alt="buy"
              />
              <p>
                {" "}
                {true ? (
                  "Book"
                ) : (
                  <span>
                    VIEW FLIGHTS <br /> & HOTELS
                  </span>
                )}
              </p>
            </Button>
          </div>
        </div>

        {/* <div className={styles.headingContainer}>
        {noOfCitySelected === 1 && tempSelectedChatData?.length > 0 && (
          <div className={styles.headingText}>
            <h1>{tempSelectedChatData[0]?.cityName?.split(",")[0]}</h1>
            <h2>
              {
                tempSelectedChatData[0]?.cityName?.split(",")[
                  tempSelectedChatData[0]?.cityName?.split(",").length - 1
                ]
              }
            </h2>
          </div>
        )}
      </div> */}

        {/* Tab container */}
        <CityTabs
          itineraryVersion={selectedChatData?.itinerary_version || 0}
          itineraryDescription={
            selectedChatData?.complete_itinerary_description
          }
          destinationList={tempSelectedChatData}
          handleEditActivity={handleEditActivity}
          disableInput={disableInput}
          showEditActivityFlag={showEditActivityFlag}
          setShowEditActivityFlag={setShowEditActivityFlag}
        />
        <div className={styles.inputContainer}>
          <InputWithMic
            placeholder="Type here to update the itinerary"
            disable={disableInput || showEditActivityFlag}
            onSend={() => handleSendMessage()}
            onStopRecording={() => {}}
            value={inputValue}
            setValue={setInputValue}
            handleCancel={handleCancel}
            disableSendBtn={inputValue === "" ? true : false}
          />
        </div>
      </div>
    </div>
  );
}
//  onSend={() => handleSendMessage(chatInputText)}
//               onStopRecording={() => handleSendMessage(chatInputText)}
//               value={chatInputText}
//               setValue={setChatInputText}
//               handleCancel={handleCancel}
//               disableSendBtn={chatInputText === "" ? true : false}
