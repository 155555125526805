import { Button } from "@mui/material";
import {
  modifyEditIcon,
  FlightCancelIcon,
  planeDepartureRegular,
  personWalkingLuggage,
} from "../../../constant";
import styles from "./flightsTabs.module.scss";
import Stepper from "../../booking/stepper/stepper";
import CustomAccordion from "../../reusableComponent/customAccordion/CustomAccordion";
import { BookedFlightsInfo } from "../../../constant/dummyData";
import React from "react";
import TravelerDetails from "../travelerDetails/travelerDetails";
import FlightDetails from "../../booking/flightBooking/flightDetails/flightDetails";
import { getPassengerCount } from "../../../utils";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

function FlightsTabs({
  flightDetails,
  travellersInfo,
}: {
  flightDetails: any;
  travellersInfo: any;
}): JSX.Element {
  console.log({ flightDetails });
  const inboundFlight =
    flightDetails &&
    flightDetails?.sectors?.length > 0 &&
    flightDetails?.sectors[0]?.inbound?.length > 0 &&
    flightDetails?.sectors[0]?.inbound;
  const outboundFlight =
    flightDetails &&
    flightDetails?.sectors?.length > 0 &&
    flightDetails?.sectors[0]?.outbound?.length > 0 &&
    flightDetails?.sectors[0]?.outbound;

  const travelerInfoData =
    travellersInfo?.travelersDetails || travellersInfo?.travelersDetailsList;

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <header className={styles.header}>
          <Button
            className={styles.headerBtn}
            endIcon={
              <img
                src={FlightCancelIcon}
                alt="img"
                className={styles.flightIcon}
              />
            }
            onClick={() =>
              triggerGAEvent(
                GA_LABEL_ENUMS.trip_details_flights_cancel_option_click
              )
            }
          >
            Cancel Options
          </Button>
          <Button
            className={styles.headerBtn}
            endIcon={
              <img src={modifyEditIcon} alt="img" className={styles.editIcon} />
            }
            onClick={() =>
              triggerGAEvent(
                GA_LABEL_ENUMS.trip_details_flights_change_flight_click
              )
            }
          >
            Change Flight
          </Button>
        </header>
        <div className={styles.flightAccord}>
          {flightDetails ? (
            <>
              {outboundFlight &&
                outboundFlight?.map((ele: any, index: number) => (
                  <div key={index}>
                    <FlightDetails
                      hideExpandIcon={false}
                      flightData={ele}
                      flightImage={""}
                      handleExpandGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_details_accordion_click
                        )
                      }
                      handleCloseGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_collapse_accordion_click
                        )
                      }
                    />
                  </div>
                ))}
              {inboundFlight &&
                inboundFlight?.map((ele: any, index: number) => (
                  <div key={index}>
                    <FlightDetails
                      hideExpandIcon={false}
                      flightData={ele}
                      flightImage={""}
                      handleExpandGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_details_accordion_click
                        )
                      }
                      handleCloseGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_collapse_accordion_click
                        )
                      }
                    />
                  </div>
                ))}
            </>
          ) : (
            <p className={styles.noFlight}>No flight booked</p>
          )}
          {BookedFlightsInfo.map((i, index) => (
            <CustomAccordion
              className={styles.customFlightStyles}
              summaryClass={styles.summaryFlightClass}
              accordianDetailsClass={styles.accordFlightDetailsClass}
              defaultExpanded={false}
              headingComponent={
                <div
                  className={styles.headingFlightComp}
                  key={index}
                  onClick={() =>
                    triggerGAEvent(
                      GA_LABEL_ENUMS.trip_details_flight_traveler_accordion_click
                    )
                  }
                >
                  <div className={styles.accordTitleCard}>
                    {i.title && <p className={styles.accordDesc}>{i.title}</p>}
                    {i.passengers && (
                      <p className={styles.traveler}>
                        {getPassengerCount(
                          travellersInfo?.adultsCount
                            ? Number(travellersInfo?.adultsCount)
                            : 0,
                          travellersInfo?.childrenCount
                            ? Number(travellersInfo?.childrenCount)
                            : 0,
                          travellersInfo?.seniorsCount
                            ? Number(travellersInfo?.seniorsCount)
                            : 0
                        )}
                      </p>
                    )}
                  </div>
                  {i.accordType && (
                    <img
                      src={
                        i.accordType === "flight"
                          ? planeDepartureRegular
                          : personWalkingLuggage
                      }
                      alt="plane"
                      className={styles.planeDepartureIcon}
                    />
                  )}
                </div>
              }
              bodyComponent={
                <React.Fragment>
                  {travelerInfoData &&
                    travelerInfoData?.map((ele: any, index: number) => (
                      <TravelerDetails
                        count={index}
                        name={
                          ele?.firstName +
                          " " +
                          ele?.middleName +
                          " " +
                          ele?.lastName
                        }
                        phone={ele?.countryCode + " " + ele.phoneNumber}
                        gender={ele.gender}
                        meal={ele?.mealPref}
                        email={ele.email}
                        skyMiles={ele.FF}
                        specialNeeds={ele.specialNeeds}
                      />
                    ))}
                </React.Fragment>
              }
              zeroMarginOnExpand
              borderBottom
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FlightsTabs;
