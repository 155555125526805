import { useCallback, useContext, useEffect, useState } from "react";
import {
  AirPort,
  BookingDetails,
  City,
  HotelInputTypes,
} from "../../../../types";
import DateRangePicker from "../../inputFields/dateRangePicker/dateRangePicker";
import Duration from "../../inputFields/duration/duration";
import SearchButton from "../../inputFields/searchButton/searchButton";
import Travelers from "../../inputFields/travelers/travelers";
import styles from "./hotel.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import PropertyType from "../../inputFields/propertyType/propertyType";
import CityLocation from "../../inputFields/location/location";
import Stepper from "./stepper/stepper";
import { toastMessage } from "../../../../helpers/toast/toastMessage";

type CityData = {
  checkIn: string;
  checkOut: string;
  country: string;
  city: string;
  countryFullName?: string;
};

type ModifiedData = {
  departure: string;
  arrival: string;
};

interface iProps {
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  searchHotels: () => void;
  inputFieldData: HotelInputTypes[];
}

function HotelInputFields({
  bookingDetails,
  setBookingDetails,
  searchHotels,
  inputFieldData,
}: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const modifyCityData = (
    data: CityData[],
    fromCity: string
  ): ModifiedData[] => {
    return data.map((item, index) => {
      const departureCity =
        index === 0
          ? fromCity
          : `${data[index - 1].city}, ${data[index - 1].countryFullName}`;
      const arrivalCity = `${item.city}, ${item.countryFullName}`;

      return {
        departure: departureCity,
        arrival: arrivalCity,
      };
    });
  };

  const modifiedData = modifyCityData(
    inputFieldData,
    selectedChatData?.fromCity ?? ""
  );

  const validateField = (
    field: string | null | undefined,
    fallback: string
  ): string => {
    return field && field.trim() !== "" ? field : fallback;
  };

  const updateBookingDetails = useCallback(
    (
      citiesArray: City[],
      bookingDetails: BookingDetails,
      setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>,
      inputFieldData: HotelInputTypes[]
    ) => {
      if (!bookingDetails.airPort || bookingDetails.airPort.length === 0) {
        console.error("Error: Missing airPort data.");
        return;
      }

      const updatedAirPort: AirPort[] = citiesArray?.map((city, index) => ({
        arrival: bookingDetails.airPort[index]?.arrival || "",
        departure: bookingDetails.airPort[index]?.departure || "",
        fromCity: validateField(city.departure, "City"),
        destinationCity: validateField(city.arrival, "City"),
        departureDate: inputFieldData[index].checkIn,
        returnDate: inputFieldData[index].checkOut,
        departureAirportFullName:
          bookingDetails.airPort[index]?.departureAirportFullName || "",
        arrivalAirportFullName:
          bookingDetails.airPort[index]?.arrivalAirportFullName || "",
        arrivalCityLatitude: inputFieldData[index].latitude || "",
        arrivalCityLongitude: inputFieldData[index].longitude || "",
        departureCityLatitude: inputFieldData[index - 1]?.latitude || "",
        departureCityLongitude: inputFieldData[index - 1]?.longitude || "",
        noOfDays: bookingDetails.travelJourneyData[index]?.totalDays
      }));

      setBookingDetails((prevBookingDetails) => ({
        ...prevBookingDetails,
        airPort: updatedAirPort,
      }));
    },
    []
  );

  const handleUpdateData = useCallback(() => {
    if (inputFieldData && inputFieldData?.length > 0) {
      updateBookingDetails(
        modifiedData,
        bookingDetails,
        setBookingDetails,
        inputFieldData
      );
    }
  }, [
    bookingDetails,
    inputFieldData,
    modifiedData,
    setBookingDetails,
    updateBookingDetails,
  ]);

  const [enableBtn, setEnableBtn] = useState<boolean>(true);

  const handleHotelInputValidation = () => {
    const { adultsCount, childrenCount, seniorsCount, travelFromDate } =
      bookingDetails || {};

    // const checkDepartureDate = airPort?.some((flight) => flight?.departureDate);

    if (travelFromDate === "") {
      toastMessage.error("", "Please select a travel date.");
      setEnableBtn(true);
      return;
    }

    const totalAdults = adultsCount + seniorsCount;

    if (totalAdults === 0) {
      toastMessage.error("", "At least 1 adult or senior is required.");
      setEnableBtn(true);
      return;
    }

    if (childrenCount > 0 && totalAdults === 0) {
      toastMessage.error(
        "",
        "A child must be accompanied by at least 1 adult or senior."
      );
      setEnableBtn(true);
      return;
    }

    searchHotels();
  };

  useEffect(() => {
    const {
      adultsCount,
      childrenCount,
      seniorsCount,
      numberOfRoomCount,
      travelFromDate,
    } = bookingDetails || {};

    const shouldEnableButton = !(
      travelFromDate &&
      (adultsCount || childrenCount || seniorsCount) &&
      numberOfRoomCount > 0
    );

    setEnableBtn(shouldEnableButton);
  }, [bookingDetails]);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.newInputCard1}>
        {bookingDetails && (
          <DateRangePicker
            startDate={bookingDetails.travelFromDate}
            endDate={bookingDetails.airportTo}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            // handleUpdateData={handleUpdateData}
            handleUpdateData={() => {}}
          />
        )}
        <div className={styles.card3}>
          {bookingDetails && (
            <Duration
              bookingDetails={bookingDetails}
              flight={false}
              hotel={true}
            />
          )}
        </div>
        {bookingDetails && (
          <div className={styles.card1}>
            <Travelers
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              showRoom={true}
            />
          </div>
        )}
      </div>
      <div className={styles.newInputCard2}>
        {bookingDetails && (
          <div className={styles.card4}>
            <PropertyType
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
            />
          </div>
        )}
        {bookingDetails &&
          bookingDetails?.airPort?.map((i, index) => (
            <div className={styles.card2} key={index}>
              {selectedChatData.destinationList?.length < 2 && (
                <CityLocation city={bookingDetails?.citiesInput || ""} />
              )}
            </div>
          ))}
      </div>
      {inputFieldData && inputFieldData?.length > 1 && (
        <div className={styles.stepper}>
          <section className={styles.stepperDetails}>
            <Stepper
              steps={inputFieldData}
              initialStep={0}
              finalStep={inputFieldData.length - 1}
            />
          </section>
        </div>
      )}
      <div
        className={`${selectedChatData?.destinationList?.length > 1 ? styles.searchBox : styles.singleCitySearch}`}>
        <SearchButton
          onClick={handleHotelInputValidation}
          disableButton={enableBtn}
        />
      </div>
    </div>
  );
}

export default HotelInputFields;
