import React, { useCallback, useContext, useEffect, useState } from "react";
import { MY_TRIP_TABS, savedDummyTripDataArr, TRIP_DETAILS_TABS } from "../../myTripsConstants";
import styles from "./pastTrips.module.scss";
import MyTripCard from "../myTripsCard/myTripsCard";
import { ROUTES, whiteArrowIcon, whiteEditIcon } from "../../../../constant";
import toast from "react-hot-toast";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { getItinerariesListAPI } from "../../../../api/getSaveItinerariesList";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import {
  sortDatesInAscendingOrder,
  sortDatesOldtoNew,
} from "../../../../utils/dateUtils";
import { Itineraries } from "../../../../types";
import Loader from "../../../reusableComponent/loader/Loader";
import { useNavigate } from "react-router-dom";
import { ScreenTypes } from "../../../../enums";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import { getItineraryDetailsAPI } from "../../../../api/getItineraryDetails";
import { v4 } from "uuid";
import { useInView } from "react-intersection-observer";

interface iProps {
  className?: string;
  handleEditTripName: any;
  pastOrCancelled?: "past" | "cancelled";
  isTripNameEdited?: boolean;
  editTripNameData?: any;
  tripName?: any;
}

function PastTrips(props: iProps): JSX.Element {
  const {
    className,
    handleEditTripName,
    pastOrCancelled = "past",
    isTripNameEdited = false,
    editTripNameData,
    tripName,
  } = props;
  const globalContext = useContext(StateContext);
  const {
    userDetails,
    setTripOrItinearyId,
    setPrevRoute,
    setScreen,
    isMobile,
    setSelectedChatData,
    setActiveTripDetailsTab
  }: iGlobalContext = globalContext;
  const [isLoadingItineraryList, setIsLoadingItineraryList] = useState(false);
  const [listItinery, setListItinery] = useState<Itineraries>([]);

  const [hasMore, setHasMore] = useState(true);
  const [skipLimit, setSkipLimit] = useState({
    skip: 0,
    limit: 10,
  });
  const { ref, inView } = useInView({});
  useEffect(() => {
    setSkipLimit({
      skip: 0,
      limit: 10,
    });
    console.log(skipLimit);
  }, [pastOrCancelled]);

  const getListItineries = useCallback(
    async function () {
      try {
        if (!hasMore || (!inView && skipLimit.skip > 0)) return;
        setIsLoadingItineraryList(true);
        // setTripCount(0)
        const response = await getItinerariesListAPI(
          userDetails.id,
          pastOrCancelled,
          skipLimit.skip,
          skipLimit.limit
        );
        setIsLoadingItineraryList(false);
        if (response && response?.data) {
          setSkipLimit({
            skip: skipLimit.skip + skipLimit.limit,
            limit: 10,
          });
          setListItinery([...listItinery, ...response?.data]);
          if (response?.data?.length === 0) setHasMore(false);
          // setListItinery(sortDatesOldtoNew(response?.data, "startDate"));
          // setTripCount(response?.data.length);

          // console.log(
          //   sortDatesInAscendingOrder(response?.data, "startDate"),
          //   "response"
          // );
        } else {
          throw new Error("No response data");
        }
      } catch (error) {
        toastMessage.error("Something went wrong!");
      }
    },
    [userDetails.id, inView, pastOrCancelled]
  );
  useEffect(() => {
    getListItineries();
  }, [inView, getListItineries]);
  
  useEffect(() => {
    setListItinery(
      listItinery.map((ele: any) =>
        ele?.id === editTripNameData?.id ? { ...ele, tripName: tripName } : ele
      )
    );
  }, [isTripNameEdited]);

  const navigate = useNavigate();
  const handleViewTrip = useCallback((trip: any) => {
    setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
    toast.dismiss();
    setPrevRoute(ROUTES.MyTrips);
    // console.log(trip);
    setTripOrItinearyId(trip?.id || "");
    if (pastOrCancelled === "past")
      triggerGAEvent(GA_LABEL_ENUMS.my_trips_past_trips_card_click);
    if (pastOrCancelled === "cancelled")
      triggerGAEvent(GA_LABEL_ENUMS.my_trips_cancelled_trips_card_click);
    if (!isMobile) setScreen(ScreenTypes.TripDetails);
    else navigate(ROUTES.TripDetails);
  }, []);

  // function handleBookTrip(trip: any) {
  //   toast.dismiss();
  //   setTripOrItinearyId(trip?.id || "");
  //   setPrevRoute(ROUTES.MyTrips);
  //   if (!isMobile) setScreen(ScreenTypes.Booking);
  //   else navigate(ROUTES.Booking);
  // }
  async function handleBookTrip(trip: any) {
    toast.dismiss();
    try {
      setTripOrItinearyId(trip?.id || "");
      setPrevRoute(ROUTES.MyTrips);

      if (pastOrCancelled === "past")
        triggerGAEvent(GA_LABEL_ENUMS.my_trips_past_trips_book_again_click);
      if (pastOrCancelled === "cancelled")
        triggerGAEvent(
          GA_LABEL_ENUMS.my_trips_cancelled_trips_book_again_click
        );
      const response = await getItineraryDetailsAPI(trip?.id || "");
      if (response?.statusCode !== 200) {
        return;
      }

      const metaData = JSON.parse(response?.data?.metaData || "[]");
      const selectedChatData = metaData[0]?.selectedChatData;
      setSelectedChatData({
        ...selectedChatData,
        id: v4(),
      });

      if (!isMobile) {
        setScreen(ScreenTypes.ItineraryInfo);
      } else {
        navigate(ROUTES.ItineraryDetails);
      }
    } catch (error) {
      console.error("Error booking trip:", error);
    }
  }

  if (isLoadingItineraryList && listItinery.length === 0)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );

  return (
    <div>
      {listItinery?.length > 0 ? (
        <div>
          {listItinery.map((trip: any, index: any) => {
            return (
              <div className={styles.upcomingTripSubContainer} key={index}>
                <MyTripCard
                  trip={trip}
                  showNextIcon={true}
                  handleEditTripName={() =>
                    handleEditTripName(
                      trip,
                      pastOrCancelled === "past"
                        ? MY_TRIP_TABS.PAST
                        : MY_TRIP_TABS.CANCELLED
                    )
                  }
                  onCardClick={() => {
                    handleViewTrip(trip);
                  }}
                />
                <div
                  className={styles.iconWithBookAgainText}
                  onClick={() => handleBookTrip(trip)}
                >
                  <img
                    className={styles.nextIcon}
                    // src={selectedIcon}
                    src={whiteEditIcon}
                    alt="selected"
                  />
                  <p>BOOK AGAIN</p>
                </div>
                <h1 className={styles.bookedDate}>{trip?.bookedOnDate}</h1>
              </div>
            );
          })}
          {hasMore && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <p className={styles.noData}>No trips</p>
      )}
    </div>
  );
}

PastTrips.defaultProps = {
  className: "",
};

export default PastTrips;
