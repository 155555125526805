import axios from "axios";
import { ENDPOINT } from "../../constant/environment";

export async function saveBookedTransactionDetailsAPI(
  flight: boolean,
  id: string,
  userId: string,
  metaData: any,
  status: string,
  bookedTransactionId: string,
  bookedItinerayId: string,
  startDate: string,
  endDate: string,
  travellersInfo: any,
  data: any,
) {
  const URL = `${ENDPOINT}/app/v1/users/itineraries`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {};
  if (flight)
    body = {
      id,
      userId,
      metaData,
      status,
      flightTransactionId: bookedTransactionId,
      flightItinerayId: bookedItinerayId,
      startDate,
      endDate,
      travellersInfo,
      data
    };
  else
    body = {
      id,
      userId,
      metaData,
      status,
      hotelTransactionId: bookedTransactionId,
      hotelItinerayId: bookedItinerayId,
      startDate,
      endDate,
      travellersInfo,
      data
    };

  try {
    if (!userId)
      return;
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function saveBookInputFields(
  id: string,
  userId: string,
  metaData: any,
  bookFilter: any,
  status: string
) {
  const URL = `${ENDPOINT}/app/v1/users/itineraries`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = { userId, metaData, bookFilter, status, id };

  try {
    if (!userId)
      return;
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
