import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function loginAPI(
  email: string,
  loginType: string,
  name: string,
  password: string
) {
  const URL = `${ENDPOINT}/app/v1/auth/login`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    email,
    loginType,
    name,
    password,
  };
  try {
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
