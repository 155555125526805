import axios from "axios";
import { ENDPOINT, environment } from "../constant/environment";
import Cookies from "js-cookie";

export async function logoutAPI(id: string) {
  const URL = `${ENDPOINT}/app/v1/auth/logout`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    userId: id,
  };
  try {
    // // console.log(body);
    const response = await axios.post(URL, body, config);
    // console.log(response.data);
    Cookies.remove(`${environment}userData`, {
      path: "/",
      domain: ".zenvoya.ai",
    });
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
