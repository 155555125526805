import React from "react";
import styles from "./bookedFlightAccordion.module.scss";
import CustomAccordion from "../customAccordion/CustomAccordion";
import { getDayOfWeek, getUTCMonthDate } from "../../../utils/dateUtils";
import { planeDepartureRegular } from "../../../constant";
import Stepper from "../../booking/flightBooking/flightDetails/stepper/stepper";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

interface iProps {
  flightData?: any;
  flightImage?: any;
  hideExpandIcon?: boolean;
}

interface CardInfo {
  flightDetails?: {
    flightName: string;
    flightNumber: string;
    bookingRef: string;
    flightImage: string;
  };
  departure?: {
    departure: string;
    departureTime: string;
    flightTime: string;
  };
  arrival?: {
    arrival: string;
    arrivalTime: string;
    destinationImg: string;
  };
  seats?: {
    seats: string;
    seatNumb: string;
    boardingPasses: boolean;
  };
  layover?: {
    layover: string;
    arrivalTime: string;
  };
}

interface StepData {
  title?: string;
  description?: string;
  departure?: string;
  departureDate?: string;
  ArrivalOnDiffDate?: boolean;
  layOver?: boolean;
  airPortCode?: string;
  airportName?: string;
  cardInfo: CardInfo[];
}

function BookedFlightAccordion({
  flightData,
  flightImage,
  hideExpandIcon = true,
}: iProps): JSX.Element {
  const departureAirportCode = flightData?.segments[0]?.depAirportCode;
  const departureAirportName = flightData?.segments[0]?.depAirportName;
  const arrivalAirportCode =
    flightData?.segments[flightData?.segments?.length - 1]?.arrAirportCode;
  const arrivalAirportName =
    flightData?.segments[flightData?.segments?.length - 1]?.arrAirportName;

  return (
    <div className={styles.main}>
      <div className={styles.flightAccord}>
        <CustomAccordion
          className={styles.customFlightStyles}
          summaryClass={styles.summaryFlightClass}
          accordianDetailsClass={styles.accordFlightDetailsClass}
          defaultExpanded={true}
          hideExpandIcon={hideExpandIcon}
          headingComponent={
            <div
              className={styles.headingFlightComp}
              onClick={() => {
                triggerGAEvent(
                  GA_LABEL_ENUMS.trip_details_daily_plans_flight_accordion_click
                );
              }}
            >
              <div className={styles.accordTitleCard}>
                <p className={styles.dayAndCity}>
                  {getDayOfWeek(flightData?.segments[0]?.departureDate)}{" "}
                  {getUTCMonthDate(flightData?.segments[0]?.departureDate)}
                </p>

                <p className={styles.accordDesc}>
                  {`${departureAirportName} (${departureAirportCode})`} -{" "}
                  {`${arrivalAirportName} (${arrivalAirportCode})`}
                </p>
              </div>
              <img
                src={
                  // i.accordType === "flight"
                  //   ?
                  planeDepartureRegular
                  // : personWalkingLuggage
                }
                alt="plane"
                className={styles.planeDepartureIcon}
              />
            </div>
          }
          bodyComponent={
            <div className={styles.stepper}>
              {/* {flightModifiedData.map((i: any, index: any) => ( */}
              <section
                className={styles.stepperDetails}
                // key={index}
              >
                {/* {i.stepsData && ( */}
                <Stepper
                  // steps={i.stepsData}
                  // initialStep={0}
                  // finalStep={i.stepsData.length - 1}
                  steps={flightData?.segments}
                  stops={flightData?.stops}
                  initialStep={0}
                  finalStep={flightData?.length - 1}
                  flightImage={flightImage}
                  onOpenStepperFunc={() =>
                    triggerGAEvent(
                      GA_LABEL_ENUMS.trip_details_daily_plans_flight_deatils_accordion_click
                    )
                  }
                  onCloseStepperFunc={() =>
                    triggerGAEvent(
                      GA_LABEL_ENUMS.trip_details_daily_plans_flight_collapse_accordion_click
                    )
                  }
                />
                {/* )} */}
              </section>
              {/* ))} */}

              <div className={styles.divider} />
            </div>
          }
          zeroMarginOnExpand
          borderBottom
        />
      </div>
    </div>
  );
}

export default BookedFlightAccordion;
