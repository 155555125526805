import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./airportSelectionPopover.module.scss";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Airport, BookingDetails, iCityDetailsResult } from "../../../../types";
import { getAirports } from "../../../../api/booking";
import Loader from "../../../reusableComponent/loader/Loader";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { StateContext } from "../../../../context/globalContext/context";
import { toastMessage } from "../../../../helpers/toast/toastMessage";

type CityAirports = {
  city: string;
  airports: Airport[];
};

interface iProps {
  popoverTrigger: ReactNode;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyButton: () => void;
  departureCity?: string;
  arrivalCity?: string;
}

export default function AirportSelectionPopover({
  popoverTrigger,
  bookingDetails,
  setBookingDetails,
  isPopoverOpen,
  setIsPopoverOpen,
  handleApplyButton,
  departureCity,
  arrivalCity,
}: iProps) {
  const state = useContext(StateContext);
  const { selectedChatData, airPorts, setAirPorts }: iGlobalContext = state;
  // const [airPorts, setAirPorts] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  // function combineCityNames(selectedChatData: any): string[] {
  //   if (!selectedChatData || !selectedChatData.fromCity) {
  //     return [];
  //   }

  //   const fromCity = selectedChatData.fromCity;
  //   const extractCityNames =
  //     selectedChatData?.destinationList?.map(
  //       (i: any) => i?.cityName?.split(",")[0] || i?.destination_city
  //     ) || [];

  //   return [fromCity, ...extractCityNames];
  // }

  function combineCityNames(
    selectedChatData: any
  ): { name: string; latitude: string; longitude: string }[] {
    if (!selectedChatData || !selectedChatData.fromCity) {
      return [];
    }
    const fromCity = {
      name: selectedChatData.fromCity,
      latitude: "",
      longitude: "",
    };

    const extractCityNames =
      selectedChatData?.destinationList?.map((i: iCityDetailsResult) => ({
        name: i?.cityName?.split(",")[0] || i?.destination_city,
        latitude: i?.city_latitude || "",
        longitude: i?.city_longitude || "",
      })) || [];

    return [fromCity, ...extractCityNames];
  }
  let fetchAirportController: any = useRef(null);
  const handleFlightSearch = async () => {
    const cities = combineCityNames(selectedChatData);

    if (cities.length === 0) {
      toastMessage.error("No valid cities selected");
      return;
    }

    setLoading(true);

    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };
    fetchAirportController.current = new AbortController();
    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current
      );
      if (fetchAirportController.current.signal.aborted) {
        return null;
      }
      setAirPorts(data);
    } catch (err) {
      setAirPorts([]);
      toastMessage.error("Failed to fetch flight data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (airPorts?.length === 0) {
      handleFlightSearch();
    }
  }, [airPorts]);

  const handleArrival = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = e.target.value;
    const selectedAirportDetails = airPorts
      .flatMap((city: { airports: any }) => city.airports)
      .find((airport: Airport) => airport.code === selectedAirport);
    const selectedCity = airPorts.find((city: { airports: any[] }) =>
      city.airports.some((airport) => airport.code === selectedAirport)
    )?.city;
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      airportTo: selectedAirport,
      airPort: prevDetails.airPort.map((flight, index) =>
        index === 0
          ? {
              ...flight,
              arrival: selectedAirport,
              destinationCity: selectedCity || "",
              arrivalAirportFullName: selectedAirportDetails?.fullName || "",
            }
          : flight
      ),
      update: true,
    }));
  };

  const handleDeparture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = e.target.value;

    // Find the selected airport and get its details
    const selectedAirportDetails = airPorts
      .flatMap((city: { airports: any }) => city.airports)
      .find((airport: Airport) => airport.code === selectedAirport);

    const selectedCity = airPorts.find((city: { airports: Airport[] }) =>
      city.airports.some((airport: Airport) => airport.code === selectedAirport)
    )?.city;

    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      airportFrom: selectedAirport,
      airPort: prevDetails.airPort.map((flight, index) =>
        index === 0
          ? {
              ...flight,
              departure: selectedAirport,
              fromCity: selectedCity || "",
              departureAirportFullName: selectedAirportDetails?.fullName || "",
            }
          : flight
      ),
      update: true,
    }));
  };

  // useEffect(() => {
  //   if (airPorts && selectedChatData) {
  //     // Filter airports for departure based on the selected city
  //     const filteredDepartureAirports = airPorts
  //       .filter(
  //         (city: CityAirports) => city.city === selectedChatData?.fromCity
  //       )
  //       .flatMap((city: CityAirports) => city.airports.slice(0, 5));

  //     // Filter airports for arrival based on cities not equal to fromCity
  //     const filteredArrivalAirports = airPorts
  //       .filter(
  //         (city: CityAirports) => city.city !== selectedChatData?.fromCity
  //       )
  //       .flatMap((city: CityAirports) => city.airports.slice(0, 5));

  //     // Set default departure airport if not already set
  //     if (filteredDepartureAirports.length > 0 && !bookingDetails.airportFrom) {
  //       const defaultDepartureAirport = filteredDepartureAirports[0];
  //       const departureCity = airPorts.find((city: CityAirports) =>
  //         city.airports.some(
  //           (airport) => airport.code === defaultDepartureAirport.code
  //         )
  //       )?.city;

  //       setBookingDetails((prevDetails) => ({
  //         ...prevDetails,
  //         airportFrom: defaultDepartureAirport.code,
  //         airPort: prevDetails.airPort.map((flight, index) =>
  //           index === 0
  //             ? {
  //                 ...flight,
  //                 departure: defaultDepartureAirport.code,
  //                 fromCity: departureCity || "",
  //                 departureAirportFullName:
  //                   defaultDepartureAirport.fullName || "",
  //               }
  //             : flight
  //         ),
  //         update: false,
  //       }));
  //     }

  //     // Set default arrival airport if not already set
  //     if (filteredArrivalAirports.length > 0 && !bookingDetails.airportTo) {
  //       const defaultArrivalAirport = filteredArrivalAirports[0];
  //       const arrivalCity = airPorts.find((city: CityAirports) =>
  //         city.airports.some(
  //           (airport) => airport.code === defaultArrivalAirport.code
  //         )
  //       )?.city;

  //       setBookingDetails((prevDetails) => ({
  //         ...prevDetails,
  //         airportTo: defaultArrivalAirport.code,
  //         airPort: prevDetails.airPort.map((flight, index) =>
  //           index === 0
  //             ? {
  //                 ...flight,
  //                 arrival: defaultArrivalAirport.code,
  //                 destinationCity: arrivalCity || "",
  //                 arrivalAirportFullName: defaultArrivalAirport.fullName || "",
  //               }
  //             : flight
  //         ),
  //         update: false,
  //       }));
  //     }
  //   }
  // }, [
  //   airPorts,
  //   selectedChatData,
  //   bookingDetails.airportFrom,
  //   bookingDetails.airportTo,
  //   setBookingDetails,
  // ]);
  useEffect(() => {
    if (airPorts && selectedChatData) {
      const departureAirportName = selectedChatData.departure_airport;
      const arrivalAirportName = selectedChatData.arrival_airport;
      // Filter airports for departure based on the selected city
      const filteredDepartureAirports = airPorts
        .filter((city: CityAirports) => {
          const fromCity = selectedChatData?.fromCity ?? selectedChatData?.from;
          return city.city === fromCity;
        })
        .flatMap((city: CityAirports) => city.airports.slice(0, 5));

      // Filter airports for arrival based on cities not equal to fromCity
      const filteredArrivalAirports = airPorts
        .filter((city: CityAirports) => {
          const fromCity = selectedChatData?.fromCity ?? selectedChatData?.from;
          return city.city !== fromCity;
        })
        .flatMap((city: CityAirports) => city.airports.slice(0, 5));

      // Find matching departure airport by name or default to the first option
      const matchingDepartureAirport =
        filteredDepartureAirports.find(
          (airport) => airport.fullName === departureAirportName
        ) || filteredDepartureAirports[0];

      // Set airportFrom to matching or default departure airport's code if found
      if (matchingDepartureAirport && !bookingDetails.airportFrom) {
        const departureCity = airPorts.find((city: CityAirports) =>
          city.airports.some(
            (airport) => airport.code === matchingDepartureAirport.code
          )
        )?.city;

        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airportFrom: matchingDepartureAirport.code,
          airPort: prevDetails.airPort.map((flight, index) =>
            index === 0
              ? {
                  ...flight,
                  departure: matchingDepartureAirport.code,
                  fromCity: departureCity || "",
                  departureAirportFullName:
                    matchingDepartureAirport.fullName || "",
                }
              : flight
          ),
          update: false,
        }));
      }

      // Find matching arrival airport by name or default to the first option
      const matchingArrivalAirport =
        filteredArrivalAirports.find(
          (airport) => airport.fullName === arrivalAirportName
        ) || filteredArrivalAirports[0];

      // Set airportTo to matching or default arrival airport's code if found
      if (matchingArrivalAirport && !bookingDetails.airportTo) {
        const arrivalCity = airPorts.find((city: CityAirports) =>
          city.airports.some(
            (airport) => airport.code === matchingArrivalAirport.code
          )
        )?.city;

        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airportTo: matchingArrivalAirport.code,
          airPort: prevDetails.airPort.map((flight, index) =>
            index === 0
              ? {
                  ...flight,
                  arrival: matchingArrivalAirport.code,
                  destinationCity: arrivalCity || "",
                  arrivalAirportFullName: matchingArrivalAirport.fullName || "",
                }
              : flight
          ),
          update: false,
        }));
      }
    }
  }, [
    airPorts,
    selectedChatData,
    bookingDetails.airportFrom,
    bookingDetails.airportTo,
    setBookingDetails,
  ]);
  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          {loading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            <>
              <h1 className={styles.heading}>Departure Airport</h1>
              {airPorts &&
              airPorts.filter((city: CityAirports) => {
                const fromCity =
                  selectedChatData?.fromCity ?? selectedChatData?.from;
                return city.city === fromCity;
              }).length === 0 ? (
                <p className={styles.errorMessage}>
                  There are no nearby airports
                </p>
              ) : (
                <FormControl className={styles.formControlContainer}>
                  <RadioGroup
                    name="radio"
                    onChange={handleDeparture}
                    value={bookingDetails.airportFrom}>
                    {airPorts &&
                      airPorts
                        .filter((city: CityAirports) => {
                          const fromCity =
                            selectedChatData?.fromCity ??
                            selectedChatData?.from;
                          return city.city === fromCity;
                        })

                        .map((city: CityAirports) => (
                          <div key={city.city}>
                            {city?.airports
                              ?.slice(0, 5)
                              .map((airport: Airport) => (
                                <FormControlLabel
                                  key={airport.code}
                                  value={airport.code}
                                  control={<CustomRadio />}
                                  label={
                                    <p
                                      className={styles.airportLabel}
                                      style={{
                                        color:
                                          airport.code ===
                                          bookingDetails.airportFrom
                                            ? "#222222"
                                            : "#696969",
                                      }}>
                                      {airport?.code} - {airport?.fullName}
                                    </p>
                                  }
                                />
                              ))}
                          </div>
                        ))}
                  </RadioGroup>
                </FormControl>
              )}
              <h1 className={styles.heading}>Arrival Airport</h1>
              {airPorts &&
              airPorts.filter((city: CityAirports) => {
                const fromCity =
                  selectedChatData?.fromCity ?? selectedChatData?.from;
                return city.city !== fromCity;
              }).length === 0 ? (
                <p className={styles.errorMessage}>
                  There are no nearby airports
                </p>
              ) : (
                <FormControl className={styles.formControlContainer}>
                  <RadioGroup
                    name="radio"
                    onChange={handleArrival}
                    value={bookingDetails.airportTo}>
                    {airPorts &&
                      airPorts
                        .filter((city: CityAirports) => {
                          const fromCity =
                            selectedChatData?.fromCity ??
                            selectedChatData?.from;
                          return city.city !== fromCity;
                        })
                        .map((city: CityAirports) => (
                          <div key={city.city}>
                            {city?.airports
                              ?.slice(0, 5)
                              .map((airport: Airport) => (
                                <FormControlLabel
                                  key={airport.code}
                                  value={airport.code}
                                  control={<CustomRadio />}
                                  label={
                                    <p
                                      className={styles.airportLabel}
                                      style={{
                                        color:
                                          airport.code ===
                                          bookingDetails.airportFrom
                                            ? "#222222"
                                            : "#696969",
                                      }}>
                                      {airport.code} - {airport.fullName}
                                      <br />
                                      <p className={styles.distance}>
                                        {" "}
                                        {airport.distance} miles from{" "}
                                        {city.city}
                                      </p>
                                    </p>
                                  }
                                />
                              ))}
                          </div>
                        ))}
                  </RadioGroup>
                </FormControl>
              )}
            </>
          )}
        </div>
      }
      handleApplyButton={handleApplyButton}
    />
  );
}

export function CustomRadio(props: RadioProps) {
  return (
    <Radio
      // disableRipple
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon} />}
    />
  );
}
