import { zenvoyaChatIcon, profileIcon } from "../../../constant";
import styles from "./initial.module.scss";

interface iProps {
  message: string;
  showUser?: boolean;
  maxWidth?: string;
}

function InitialChat({
  message,
  showUser = true,
  maxWidth = "360px",
}: iProps): JSX.Element {
  return (
    <div className={styles.main}>
      <div
        className={styles.container}
        // style={{ maxWidth: maxWidth }}
      >
        {showUser && (
          <div className={styles.userCard}>
            <div className={styles.avatarBox}>
              <img alt="" className={styles.userAvatar} src={profileIcon} />
              <p className={styles.userName}>YOU</p>
            </div>
            <p className={styles.message}>{message}</p>
          </div>
        )}
        <div className={styles.zenCard}>
          <img alt="" className={styles.zenAvatar} src={zenvoyaChatIcon} />
          <p className={styles.zenTitle}>zenvoya</p>
        </div>
      </div>
    </div>
  );
}

export default InitialChat;
