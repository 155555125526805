import React from "react";
import styles from "./DeskTopSelectedScreenPage.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { ScreenTypes } from "../../../enums";
import TodayTrip from "../../../routes/todayTrip/todayTrip";
import MyTrips from "../../myTripsPage/myTrips";
import PreviewPreference from "../../../routes/previewPreference/previewPreference";
import FaqAccordion from "../../../routes/faqsAccordion/faqsAccordion";
import RecommendedTrips from "../../desktopLandingPage/recommendedTrips/recommendedTrips";
import DeskTopDestinations from "../deskTopDestinations/deskTopDestinations";
import NewTrip from "../../../routes/newTrip/newTrip";

function DesktopSelectedScreenPage(): JSX.Element {
  const state = React.useContext(StateContext);
  const { firstScreen }: iGlobalContext = state;
  const switchScreens = (type: string) => {
    switch (type) {
      case ScreenTypes.CurrentTrip:
        return <TodayTrip />;
      case ScreenTypes.MyTrips:
        return <MyTrips />;
      case ScreenTypes.Preferences:
        return <PreviewPreference />;
      case ScreenTypes.help:
        return <FaqAccordion />;
      case ScreenTypes.topDestination:
        return <DeskTopDestinations />;
      case ScreenTypes.NewTrip: 
        return <NewTrip />
      default:
        return <RecommendedTrips />;
    }
  };
  return (
    <div className={styles.deskTopSelectedScreenContainer}>
      {switchScreens(firstScreen)}
    </div>
  );
}

export default DesktopSelectedScreenPage;
