import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function addHistoryAPI(data: any) {
  const URL = `${ENDPOINT}/app/v1/users/history`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    ...data
  };
  try {
    // console.log(body);
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
