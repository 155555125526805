import { createContext, useContext, ReactNode, useState } from "react";
import { iCurrentTripContext } from "./currentTripContextInterface";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getItinerariesListAPI } from "../../api/getSaveItinerariesList";
const CurrentTripContext = createContext({} as iCurrentTripContext);
export const CurrentTripContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentTripData, setCurrentTripData] = useLocalStorage(
    "_trip_trove_current_trip_data",
    {}
  );

  async function getCurrentTrip(userId: string) {
    setCurrentTripData({});
    const response = await getItinerariesListAPI(userId, "current", 0, 10);
    if (response?.data?.length > 0) {
      setCurrentTripData(response?.data[0]);
      return true;
    }
    return false;
  }

  const value = { currentTripData, setCurrentTripData, getCurrentTrip };
  return (
    <CurrentTripContext.Provider value={value}>
      {children}
    </CurrentTripContext.Provider>
  );
};
export const useCurrentTripContext = () => useContext(CurrentTripContext);
