export const flightInfoInDetail = {
  status: 200,
  message: "Flight search request processed successfully.",
  data: [
    {
      price: {
        paxPrice: [
          {
            paxSupplierFare: "871_1180.7",
            totalFare: 1180.7,
            baseFare: 871,
            paxType: "ADT",
            psup_fare: "871_1180.7",
            tax: [
              {
                YQ: 0,
                OT: 309.7,
                YR: 0,
              },
            ],
            supplierCurrency: "USD",
          },
        ],
        totalFare: 1180.7,
        baseFare: 871,
        exchangeRate: 1,
        supplierFare: "871_1180.7",
        tax: [
          {
            YQ: 0,
            OT: 309.7,
            YR: 0,
          },
        ],
        userCurrency: "USD",
        supplierCurrency: "USD",
      },
      uniqueId: "aUCc0R4wBSkPsi8a",
      outbound: [
        {
          stopsCount: 1,
          totalTime: "20:00",
          ticketingAirline: "AF",
          refundable: true,
          stops: [
            {
              departureDate: "2024-08-30T13:45:00",
              layover: "2:25",
              airport: "CDG",
              arrivalDate: "2024-08-30T11:20:00",
            },
          ],
          baggageDetails: [
            {
              baggage_amount: "pieces",
              baggage_quantity: "0",
            },
          ],
          transactionId: "hyPQIpnl82PoGyxr_1",
          flightNumber: "AF-1681/AF-662",
          segments: [
            {
              marketingAirline: "AF",
              arrival: "CDG",
              timezone: "Europe/Paris",
              departure: "LHR",
              departureDate: "2024-08-30T09:00:00",
              terminal: {
                arrival: "4",
                departure: "2E",
              },
              equipmentType: "223",
              flightNumber: "1681",
              arrivalDate: "2024-08-30T11:20:00",
              operatingAirline: "AF",
            },
            {
              marketingAirline: "AF",
              arrival: "DXB",
              timezone: "Asia/Dubai",
              departure: "CDG",
              departureDate: "2024-08-30T13:45:00",
              terminal: {
                arrival: "",
                departure: "1",
              },
              equipmentType: "772",
              flightNumber: "662",
              arrivalDate: "2024-08-30T22:35:00",
              operatingAirline: "AF",
            },
          ],
        },
      ],
    },
  ],
};

