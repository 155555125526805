import React from "react";
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import { DialogSelected } from "../../constant/common";
import { logoutAPI } from "../../api/logout";
import { iGlobalContext } from "../../context/globalContext/interface";
import { LOGGED_OUT } from "../../constant";
import ReactGA from "react-ga4";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { TOAST_MESSAGES } from "../../constant/toastMessages";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";

interface iProps {
  closeDrawer: () => void;
}

export default function Login({ closeDrawer }: iProps): JSX.Element {
  const navigate = useNavigate();
  // Context
  const state = React.useContext(StateContext);
  const {
    setNav,
    setMenuOpenState,
    userDetails,
    setLoginCreateAccountState,
    setSignInClick,
    setStartingValue,
    setDestValue,
    setClimate,
    setPopulation,
    setExperience,
    setAccomodations,
    setFoodPreferences,
    setMonths,
    setSelectedDestFlag,
    setUserDetails,
    setTripForNDays,
    setIsLoggedIn,
    setBudget,
    setScreen,
    setFirstScreen,
    userInfo,
    setUserInfo,
  }: iGlobalContext = state;

  // Sign in
  function handleSignIn() {
    setSignInClick(true);
    setMenuOpenState(false);
    setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
    closeDrawer();
  }
  // async function handleLogoutClick() {
  //   const response = await logoutAPI(userDetails.id);

  //   if (response?.statusCode === 200) {
  //     // Clear state
  //     toastMessage.success("Success", "Logout successful");
  //     setScreen("");
  //     setFirstScreen("");
  //     navigate("/");
  //     window.localStorage.removeItem("JWT");
  //     const resetUserInfo = {
  //       id: "",
  //       name: "",
  //       email: "",
  //       phone: "",
  //       profile_pic: "",
  //       loggedIn: false,
  //       role: 0,
  //     };

  //     setUserInfo(resetUserInfo);
  //     setUserDetails(resetUserInfo);
  //     setIsLoggedIn(false);

  //     setNav("LANDING_PAGE");
  //     setSelectedDestFlag(false);
  //     setStartingValue({ value: "", flag: false });
  //     setDestValue({ value: "", flag: false });

  //     const resetState = (
  //       setter: React.Dispatch<React.SetStateAction<any[]>>
  //     ) => setter([]);

  //     [
  //       setClimate,
  //       setPopulation,
  //       setExperience,
  //       setFoodPreferences,
  //       setAccomodations,
  //       setMonths,
  //       setBudget,
  //       setTripForNDays,
  //     ].forEach(resetState);

  //     closeDrawer();

  //     // Clear localStorage and sessionStorage
  //     const keysToRemove = [
  //       "_starting",
  //       "_destination",
  //       "_extra_meta",
  //       "_international",
  //       "_select_dest_flag",
  //       "shared_itinerary_id",
  //       "_itinerary_details_from_list",
  //       "_itinerary_details",
  //       "_shared_itinerary_id",
  //       "_sliderValue",
  //     ];
  //     keysToRemove.forEach((key) => {
  //       window.localStorage.removeItem(key);
  //       window.sessionStorage.removeItem(key);
  //     });
  //   }

  //   ReactGA.event({
  //     category: LOGGED_OUT.CATEGORY,
  //     action: LOGGED_OUT.ACTION,
  //     label: ` ${LOGGED_OUT.LABEL}  `,
  //   });

  //   /**
  //    * post message data
  //    */
  //   const iframe: HTMLIFrameElement | null =
  //     document.querySelector("#app_domain");
  //   const wind = iframe?.contentWindow;
  //   wind?.postMessage(JSON.stringify({}), "*");

  //   if (window && window.parent) {
  //     // console.log("Window.parent", window.parent);
  //     const message = {
  //       action: "logout",
  //       data: {},
  //     };
  //     // console.log("MESSAGE", message);
  //     window.parent.postMessage(JSON.stringify(message)); // or specify a specific origin instead of "*"
  //   }
  // }

  function resetAppState() {
    setScreen("");
    setFirstScreen("");
    window.localStorage.removeItem("JWT");

    const resetUserInfo = {
      id: "",
      name: "",
      email: "",
      phone: "",
      profile_pic: "",
      loggedIn: false,
      role: 0,
    };
    [setUserInfo, setUserDetails].forEach((setFunc) => setFunc(resetUserInfo));
    setIsLoggedIn(false);

    setNav("LANDING_PAGE");
    setSelectedDestFlag(false);
    setStartingValue({ value: "", flag: false });
    setDestValue({ value: "", flag: false });

    resetStateValues([
      setClimate,
      setPopulation,
      setExperience,
      setFoodPreferences,
      setAccomodations,
      setMonths,
      setBudget,
      setTripForNDays,
    ]);

    closeDrawer();
  }

  function resetStateValues(
    setters: React.Dispatch<React.SetStateAction<any[]>>[]
  ) {
    setters.forEach((setter) => setter([]));
  }

  function clearStorage(keys: string[]) {
    keys.forEach((key) => {
      window.localStorage.removeItem(key);
      window.sessionStorage.removeItem(key);
    });
  }

  function postLogoutMessage() {
    const iframe = document.querySelector(
      "#app_domain"
    ) as HTMLIFrameElement | null;
    const wind = iframe?.contentWindow;
    wind?.postMessage(JSON.stringify({}), "*");

    if (window && window.parent) {
      const message = {
        action: "logout",
        data: {},
      };
      window.parent.postMessage(JSON.stringify(message), "*");
    }
  }

  async function handleLogoutClick() {
    try {
      const response = await logoutAPI(userDetails.id);

      if (response?.statusCode !== 200) {
        toastMessage.error("OOPS", "Logout failed");
      }

      toastMessage.success(
        TOAST_MESSAGES.AUTHENTICATION.logoutSuccess.messageTitle,
        TOAST_MESSAGES.AUTHENTICATION.logoutSuccess.message
      );

      // Clear state and storage
      resetAppState();
      clearStorage([
        "JWT",
        "_starting",
        "_destination",
        "_extra_meta",
        "_international",
        "_select_dest_flag",
        "shared_itinerary_id",
        "_itinerary_details_from_list",
        "_itinerary_details",
        "_shared_itinerary_id",
        "_sliderValue",
        // "_trip_trove_selected_chat_data",
      ]);

      // Navigate and trigger post-logout actions
      navigate("/");
      triggerGAEvent(GA_LABEL_ENUMS.Logout);
      postLogoutMessage();
    } catch (error) {
      console.error("Error during logout:", error);
      toastMessage.error("Logout failed. Please try again.");
    }
  }

  return (
    <Stack
      zIndex={999}
      bgcolor="transparent"
      sx={{ borderRadius: "4px" }}
      spacing={2}
    >
      {userInfo.loggedIn === true ? (
        <Button
          onClickCapture={handleLogoutClick}
          size="small"
          sx={{
            mb: { sm: 4, md: 0 },
            bgcolor: "#BF150D",
            color: "#ffff",
            borderRadius: "46.5px",
            boxShadow: "none",
            textTransform: "none",
            fontFamily: "Lato",
            pt: 0.5,
            pb: 0.5,
            pl: { xs: 2, sm: 2, md: 2 },
            pr: { xs: 2, sm: 2, md: 2 },
            "&:hover": {
              bgcolor: "#BF150D",
              color: "#ffff",
            },
          }}
          variant="text"
        >
          Log out
        </Button>
      ) : (
        <Button
          variant="contained"
          size="small"
          sx={{
            mb: { xs: "80px", sm: 4, md: 0 },
            bgcolor: "#BF150D",
            color: "#fff",
            borderRadius: "46.5px",
            boxShadow: "none",
            textTransform: "none",
            fontFamily: "Lato",
            pt: 0.5,
            pb: 0.5,
            pl: { xs: 2, sm: 2, md: 2 },
            pr: { xs: 2, sm: 2, md: 2 },
            "&:hover": {
              bgcolor: "#BF150D",
              color: "#fff",
            },
            width: "max-content",
            alignSelf: "center",
          }}
          onClickCapture={() => {
            triggerGAEvent(GA_LABEL_ENUMS.Login_in_click);
            handleSignIn();
          }}
        >
          Login
        </Button>
        // <Stack
        //   minHeight={{ xs: "100dvh", md: "" }}
        //   justifyContent={{ xs: "flex-end", md: "" }}
        // >
        //   <Button
        //     onClickCapture={() => {
        //       triggerGAEvent(GA_LABEL_ENUMS.Login_in_click);
        //       handleSignIn();
        //     }}
        //     size="small"
        //     sx={{
        //       mb: { xs: "80px", sm: 4, md: 0 },
        //       bgcolor: "#BF150D",
        //       color: "#fff",
        //       borderRadius: "46.5px",
        //       boxShadow: "none",
        //       textTransform: "none",
        //       fontFamily: "Lato",
        //       pt: 0.5,
        //       pb: 0.5,
        //       pl: { xs: 2, sm: 2, md: 2 },
        //       pr: { xs: 2, sm: 2, md: 2 },
        //       "&:hover": {
        //         bgcolor: "#BF150D",
        //         color: "#fff",
        //       },
        //       width: "max-content",
        //       alignSelf: "center",
        //     }}
        //     variant="text"
        //   >
        //     Log in
        //   </Button>
        // </Stack>
      )}
    </Stack>
  );
}
