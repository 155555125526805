import styles from "./location.module.scss";

interface iProps {
  city: string;
}

function CityLocation({ city }: iProps): JSX.Element {
  const cityName = city?.split(",")[0] || "";
  const countryName = city?.split(",")[1] || "";
  return (
    <div className={styles.main}>
      <p className={styles.label}>LOCATION</p>
      <div className={styles.btn}>
        {city ? (
          <p className={styles.btnName}>
            <p className={styles.countryName}> {cityName}</p>
            <p className={styles.countryName}>{countryName}</p>
          </p>
        ) : (
          <p className={styles.btnName}>{""}</p>
        )}
      </div>
    </div>
  );
}

export default CityLocation;
