import britishAirways from "../../../../assets/britishAirways.png";
import styles from "./flight.module.scss";
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { expandIcon, planeEnginesLight } from "../../../../constant";
import { CustomRadio } from "../../customRadio/customRadio";
import { useContext, useEffect, useRef, useState } from "react";
import FlightDetails from "../flightDetails/flightDetails";
import {
  getFlightAvailability,
  getFlightDetails,
} from "../../../../api/booking";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import toast from "react-hot-toast";
import Loader from "../../../reusableComponent/loader/Loader";
import NewLoader from "../../../reusableComponent/newLoader/NewLoader";

interface iProps {
  setFlightData?: any;
  flightData?: any;
  startTime: string;
  endTime: string;
  flightName: string;
  stopsInfo: string;
  stops: number;
  stopTime: string;
  airports: string;
  flightCost: string;
  flightClass: string;
  tourPackage: boolean;
  buttonName: string;
  flightImage?: string;
  sup?: string;
  detailsButton: string;
  handleBook: () => void;
  selected?: boolean;
  encryptedKey?: string;
  uniqueId?: string;
  inOut?: string;
  selectedData?: string;
  handleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDetailsBtnClick?: any;
}

const FlightListing: React.FC<iProps> = ({
  flightData,
  startTime,
  endTime,
  flightName,
  stopsInfo,
  stops,
  stopTime,
  airports,
  flightCost,
  flightClass,
  tourPackage,
  buttonName,
  flightImage,
  detailsButton,
  handleBook,
  encryptedKey = "",
  uniqueId = "",
  selectedData,
  handleSelect,
  onDetailsBtnClick,
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [flightDetails, setflightDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const globalContext = useContext(StateContext);
  const {
    setFlightAvailabilityData,
    isCheckingFlight,
    setIsCheckingFlight,
    setBookingDetails,
  }: iGlobalContext = globalContext;

  // const handleShowDetails = () => {
  //   setShowDetails(!showDetails);
  // };

  const handleShowDetails = async (encryptedKey: string, uniqueId: string) => {
    if (showDetails) {
      setShowDetails(!showDetails);
      return;
    }
    setIsOpenFlightDetailsLoader(true);
    try {
      const currentFlightDetails =
        flightData?.sectors?.find((ele: any) => ele?.uniqueId === uniqueId) ||
        undefined;
      if (currentFlightDetails?.flightDetails) return;
      if (onDetailsBtnClick) onDetailsBtnClick();
      setShowDetails(!showDetails);
      setflightDetails(null);
      const data = await getFlightDetails(encryptedKey, uniqueId);
      // console.log({ data });
      setflightDetails(data);
      // setFlightData((prev: any) =>
      //   prev?.sectors?.map((ele: any) => {
      //     // console.log(ele.uniqueId, uniqueId);
      //     if (ele?.uniqueId === uniqueId)
      //       return {
      //         ...ele,
      //         flightDetails: data,
      //       };
      //     else return ele;
      //   })
      // );
      setIsOpenFlightDetailsLoader(false);
    } catch (err) {
      setShowDetails(!showDetails);
      setIsOpenFlightDetailsLoader(false);
    } finally {
      setLoading(false);
      setIsOpenFlightDetailsLoader(false);
    }
  };

  const getButtonName = (name: string) => {
    switch (name) {
      case "E":
        return "Economy";
      case "P":
        return "Premium Plus";
      case "B":
        return "Business";
      case "F":
        return "First Class";
      default:
        return "Economy";
    }
  };

  let checkFlightController: any = useRef(null);

  // const handleFlightAvailability = async (
  //   encryptedKey: string,
  //   uniqueId: string
  // ) => {
  //   setLoading(true);

  //   try {
  //     toast.dismiss();
  //     checkFlightController.current = new AbortController();
  //     setIsCheckingFlight(true);
  //     const { statusCode, ...data } = await getFlightAvailability(
  //       encryptedKey,
  //       uniqueId,
  //       checkFlightController?.current
  //     );
  //     toast.dismiss();
  //     if (statusCode === 200) {
  //       handleBook();
  //       setBookingDetails({
  //         ...bookingDetails,
  //         isFlightAvailable: true,
  //         selectedFlightId: uniqueId,
  //       });
  //     } else {
  //       setIsCheckingFlight(false);
  //       setBookingDetails({
  //         ...bookingDetails,
  //         isFlightAvailable: false,
  //         selectedFlightId: uniqueId,
  //       });
  //       toastMessage.error(
  //         TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.messageTitle,
  //         TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.message
  //       );
  //       // toastMessage.error("OOPS!", "Flight not available");
  //     }
  //     setFlightAvailabilityData(data?.data);
  //   } catch (err) {
  //     setError(true);
  //     setIsCheckingFlight(false);
  //     setLoading(false);
  //   } finally {
  //     setIsCheckingFlight(false);
  //     setLoading(false);
  //   }
  // };

  const handleFlightAvailability = async (
    encryptedKey: string,
    uniqueId: string
  ): Promise<boolean> => {
    setLoading(true);
    setIsCheckingFlight(true);
    toast.dismiss();
    try {
      checkFlightController.current = new AbortController();
      const { statusCode, data: flightData } = await getFlightAvailability(
        encryptedKey,
        uniqueId,
        checkFlightController.current
      );
      if (statusCode === 200) {
        setBookingDetails((prevBookingDetails) => ({
          ...prevBookingDetails,
          isFlightAvailable: true,
          selectedFlightId: uniqueId,
        }));
        handleBook();
        setFlightAvailabilityData(flightData);
        return true;
      }
      return false;
    } catch (err) {
      console.error("Failed to check flight availability:", err);
      return false;
    } finally {
      setIsCheckingFlight(false);
      setLoading(false);
    }
  };

  // const outboundData = flightInfo.map((flight) => flight.outbound);
  // const inboundData = flightInfo.map((flight) => flight.inbound);
  const flightPrice = parseFloat(flightCost);

  const outboundData = flightDetails?.data[0]?.outbound;
  const inboundData = flightDetails?.data[0]?.inbound;

  useEffect(() => {
    if (!isCheckingFlight && checkFlightController?.current)
      checkFlightController?.current?.abort();
  }, [isCheckingFlight]);

  const [openFlightDetailsLoader, setIsOpenFlightDetailsLoader] =
    useState(false);

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const response = await handleFlightAvailability(encryptedKey, uniqueId);
    if (response) {
      handleSelect(event);
    }
  };

  return (
    <div className={styles.main}>
      <NewLoader
        loaderIcon1={planeEnginesLight}
        text="Searching for flights details"
        isOpen={openFlightDetailsLoader}
        onClose={() => {
          setIsOpenFlightDetailsLoader(false);
        }}
      />
      <div className={styles.container}>
        <div className={styles.contentCard}>
          <div className={styles.hotelListCard}>
            <div className={styles.hotelList}>
              <div className={styles.card1}>
                <div className={styles.flightImgCard}>
                  <img
                    src={flightImage ? flightImage : britishAirways}
                    alt="flight"
                    className={styles.flightImage}
                  />
                </div>

                <p className={styles.flightTime}>
                  {startTime} - {endTime}
                  {/* {sup && <sup className={styles.supText}>+ {sup}</sup>} */}
                  <br />
                  <span className={styles.flightName}>{flightName}</span>
                </p>
              </div>
              <div className={styles.card2}>
                <p className={styles.stops}>
                  {stops > 0
                    ? `${stops} ${stops > 1 ? "Stops" : "Stop"}`
                    : "Nonstop"}

                  <br />
                  <span className={styles.stopInfo}>{stopsInfo}</span>
                </p>
                <p className={styles.stops}>
                  {stopTime}
                  <br />
                  <span className={styles.stopInfo}>{airports}</span>
                </p>
              </div>
              <div className={styles.card3}>
                <Button
                  className={styles.flightBtn}
                  endIcon={
                    <img
                      src={expandIcon}
                      alt="icon"
                      className={`${showDetails ? styles.rotateExpIcon : styles.expIcon}`}
                    />
                  }
                  // onClick={handleShowDetails}
                  onClick={() => {
                    if (encryptedKey && uniqueId) {
                      handleShowDetails(encryptedKey, uniqueId);
                    }
                  }}
                >
                  {showDetails ? "Hide Details" : detailsButton}
                </Button>
              </div>
              <div className={styles.card4}>
                {tourPackage ? (
                  <section className={styles.tourPack}>
                    <FormControl className={styles.radioControl}>
                      <RadioGroup
                        // onChange={handleSelect}
                        value={selectedData}
                        onChange={handleOnChange}
                        className={styles.radioGroup}
                      >
                        <FormControlLabel
                          key={uniqueId}
                          value={uniqueId}
                          control={
                            <CustomRadio checked={selectedData === uniqueId} />
                          }
                          label={
                            <span className={styles.radio}>
                              ${Math.round(flightPrice)}
                              <span className={styles.flightClass}>
                                {getButtonName(flightClass)}
                              </span>
                            </span>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </section>
                ) : (
                  <section className={styles.flight}>
                    <p className={styles.price}>
                      ${Math.round(flightPrice)}
                      <br />
                      <span className={styles.flightClass}>
                        {getButtonName(flightClass)}
                      </span>
                    </p>
                    <Button
                      className={styles.bookBtn}
                      // onClick={handleBook}
                      onClick={() => {
                        if (encryptedKey && uniqueId) {
                          handleFlightAvailability(encryptedKey, uniqueId);
                        } else {
                          handleBook();
                          // toastMessage.error("OOPS!","Something went wrong");
                        }
                      }}
                    >
                      {`${loading ? "Checking" : buttonName}`}
                    </Button>
                  </section>
                )}
              </div>
            </div>
            {showDetails && (
              <div className={styles.testCard}>
                {flightDetails ? (
                  flightDetails?.data?.length > 0 ? (
                    <>
                      {outboundData?.length > 0 &&
                        outboundData?.map((ele: any, index: number) => (
                          <div key={index}>
                            <FlightDetails
                              // flightData={outboundData[0]}
                              flightData={ele}
                              flightImage={flightImage}
                            />
                          </div>
                        ))}
                      {inboundData?.length > 0 &&
                        inboundData?.map((ele: any, index: number) => (
                          <div key={index}>
                            <FlightDetails
                              flightData={ele}
                              flightImage={flightImage}
                            />
                          </div>
                        ))}
                    </>
                  ) : (
                    <p className={styles.noDetails}>No details found</p>
                  )
                ) : (
                  <div className={styles.loaderContainer}>
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightListing;
