import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styles from "./customAccordion.module.scss";
import { expandIcon } from "../../../constant";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

export default function CustomAccordion({
  className = "",
  summaryClass = "",
  headingComponent,
  bodyComponent,
  accordianDetailsClass = "",
  borderBottom = false,
  defaultExpanded = false,
  zeroMarginOnExpand = false,
  hideExpandIcon = false,
  listing = false,
  onChange,
}: {
  className?: string;
  summaryClass?: string;
  accordianDetailsClass?: string;
  headingComponent: React.ReactNode;
  bodyComponent: React.ReactNode;
  borderBottom?: boolean;
  defaultExpanded?: boolean;
  zeroMarginOnExpand?: boolean;
  hideExpandIcon?: boolean;
  listing?: boolean;
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
}) {
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      className={`${styles.mainContainer} ${className}`}
      sx={{
        "&.Mui-expanded": {
          marginTop: `${zeroMarginOnExpand && "0"}`,
        },
      }}
      onChange={onChange}>
      <AccordionSummary
        expandIcon={
          <img
            src={expandIcon}
            className={`${hideExpandIcon ? styles.hideExpandIcon : styles.expandIcon}`}
            alt="expand-icon"
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${styles.summary} ${summaryClass}`}
        sx={{
          borderBottom: borderBottom ? "1px solid #cacacc" : "none",
          backgroundColor: borderBottom ? "#F4F4F4" : "transparent",
          padding: 0,
          margin: 0,
          "& .MuiAccordionSummary-content": {
            margin: listing ? "5px 0" : "12px 0",
          },
        }}>
        {headingComponent}
      </AccordionSummary>
      <AccordionDetails className={`${accordianDetailsClass}`}>
        {bodyComponent}
      </AccordionDetails>
    </Accordion>
  );
}
