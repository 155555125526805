import React, { useContext } from "react";
import CustomAccordion from "../customAccordion/CustomAccordion";
import styles from "./bookedHotelAccordion.module.scss";
import { getDayOfWeek, getUTCMonthDate } from "../../../utils/dateUtils";
import {
  bedFontLight,
  eyeLight,
  hotelRoomIcon,
  propertyStarBlue,
  ROUTES,
} from "../../../constant";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { String } from "aws-sdk/clients/acm";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { ScreenTypes } from "../../../enums";
import HotelCard from "../../booking/hotelBooking/hotelCard/hotelCard";
import DiscountCard from "../../booking/hotelBooking/discountCard/discountCard";

interface iProps {
  checkIn?: any;
  checkOut?: any;
  star: number;
  hotelName: string;
  hotelLocation: string;
  hotelRating: number;
  reviewCount: number;
  cost: number;
  cancellation: string;
  Amenity: string[];
  hotelList: boolean;
  discount?: string;
  discountCost?: string;
  selected?: number | null;
  onSelect?: () => void;
  id?: number | null;
  hotelMainImage?: string;
  taxesIncluded: string;
  reviewHeading?: string;
  hotelId?: string;
  hotelDetails?: any;
  city?: string;
  hotelAccordionClick?: any;
  hotelViewDetailsClick?: any;
  // setShowSelectRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BookedHotelAccordion({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  cost,
  cancellation,
  Amenity,
  hotelList,
  discount,
  discountCost,
  selected,
  onSelect,
  id,
  hotelMainImage,
  taxesIncluded,
  reviewHeading,
  hotelId,
  hotelDetails,
  city,
  checkIn,
  checkOut,
  hotelAccordionClick,
  hotelViewDetailsClick,
  // setShowSelectRoomModal,
}: iProps) {
  const navigate = useNavigate();
  const globalContext = useContext(StateContext);
  const {
    setHotelDetailsPageId,
    isMobile,
    setScreen,
    setShouldUserNavigateBackTotripDetailsPage,
  }: iGlobalContext = globalContext;

  const isThisHotelSelected = false;
  const handleViewDetails = () => {
    if (hotelViewDetailsClick) hotelViewDetailsClick();
    // if (!hotelId) return;
    // setHotelDetailsPageId(hotelId);
    // if (isMobile) {
    //   navigate(ROUTES.HotelDetails);
    // } else {
    //   setShouldUserNavigateBackTotripDetailsPage(false);
    //   setScreen(ScreenTypes.HotelDetails);
    // }
    // console.log(hotelId)
    if (!hotelId) return;
    setHotelDetailsPageId(hotelId);
    if (isMobile) {
      navigate(ROUTES.HotelDetails);
    }
    if (!isMobile) {
      setShouldUserNavigateBackTotripDetailsPage(true);
      setScreen(ScreenTypes.HotelDetails);
    }
  };

  return (
    <CustomAccordion
      className={styles.customFlightStyles}
      summaryClass={styles.summaryFlightClass}
      accordianDetailsClass={styles.accordFlightDetailsClass}
      defaultExpanded={false}
      headingComponent={
        <div
          className={styles.headingFlightComp}
          onClick={() => {
            if (hotelAccordionClick) hotelAccordionClick();
          }}
        >
          <div className={styles.accordTitleCard}>
            {checkIn && checkOut && (
              <p className={styles.dayAndCity}>
                {getDayOfWeek(checkIn)} &nbsp;
                <span>{getUTCMonthDate(checkIn, true)}</span> - &nbsp;
                {getDayOfWeek(checkOut)} &nbsp;
                <span>{getUTCMonthDate(checkOut, true)}</span>
              </p>
            )}
            {city && <p className={styles.accordDesc}>{city.split(",")[0]}</p>}
          </div>
          {bedFontLight && (
            <img
              src={bedFontLight}
              alt="plane"
              className={styles.planeDepartureIcon}
            />
          )}
        </div>
      }
      bodyComponent={
        // <div className={styles.main}>
        //   <div className={styles.twoSectionsContainer}>
        //     <img
        //       src={hotelMainImage || hotelRoomIcon}
        //       alt="hotel"
        //       className={`${styles.hotelImg}`}
        //     />
        //     <section className={styles.contentCard}>
        //       <div className={styles.starContainer}>
        //         <img
        //           className={styles.starPropertyImg}
        //           src={propertyStarBlue}
        //           alt="star icon"
        //         />
        //         <span className={styles.star}>{stars} star property</span>
        //       </div>
        //       <p className={styles.hotelName}>{name}</p>
        //       <p className={styles.location}>
        //         {/* {place} &nbsp; | &nbsp; */}
        //         {address}
        //       </p>
        //       <div className={styles.roomDetailsContainer}>
        //         <div className={styles.room}>
        //           {noOfRooms} Room -{" "}
        //           {/* {bedCount > 1 ? bedCount : ""} {bedSize}{" "} */}
        //           {bedInformation[0]?.bedType}
        //         </div>
        //       </div>
        //     </section>
        //   </div>
        //   <div className={styles.card3}>
        //     <Button
        //       className={styles.flightBtn}
        //       onClick={() => {
        //         if (!hotelId) return;
        //         setHotelDetailsPageId(hotelId);
        //         if (isMobile) {
        //           navigate(ROUTES.HotelDetails);
        //         }
        //         if (!isMobile) {
        //           setShouldUserNavigateBackTotripDetailsPage(true);
        //           setScreen(ScreenTypes.HotelDetails);
        //         }
        //       }}
        //     >
        //       <div className={styles.iconAndText}>
        //         <img src={eyeLight} alt="icon" className={styles.expIcon} />
        //         <span className={styles.viewDetails}>
        //           VIEW <br /> DETAILS
        //         </span>
        //       </div>
        //     </Button>
        //   </div>
        // </div>
        <div className={styles.main}>
          <div className={styles.container}>
            <div className={styles.contentCard}>
              <div
                className={`${styles.hotelListCard} ${isThisHotelSelected && styles.selectedHotelListCard}`}
              >
                <HotelCard
                  star={star}
                  hotelName={hotelName || "Hotel name"}
                  hotelLocation={hotelLocation || "Hotel location"}
                  hotelRating={hotelRating || 0}
                  reviewCount={reviewCount || 0}
                  Amenity={Amenity?.slice(0, 3)}
                  hotelList={hotelList}
                  hotelMainImage={hotelMainImage}
                  reviewHeading={reviewHeading || "No reviews"}
                  handleViewDetails={handleViewDetails}
                  city={city}
                />
                <div className={styles.card3}>
                  <Button
                    className={styles.flightBtn}
                    onClick={() => {
                      if (!hotelId) return;
                      setHotelDetailsPageId(hotelId);
                      if (isMobile) {
                        navigate(ROUTES.HotelDetails);
                      }
                      if (!isMobile) {
                        setShouldUserNavigateBackTotripDetailsPage(false);
                        setScreen(ScreenTypes.HotelDetails);
                      }
                    }}
                  >
                    <div className={styles.iconAndText}>
                      {discount && (
                        <DiscountCard
                          cost={discountCost}
                          percentage={discount}
                        />
                      )}
                      <div className={styles.viewDetailsBox}>
                        <img
                          src={eyeLight}
                          alt="icon"
                          className={styles.expIcon}
                        />
                        <span className={styles.viewDetails}>
                          View <br /> Details
                        </span>
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      zeroMarginOnExpand
      borderBottom
    />
  );
}
