import axios from "axios";
import { ENDPOINT } from "../constant/environment";
import { API_HEADER } from "../constant/common";
// import { apiCallWithNRetries, wait } from "../utils";
// import { HOTEL_DETAILS_DUMMYDATA } from "../component/hotelDetails/hotelDetailsConstants";
// import {
//   HOTEL_AVAILABILITY_RESPONSE,
//   SELECT_ROOM_CONSTANTS,
// } from "../component/hotelDetails/components/selectRoomModal/selectRoomsConstants";
import { toastMessage } from "../helpers/toast/toastMessage";
import toast from "react-hot-toast";

export const getHotelDetailsAPI = async (
  hotelId: string,
  controller: AbortController,
  isLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const URL = `${ENDPOINT}/app/v1/hotel/${hotelId}`;
  const config = {
    headers: API_HEADER,
    signal: controller.signal,
  };
  // const body = {
  //   hotelId,
  // };
  try {
    if (isLoading) isLoading(true);
    const response = await axios.get(URL, config);
    // const response: any = await wait(2000, HOTEL_DETAILS_DUMMYDATA);
    if (isLoading) isLoading(false);
    // console.log(response, "reponse");
    return response?.data.data;
  } catch (error) {
    if (isLoading) isLoading(false);
    // console.log(error);
    return null;
  }
};

export const getRoomDetailsAPI = async (
  roomDetailsBody: any,
  controller: AbortController,
  isLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const URL = `${ENDPOINT}/app/v1/hotel/${roomDetailsBody.hotelId}`;
  const config = {
    headers: API_HEADER,
    signal: controller.signal,
  };
  const body = {
    ...roomDetailsBody,
  };
  try {
    if (isLoading) isLoading(true);
    const response = await axios.post(URL, body, config);
    // const response: any = await wait(2000, SELECT_ROOM_CONSTANTS);
    if (isLoading) isLoading(false);
    // // console.log(response);
    return response?.data?.data;
  } catch (error) {
    if (isLoading) isLoading(false);
    // console.log(error);
    return null;
  }
};

export interface iHotelsArgs {
  uniqueId: string;
  sessionId: string;
}

export interface iHoteRoomAvailableArgs {
  hotels: iHotelsArgs[];
}

export const checkHotelRoomAvailable = async (
  args: iHoteRoomAvailableArgs,
  controller: AbortController,
  isLoading?: any
): Promise<any> => {
  const URL = `${ENDPOINT}/app/v1/hotel/availability`;
  // const URL = `https://inyuqios27.execute-api.us-east-1.amazonaws.com/dev/be/v1/search/hotels/availability`;
  const config = {
    headers: API_HEADER,
    signal: controller.signal,
  };
  const body = {
    ...args,
  };
  try {
    // toast.loading("Please wait, checking room availibility");
    if (isLoading) isLoading(true);
    const response = await axios.post(URL, body, config);
    // const response: any = await apiCallWithNRetries(
    //   () => axios.post(URL, body, config),
    //   3,
    //   1
    // );
    // const response: any = await wait(2000, HOTEL_AVAILABILITY_RESPONSE);
    if (isLoading) isLoading(false);
    // // console.log(response, response?.data.data.data, "hotel availabilityies");
    toast.dismiss();

    return response?.data?.data?.data;
  } catch (error: any) {
    toast.dismiss();
    if (isLoading) isLoading(false);
    // console.log(error);
    return null;
  }
};

export interface iBookHotelRoomArgs {
  clientId: string;
  userId: number;
  passengerDetails: PassengerDetail[];
  hotels: iHotelRoomBookArgs[];
}

export interface iHotelRoomBookArgs {
  prebookId: string;
  sessionId: string;
}

export interface PassengerDetail {
  passengerId: number;
  room_no: number;
  title: string;
  paxType: string;
  leadPax: boolean;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
}

export const bookHotelRoomAPI = async (
  args: iBookHotelRoomArgs,
  isLoading?: any
): Promise<any> => {
  const URL = `${ENDPOINT}/app/v1/book/hotel`;
  // const URL = `https://inyuqios27.execute-api.us-east-1.amazonaws.com/dev/be/v1/booking/hotels/`;
  const config = {
    headers: API_HEADER,
    // signal,
  };
  const body = {
    ...args,
  };
  try {
    // toastMessage.loading("Please wait, Booking room");
    if (isLoading) isLoading(true);
    const response = await axios.post(URL, body, config);
    // const response: any = await wait(2000, HOTEL_AVAILABILITY_RESPONSE);
    if (isLoading) isLoading(false);
    // console.log(response, "response from hotel book");
    // // console.log(response, response?.data.data.data, "hotel availabilityies");
    toast.dismiss();
    if (response?.data?.statusCode !== 200)
      throw new Error(response?.data.message);

    return response?.data?.data;
  } catch (error: any) {
    toast.dismiss();
    if (isLoading) isLoading(false);
    toastMessage.error(error.message);
    // console.log(error, "error message");
    return null;
  }
};
