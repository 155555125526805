import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./airportSelectionPopover.module.scss";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {
  Airport,
  AirportDataTypes,
  BookingDetails,
  iCityDetailsResult,
} from "../../../../types";
import { FormControlLabel } from "@mui/material";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { getAirports } from "../../../../api/booking";

interface iProps {
  popoverTrigger: React.ReactNode;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  departure: string;
  arrival: string;
  fromCity: string;
  destinationCity: string;
  indexNumber: number;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityLatitude: string;
  departureCityLongitude: string;
  airportData: AirportDataTypes[];
}

export default function AirportSelectionPopover({
  popoverTrigger,
  bookingDetails,
  setBookingDetails,
  isPopoverOpen,
  setIsPopoverOpen,
  departure,
  arrival,
  fromCity,
  destinationCity,
  indexNumber,
  arrivalCityLatitude,
  arrivalCityLongitude,
  departureCityLatitude,
  departureCityLongitude,
  airportData,
}: iProps) {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const [airPorts, setAirPorts] = useState<AirportDataTypes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const updateBookingDetailsWithAirports = (
    data: AirportDataTypes[],
    fromCity: string,
    destinationCity: string,
    indexNumber: number
  ) => {
    if (data?.length > 0) {
      const departureCityAirports = data.find(
        (city: AirportDataTypes) => city.city === fromCity
      )?.airports;
      const arrivalCityAirports = data.find(
        (city: AirportDataTypes) => city.city === destinationCity
      )?.airports;

      // Check and update departure airport only if it's not already set
      if (departureCityAirports && departureCityAirports.length > 0) {
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airPort: prevDetails.airPort.map((airPort, idx) => {
            if (idx === indexNumber && !airPort.departure) {
              return {
                ...airPort,
                departure: departureCityAirports[0].code,
                departureAirportFullName: departureCityAirports[0].fullName,
              };
            }
            return airPort;
          }),
        }));
      }
      if (arrivalCityAirports && arrivalCityAirports.length > 0) {
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airPort: prevDetails.airPort.map((airPort, idx) => {
            if (idx === indexNumber && !airPort.arrival) {
              return {
                ...airPort,
                arrival: arrivalCityAirports[0].code,
                arrivalAirportFullName: arrivalCityAirports[0].fullName,
              };
            }
            return airPort;
          }),
        }));
      }
    }
  };

  const handleUpdateAirPortData = useCallback(() => {
    setAirPorts(airportData);
    updateBookingDetailsWithAirports(
      airportData,
      fromCity,
      destinationCity,
      indexNumber
    );
  }, [destinationCity, fromCity]);

  useEffect(() => {
    handleUpdateAirPortData();

    const timeoutId = setTimeout(() => {
      handleUpdateAirPortData();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleRadioArrivalChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    airportIndex: number
  ) => {
    const selectedAirportCode = event.target.value;
    const selectedCity = airPorts.find((city) => city.city === destinationCity);
    const selectedAirport = selectedCity?.airports.find(
      (airport) => airport.code === selectedAirportCode
    );

    setBookingDetails((prevDetails) => {
      const updatedAirports = [...prevDetails.airPort];
      updatedAirports[airportIndex] = {
        ...updatedAirports[airportIndex],
        arrival: selectedAirportCode,
        arrivalAirportFullName: selectedAirport?.fullName || "",
      };

      return {
        ...prevDetails,
        airPort: updatedAirports,
      };
    });
  };

  const handleRadioDepartureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    airportIndex: number
  ) => {
    const selectedAirportCode = event.target.value;

    // Find the city and airport details based on airportIndex and the selectedAirportCode
    const selectedCity = airPorts.find((city) => city.city === fromCity);
    const selectedAirport = selectedCity?.airports.find(
      (airport) => airport.code === selectedAirportCode
    );

    setBookingDetails((prevDetails) => {
      const updatedAirports = [...prevDetails.airPort];
      updatedAirports[airportIndex] = {
        ...updatedAirports[airportIndex],
        departure: selectedAirportCode,
        departureAirportFullName: selectedAirport?.fullName || "",
      };

      return {
        ...prevDetails,
        airPort: updatedAirports,
      };
    });
  };

  // function combineCityNames(
  //   selectedChatData: any
  // ): { name: string; latitude: string; longitude: string }[] {
  //   if (!selectedChatData || !selectedChatData.fromCity) {
  //     return [];
  //   }
  //   const fromCity = {
  //     name: selectedChatData.fromCity,
  //     latitude: "",
  //     longitude: "",
  //   };

  //   const extractCityNames =
  //     selectedChatData?.destinationList?.map((i: iCityDetailsResult) => ({
  //       name: i?.cityName?.split(",")[0] || i?.destination_city,
  //       latitude: i?.city_latitude || "",
  //       longitude: i?.city_longitude || "",
  //     })) || [];

  //   return [fromCity, ...extractCityNames];
  // }
  // let fetchAirportController: any = useRef(null);
  // const handleFlightSearch = async () => {
  //   const cities = combineCityNames(selectedChatData);

  //   if (cities.length === 0) {
  //     toastMessage.error("No valid cities selected");
  //     return;
  //   }

  //   setLoading(true);

  //   const requestBody = {
  //     cities: cities,
  //     radius: "100 miles",
  //   };
  //   fetchAirportController.current = new AbortController();
  //   try {
  //     const data = await getAirports(
  //       requestBody,
  //       fetchAirportController.current
  //     );
  //     if (fetchAirportController.current.signal.aborted) {
  //       return null;
  //     }
  //     setAirPorts(data);
  //   } catch (err) {
  //     setAirPorts([]);
  //     toastMessage.error("Failed to fetch flight data");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   if (airPorts?.length === 0) {
  //     handleFlightSearch();
  //   }
  // }, [airPorts]);
  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <>
            <h1 className={styles.heading}>Departure Airport</h1>
            {airPorts &&
            airPorts.filter((city: AirportDataTypes) => city.city === fromCity)
              .length === 0 ? (
              <p className={styles.errorMessage}>
                There are no nearby airports
              </p>
            ) : (
              <FormControl className={styles.formControlContainer}>
                <RadioGroup
                  key={indexNumber}
                  onChange={(e) => handleRadioDepartureChange(e, indexNumber)}
                  value={departure}>
                  {airPorts &&
                    airPorts
                      .filter(
                        (city: AirportDataTypes) => city.city === fromCity
                      )
                      .map((city: AirportDataTypes, airportIndex: number) => (
                        <React.Fragment key={airportIndex}>
                          {city.airports.slice(0, 5).map((airport: Airport) => (
                            <FormControlLabel
                              key={airport.code}
                              value={airport.code}
                              control={<CustomRadio />}
                              label={
                                <p
                                  className={styles.airportLabel}
                                  style={{
                                    color:
                                      airport.code ===
                                      (bookingDetails?.airPort[indexNumber]
                                        ?.departure ?? "")
                                        ? "#222222"
                                        : "#696969",
                                  }}>
                                  {airport.code} - {airport.fullName}
                                </p>
                              }
                            />
                          ))}
                        </React.Fragment>
                      ))}
                </RadioGroup>
              </FormControl>
            )}

            <h1 className={styles.heading}>Arrival Airport</h1>
            {airPorts &&
            airPorts.filter(
              (city: AirportDataTypes) => city.city === destinationCity
            ).length === 0 ? (
              <p className={styles.errorMessage}>
                There are no nearby airports
              </p>
            ) : (
              <FormControl className={styles.formControlContainer}>
                <RadioGroup
                  key={indexNumber}
                  name={`radioArrival-${indexNumber}`}
                  onChange={(e) => handleRadioArrivalChange(e, indexNumber)}
                  value={arrival}>
                  {airPorts &&
                    airPorts
                      .filter(
                        (city: AirportDataTypes) => city.city !== fromCity
                      )
                      .map((city: AirportDataTypes, airportIndex: number) => (
                        <React.Fragment key={airportIndex}>
                          {city.airports.slice(0, 5).map((airport: Airport) => (
                            <FormControlLabel
                              key={airport.code}
                              value={airport.code}
                              control={<CustomRadio />}
                              label={
                                <p
                                  className={styles.airportLabel}
                                  style={{
                                    color:
                                      airport.code ===
                                      (bookingDetails?.airPort[indexNumber]
                                        ?.arrival ?? "")
                                        ? "#222222"
                                        : "#696969",
                                  }}>
                                  {airport.code} - {airport.fullName}
                                  <br />
                                  <p className={styles.distance}>
                                    {airport.distance} miles from {city.city}
                                  </p>
                                </p>
                              }
                            />
                          ))}
                        </React.Fragment>
                      ))}
                </RadioGroup>
              </FormControl>
            )}
          </>
        </div>
      }
      handleApplyButton={() => {}}
    />
  );
}

export function CustomRadio(props: RadioProps) {
  return (
    <Radio
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon} />}
    />
  );
}
