import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./exploreTripAndAssistance.module.scss";
import InputWithMic from "../reusableComponent/inputWithMic/InputWithMic";
import { iAppConfig, iChatData } from "../../types";
import ChatContent from "../../routes/topDestination/chatContent/ChatContent";
import { exploreCityAPI } from "../../api/llm/exploreCityAPI";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { v4 } from "uuid";
import { getConfigMessage, scrollToBottom } from "../../utils";
import { backIcon, exploreCityLogo, zenvoyaChatIcon } from "../../constant";
import { APP_CONFIG } from "../../constant/common";
import Assistance from "../reusableComponent/assistance/Assistance";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { iCurrentTripContext } from "../../context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "../../context/currentTripContext/currentTripContext";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
let abortChatController = new AbortController();

export default function ExploreTripAndAssistance() {
  const [assistancChatData, setAssistanceChatData] = useState(
    [] as iChatData[]
  );
  const globalContext = useContext(StateContext);
  const {
    userDetails,
    appConfig,
    isMobile,
    setScreen,
    scrollContainerRef,
  }: iGlobalContext = globalContext;
  const { currentTripData }: iCurrentTripContext = useCurrentTripContext();
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [chatInputText, setChatInputText] = useState("");

  // console.log({ currentTripData });

  async function handleSendMessage(message: string) {
    // console.log(message);
    triggerGAEvent(GA_LABEL_ENUMS.explore_city_input_click);
    if (message === "") return;
    setAssistanceChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: chatInputText,
        isUserMessage: true,
        prompt: [],
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "",
        prompt: [],
        isUserMessage: false,
        isLoading: true,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
    setChatInputText("");
    setDisableInput(true);

    abortChatController.abort();
    abortChatController = new AbortController();
    scrollToBottom(scrollContainerRef);
    const response = await exploreCityAPI(
      userDetails?.id || "",
      message,
      // assistancChatData.length === 0
      //   ? []
      //   : assistancChatData[assistancChatData.length - 1].prompt,
      currentTripData?.data || {},
      abortChatController.signal
    );

    if (response) {
      setAssistanceChatData((prev: iChatData[]) => [
        ...prev.splice(0, prev.length - 1),
        {
          id: prev[prev.length - 1].id,
          message: response?.data?.result || "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: false,
          destinationList: [],
        },
      ]);
    } else {
      if (!abortChatController.signal.aborted) {
        setAssistanceChatData((prev: iChatData[]) => [
          ...prev.splice(0, prev.length - 1),
        ]);
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    }
    scrollToBottom(scrollContainerRef);
    setDisableInput(false);
    // console.log(response);
  }

  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.explore_city_page_load);
    if (chatInputText === "") scrollToBottom();
    // console.log(assistancChatData, assistancChatData?.length - 1);
  }, [assistancChatData]);

  function handleCancel() {
    const tempassistancChatData = [...assistancChatData];
    setAssistanceChatData(tempassistancChatData.slice(0, -2));
    triggerGAEvent(GA_LABEL_ENUMS.explore_city_stop_click);
    abortChatController.abort();
  }

  let parseData = {} as any;
  let city = "";
  if (currentTripData?.metaData) {
    parseData = JSON.parse(currentTripData?.metaData)[0]?.selectedChatData;
    city =
      parseData?.destinationList[0]?.city_name ||
      parseData?.destinationList[0]?.destination_city ||
      parseData?.destinationList[0]?.cityName?.split(",")[0];
  }

  const handleBackClick = useCallback(() => {
    triggerGAEvent(GA_LABEL_ENUMS.explore_city_back_click);
  }, []);

  const handleListeningFunc = useCallback(() => {
    triggerGAEvent(GA_LABEL_ENUMS.explore_city_mic_send_click);
  }, []);

  const handleStopListeningFunc = useCallback(() => {
    triggerGAEvent(GA_LABEL_ENUMS.explore_city_mic_stop_click);
  }, []);

  return (
    <Assistance
      placeholder={`Ask about ${city || ""}`}
      title="EXPLORE THE CITY"
      headingText={`What would you like to know about ${city || ""}?`}
      chatData={assistancChatData}
      appConfig={appConfig}
      appConfigKey={APP_CONFIG.EXPLORE_TRIP_INFO}
      disableInput={disableInput}
      handleSendMessage={handleSendMessage}
      chatInputText={chatInputText}
      setChatInputText={setChatInputText}
      handleCancel={handleCancel}
      onBackClick={handleBackClick}
      onMicStartListeningFunc={handleListeningFunc}
      onMicStopListeningFunc={handleStopListeningFunc}
    />
  );
}
