import { useRef, useContext, useEffect, useState } from "react";
import {
  ROUTES,
  SENDING_CHAT_MESSAGE,
  VIEW_RECOMMENDED_TRIPS,
  zenvoyaChatIcon,
  newTripLogo,
  exploreCityLogo,
} from "../../constant";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { iGlobalContext } from "../../context/globalContext/interface";
import { StateContext } from "../../context/globalContext/context";
import { getFrequentlyViewTripsApi } from "../../api/getFrequentlyViewTrips";
import SpeechRecognition from "react-speech-recognition";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { v4 } from "uuid";
import { iAppConfig } from "../../types";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import {
  checkIfThereIsDestination,
  getConfigMessage,
  scrollToBottom,
} from "../../utils";
import Loader from "../../component/reusableComponent/loader/Loader";
import { RESPONSE_DUMMY } from "../../constant/responseDummy";
import InitialChat from "../../component/desktopLandingPage/initialChat/initialChat";
import ReactGA from "react-ga4";
import { APP_CONFIG } from "../../constant/common";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { localStorageName } from "../../enums";
import styles from "./new.module.scss";
import { iRecommendedTripContext } from "../../context/recommendedTripContext/recommendedTripContextInterface";
import { useRecommendedTripContext } from "../../context/recommendedTripContext/recommendedTripContext";

function NewTrip(): JSX.Element {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    setShowMoreFlag,
    setApiResponse,
    isMobile,
    setOpenOverlayScreen,
    setChatData,
    setSharedItineraryId,
    setIsItinerarySavedFlag,
    setenableMultiSelect,
    userDetails,
    appConfig,
    setSelectedChatData,
    setPrevRoute,
  }: iGlobalContext = context;

  const {
    frequentlyViewTrips,
    index,
    handleScrollLeft,
    sliderRef,
    handleViewItineraryDetails,
    handleScrollRight,
    disableInput,
    handleCreateItinerary,
    scrollContainerRef,
    handleCancel,
    setDisableInput,
  }: iRecommendedTripContext = useRecommendedTripContext();

  const navigate = useNavigate();
  return (
    // <div className={styles.main}>
    //   <div className={styles.container}>
    //     <div className={styles.logoCard}>
    //       <img src={newTripLogo} alt="" className={styles.logo} />
    //     </div>
    //     <div className={styles.inputCard}>
    //       <div className={styles.inputContainer}>
    //         <div className={styles.contentContainer}>
    //           <div className={styles.sectionContainer}>
    //             <div className={styles.systemChatIcon}>
    //               <img
    //                 className={styles.chatIcon}
    //                 alt="chat logo"
    //                 src={zenvoyaChatIcon}
    //               />
    //               <h3 className={styles.heading}>zenvoya</h3>
    //             </div>
    //             <div
    //               className={styles.constText}
    //               dangerouslySetInnerHTML={{
    //                 __html:
    //                   appConfig?.length > 0
    //                     ? appConfig?.filter(
    //                         (ele: iAppConfig) =>
    //                           ele.key === APP_CONFIG.HOME_CONSTANT_1
    //                       )[0]?.value
    //                     : "",
    //               }}
    //             />
    //           </div>
    //           {disableInput && (
    //             <div className={styles.chatLoaderContainer}>
    //               <div className={styles.chatLoaderSubContainer}>
    //                 <InitialChat message={chatInputText} />
    //               </div>
    //             </div>
    //           )}
    //           {disableInput && <Loader />}
    //         </div>
    //         <div className={styles.input}>
    //         <InputWithMic
    //           onStopRecording={handleCreateItinerary}
    //           onSend={handleCreateItinerary}
    //           value={chatInputText}
    //           setValue={setChatInputText}
    //           disable={disableInput}
    //           handleCancel={handleCancel}
    //           setDisableInput={setDisableInput}
    //           disableSendBtn={chatInputText === "" ? true : false}
    //         />
    //        </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div
      className={styles.superContainer}
      // style={{
      //   justifyContent: chatData?.length > 0 ? "flex-start" : "flex-end",
      // }}
    >
      <div className={styles.desktopScrollContainer} ref={scrollContainerRef}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src={exploreCityLogo}
            alt="assistanceIcon"
          />
          {/* <h1>{title}</h1> */}
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.systemChatIcon}>
            <img
              className={styles.chatIcon}
              alt="chat logo"
              src={zenvoyaChatIcon}
            />
            <h3 className={styles.heading}>zenvoya</h3>
          </div>
          <div
            className={styles.constText}
            dangerouslySetInnerHTML={{
              __html:
                appConfig?.length > 0
                  ? appConfig?.filter(
                      (ele: iAppConfig) =>
                        ele.key === APP_CONFIG.HOME_CONSTANT_1
                    )[0]?.value
                  : "",
            }}
          />
        </div>
        {disableInput && (
          <div className={styles.chatLoaderContainer}>
            <div className={styles.chatLoaderSubContainer}>
              <InitialChat message={chatInputText} />
            </div>
          </div>
        )}
        {disableInput && <Loader />}
        {/* {chatData.map((eleChatData: iChatData, index: number) => (
        <div key={index}>
          {!eleChatData?.singleSelectedDestination && (
            <ChatContent
              paddingLeft={true}
              isUserMessage={eleChatData.isUserMessage}
              message={eleChatData.message}
              preText={eleChatData.preText}
              isLoading={
                eleChatData?.destinationList?.length > 0
                  ? false
                  : eleChatData.isLoading
              }
            />
          )}
        </div>
      ))} */}

        <div className={styles.inputContainer}>
          <InputWithMic
            onStopRecording={handleCreateItinerary}
            onSend={handleCreateItinerary}
            value={chatInputText}
            setValue={setChatInputText}
            disable={disableInput}
            handleCancel={handleCancel}
            setDisableInput={setDisableInput}
            disableSendBtn={chatInputText === "" ? true : false}
          />
        </div>
      </div>
    </div>
  );
}

export default NewTrip;
