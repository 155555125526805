import { useEffect, useState } from "react";
import { arrowRightArrowLeftRegular } from "../../../../constant";
import { BookingDetails } from "../../../../types";
import styles from "./air.module.scss";
import AirportSelectionPopover from "../../bookingPopover/airportSelectionPopover/AirportSelectionPopover";

interface iProps {
  className?: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  departure: string;
  arrival: string;
  fromCity: string;
  destinationCity: string;
  handleUpdateData: () => void;
  arrivalCity?: string;
  departureCity?: string;
}

function AirPortPicker({
  className,
  bookingDetails,
  setBookingDetails,
  departure,
  arrival,
  fromCity,
  destinationCity,
  handleUpdateData,
  arrivalCity,
  departureCity
}: iProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <>
      {bookingDetails && (
        <AirportSelectionPopover
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          handleApplyButton={handleUpdateData}
          arrivalCity={arrivalCity}
          departureCity={departureCity}
          popoverTrigger={
            <div className={`${className} ${styles.main}`}>
              <p className={styles.label}>AIRPORTS</p>
              <div
                className={`${isPopoverOpen ? styles.activeBtn : styles.btn}`}>
                {!departure ? (
                  <section className={styles.card}>
                    <p className={styles.btnName}>
                      {" "}
                      {bookingDetails.airportFrom
                        ? bookingDetails.airportFrom
                        : ""}
                    </p>
                    <p className={styles.city}></p>
                  </section>
                ) : (
                  <div className={styles.card}>
                    <p className={styles.btnName}>{departure}</p>
                    <p className={styles.city}>{fromCity}</p>
                  </div>
                )}
                <img
                  src={arrowRightArrowLeftRegular}
                  className={styles.arrowIcon}
                  alt=""
                />
                {!arrival ? (
                  <section className={styles.card}>
                    <p className={styles.btnName}>
                      {" "}
                      {bookingDetails.airportTo ? bookingDetails.airportTo : ""}
                    </p>
                    <p className={styles.city}></p>
                  </section>
                ) : (
                  <section className={styles.card}>
                    <p className={styles.btnName}>{arrival}</p>
                    <p className={styles.city}>{destinationCity}</p>
                  </section>
                )}
              </div>
            </div>
          }
        />
      )}
    </>
  );
}

export default AirPortPicker;
