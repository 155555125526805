import React, { useContext, useState } from "react";
import {
  ROUTES,
  swipeLeftTr,
  transparentSwipeRightIcon,
} from "../../../constant";
import styles from "./carousel.module.scss";
import { upcomingDummyTripData } from "../../../constant/dummyData";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { StateContext } from "../../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import { ScreenTypes } from "../../../enums";
import {
  getUTCMonthDate,
  getTwelveHourTimeFormat,
  getYear,
} from "../../../utils/dateUtils";
import UpcomingTripCard from "../../reusableComponent/upcomingTripCard/UpcomingTripCard";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

interface iProps {
  className?: string;
  carouselArr: any[];
  noPaddSwipeBtn?: boolean;
}

function UpcomingTripCarousel(props: iProps): JSX.Element {
  const { carouselArr = [], className, noPaddSwipeBtn = false } = props;
  const [sliderIndex, setSliderIndex] = useState(0);
  const globalContext = useContext(StateContext);
  const {
    setTripOrItinearyId,
    setPrevRoute,
    isMobile,
    setScreen,
  }: iGlobalContext = globalContext;
  const navigate = useNavigate();
  return (
    <div className={`${className} ${styles.imageContainer}`}>
      {carouselArr.length > 0 && sliderIndex !== 0 && (
        <img
          className={`${styles.rightBtn} ${styles.leftSwipeBtn} ${noPaddSwipeBtn && styles.removeLeftPadd}`}
          src={swipeLeftTr}
          alt="left swipe btn"
          onClick={() => {
            if (sliderIndex > 0) {
              setSliderIndex((prev) => prev - 1);
              return;
            }
            // setSliderIndex(carouselArr.length - 1);
          }}
        />
      )}
      {carouselArr.map((ele, index) => {
        return (
          <div
            className={`${styles.horizontalImages} ${noPaddSwipeBtn && styles.addExtraPadd} ${className}`}
            style={{
              transform: `translateX(${(index - sliderIndex) * 100}%)`,
            }}
          >
            <UpcomingTripCard
              data={ele}
              onCardClick={() => {
                // console.log("YES");
                triggerGAEvent(GA_LABEL_ENUMS.home_upcoming_trip_click);
                setTripOrItinearyId(ele?.id);
                setPrevRoute(ROUTES.Home);
                if (isMobile) {
                  navigate(ROUTES.TripDetails);
                } else {
                  setScreen(ScreenTypes.TripDetails);
                  // console.log("YES DES");
                }
              }}
            />
          </div>
        );
      })}
      {carouselArr.length > 0 && sliderIndex !== carouselArr.length - 1 && (
        <img
          className={`${styles.rightBtn} ${noPaddSwipeBtn && styles.removeRightPadd}`}
          src={transparentSwipeRightIcon}
          alt="right btn"
          onClick={() => {
            if (sliderIndex < carouselArr.length - 1) {
              setSliderIndex((prev) => prev + 1);
              return;
            }
            // setSliderIndex(0);
          }}
        />
      )}
    </div>
  );
}

export default UpcomingTripCarousel;
