import { TOAST_MESSAGES } from "./../constant/toastMessages";
import axios from "axios";
import { ENDPOINT } from "../constant/environment";
import { toastMessage } from "../helpers/toast/toastMessage";

// export const getFlightAvailability = async (
//   encryptedKey: string,
//   uniqueId: string,
//   controller: AbortController
// ) => {
//   const URL = `${ENDPOINT}/app/v1/flight/check/${encryptedKey}-${uniqueId}`;
//   const config = {
//     headers: {
//       "Content-Type": "application/json;charset=UTF-8",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Headers": "*",
//     },
//     signal: controller.signal,
//   };
//   try {
//     const response = await axios.get(URL, config);
//     return response?.data;
//   } catch (error: unknown) {
//     if (error instanceof Error) {
//       console.error("Error fetching flight availability:", error.message);
//       // toastMessage.error("Failed to retrieve flight availability");
//     } else {
//       console.error("Unknown error:", error);
//       toastMessage.error("An unknown error occurred");
//     }
//   }
// };

export const getFlightAvailability = async (
  encryptedKey: string,
  uniqueId: string,
  controller: AbortController
) => {
  const URL = `${ENDPOINT}/app/v1/flight/check/${encryptedKey}-${uniqueId}`;
  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    signal: controller.signal,
  };

  try {
    const response = await axios.get(URL, config);
    if (
      response?.data?.statusCode === 400 ||
      response?.data?.statusCode === 404
    ) {
      toastMessage.error(
        TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.messageTitle,
        TOAST_MESSAGES.BOOKING.Search.FlightAvailabilityFailure.message
      );
    }
    return response?.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      if (error?.name === "CanceledError") {
        console.error("API call was aborted");
        return;
      }

      console.error("Error fetching flight availability:", error.message);
      toastMessage.error("OOPS!", "Failed to retrieve flight availability");
    } else {
      console.error("Unknown error:", error);
      toastMessage.error("An unknown error occurred");
    }
  }
};

export const getFlightDetails = async (
  encryptedKey: string,
  uniqueId: string
) => {
  const URL = `${ENDPOINT}/app/v1/flight/${encryptedKey}-${uniqueId}`;
  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  try {
    const response = await axios.get(URL, config);
    return response?.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Error fetching flight details:", error.message);
      toastMessage.error("Error fetching flight details");
    } else {
      console.error("Unknown error:", error);
      toastMessage.error("An unknown error occurred");
    }
  }
};

const handleError = (
  statusCode: number,
  messageType: "flight" | "hotel" | "booking"
) => {
  if (!statusCode || !messageType) {
    return;
  }

  switch (statusCode) {
    case 400:
      if (messageType === "flight") {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.message
        );
      } else if (messageType === "hotel") {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
        );
      }
      break;
    case 401:
      toastMessage.error(
        TOAST_MESSAGES.MessageFor401.messageTitle,
        TOAST_MESSAGES.MessageFor401.message
      );
      break;
    case 402:
      toastMessage.error(
        TOAST_MESSAGES.MessageFor402.messageTitle,
        TOAST_MESSAGES.MessageFor402.message
      );
      break;
    case 403:
      toastMessage.error(
        TOAST_MESSAGES.MessageFor403.messageTitle,
        TOAST_MESSAGES.MessageFor403.message
      );
      break;
    case 404:
      if (messageType === "flight") {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.message
        );
      } else if (messageType === "hotel") {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.NoHotelsFound.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.NoHotelsFound.message
        );
      }
      break;
    default:
      toastMessage.error(
        TOAST_MESSAGES.ApiBreakdown.messageTitle,
        TOAST_MESSAGES.ApiBreakdown.message
      );
  }
};

export const searchFlights = async (
  requestBody: any,
  controller: AbortController
) => {
  const url = `${ENDPOINT}/app/v1/flight/search`;
  try {
    const response = await fetch(url, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (data.statusCode >= 400 && data.statusCode <= 404) {
      handleError(data?.statusCode, "flight");
      return;
    }
    return data.data;
  } catch (error) {
    console.error("Error searching for flights:", error);
    // toastMessage.error("Error searching for flights");
    throw error;
  }
};

export const searchHotels = async (
  requestBody: any,
  controller: AbortController
) => {
  const url = `${ENDPOINT}/app/v1/hotel/search`;
  try {
    const response = await fetch(url, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      toastMessage.error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (data.statusCode >= 400 && data.statusCode <= 404) {
      handleError(data?.statusCode, "hotel");
      return;
    }
    return data.data;
  } catch (error) {
    console.error("Error searching for hotels:", error);
    // toastMessage.error("Error searching for hotels");
    throw error;
  }
};

export const getAirports = async (
  requestBody: any,
  controller: AbortController
) => {
  const url = `${ENDPOINT}/app/v1/flight/airports`;
  try {
    const response = await fetch(url, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (data.statusCode && data.statusCode === 400) {
      console.error("OOPS!", data.message);
      return;
    }
    return data.data;
  } catch (error) {
    console.error("Error searching for airports:", error);
    throw error;
  }
};
