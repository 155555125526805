import { ToastType } from "../types";

export const TOAST_MESSAGES = {
  ApiBreakdown: {
    type: ToastType.ERROR,
    messageTitle: "OOPS!",
    message: "Something went wrong!",
  },
  MessageFor401: {
    type: ToastType.ERROR,
    messageTitle: "Unauthorized",
    message:
      "You are not authorized to access this resource. Please log in and try again.",
  },
  MessageFor402: {
    type: ToastType.ERROR,
    messageTitle: "Access Required!",
    message: "Please get the access to proceed.",
  },

  MessageFor403: {
    type: ToastType.ERROR,
    messageTitle: "Forbidden!",
    message: "You do not have permission to access this resource",
  },
  BOOKING: {
    Search: {
      FlightSearchSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Flight data fetched successfully",
      },
      NoFlightsFound: {
        messageTitle: "Sorry",
        message:
          "There are no flights available for selected dates and destination. Please try again by changing your dates.",
      },
      NoFlightsFoundScreenMessage: {
        messageTitle: "Sorry",
        message: "No flights found",
      },
      FlightSearchFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "having trouble getting flight results. Please try again later!",
      },
      HotelSearchSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Hotel data fetched successfully",
        message: "Success!",
      },
      NoHotelsFound: {
        messageTitle: "Sorry",
        message:
          "There are no hotels available for selected dates and destination. Please try again by changing your dates.",
      },
      NoHotelsFoundScreenMessage: {
        messageTitle: "Sorry",
        message: "No hotels found",
      },
      HotelSearchFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Having trouble getting hotel results. Please try again later!,",
      },
      FlightAvailabilitySuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Flight available!",
      },
      FlightAvailabilityFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Selected flight is no longer available. Please select another flight or search  again!",
      },
      FlightDetailsFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Having trouble getting flight details. Please try again later!",
      },
      HotelAvailabilitySuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Hotel available!",
      },
      HotelAvailabilityFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Selected hotel is no longer available. Please select another hotel or search  again! ",
      },
      HotelRoomDetailsFailure: {
        type: ToastType.ERROR,
        messageTitle: "Sorry",
        message:
          "Having trouble getting room rates for selected hotel. Please try again later!",
      },
    },
    TravelerInfo: {
      formValidationSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "SUCCESS!",
        message: "Your data has been updated successfully.",
      },
      formValidationFailure: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Missing required information! Please enter missing information and try again!",
      },
      invalidDate: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Invalid Date of Birth. Please enter correct Date and try again!",
      },
      invalidYear: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Invalid Year for Date of Birth. Please enter correct Year and try again!",
      },
      flyerProgramError: {
        type: ToastType.ERROR,
        messageTitle: "",
        message:
          "Invalid Frequent Flyer Number. Please enter correct information and try again!",
      },
    },
    BOOK: {
      bookingSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Booking Complete!",
        message:
          "Your booking has been completed successfully, with the confirmation number below.",
      },
      bookingHotelSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Booking Complete!",
        message: "Your hotel booking has been completed successfully",
      },
      bookingFlightSuccess: {
        type: ToastType.SUCCESS,
        messageTitle: "Booking Complete!",
        message: "Your flight booking has been completed successfully",
      },
      bookFailure: {
        type: ToastType.ERROR,
        messageTitle: "OOPS!",
        message: "Something went wrong!",
      },
    },
  },
  PROFILE: {
    profileUpdateSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your pofile is successfully updated!",
    },
    profileUpdateFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Having trouble updating your profile. Please try again later!",
    },
    profileFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Something went wrong",
    },
    profilePictureUpdateSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your photo is updated.",
    },
    profilePictureUpdateFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Having trouble updating your photo. Please try again later!",
    },
  },
  PREFERENCES: {
    preferencesUpdateSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your preferences are successfully updated!",
    },
    preferencesUpdateFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message:
        "Having trouble updating your preferences. Please try again later!",
    },
    preferenceFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Something went wrong",
    },
  },
  AUTHENTICATION: {
    loginSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Logged in successfully",
    },
    loginFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "Invalid credentials! Please try again with correct credentials!",
    },
    logoutSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Logged out successfully",
    },
    logoutError: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Failed to logout from your account. Please try again later!",
    },
    accountCreationSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your account is successfully created!",
    },
    accountCreationFailure: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Failed to create account. Please try again later!",
    },
    accountCreationInvalidName: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Please enter your name",
    },
    accountCreationInvalidEmail: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Please enter valid email address",
    },
    accountCreationEmailExist: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Email id already exist",
    },
    accountCreationInvalidPassword: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Password must be at-least 6 character",
    },
    forgetPasswordInvalidEmail: {
      type: ToastType.ERROR,
      messageTitle: "",
      message: "Please enter valid email address",
    },
    forgetPasswordEmailSentError: {
      type: ToastType.ERROR,
      messageTitle: "OOPS!",
      message: "Unable to send the password link to your email",
    },
    forgetPasswordEmailSentSuccess: {
      type: ToastType.ERROR,
      messageTitle: "SUCCESS!",
      message:
        "Reset password link sent to your email, please check your email inbox!",
    },
  },
  SaveItinerary: {
    inputFieldEnter: {
      type: ToastType.INFO,
      messageTitle: "Please",
      message: "Please type your message before hitting send!",
    },
    inputFieldLoad: {
      type: ToastType.INFO,
      messageTitle: "Please wait!",
      message:
        "Processing your previous request. Either cancel or your previous request to wait it to complete!",
    },
    saveSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your itinerarry successfully saved!",
    },
    saveFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "We are having trouble saving your itinerary. Please try again later!",
    },
    destinationError: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message: "Having trouble with parsing JSON data. Please try again later!",
    },
  },
  ShareItinerary: {
    shareSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your itinerary has been shared!",
    },
    shareFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "Having trouble with sharing your itinerary. Please try again later!",
    },
  },
  EditItinerary: {
    editSuccess: {
      type: ToastType.SUCCESS,
      messageTitle: "SUCCESS!",
      message: "Your itinerary has been updated!",
    },
    editFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message:
        "Having trouble with updating your itinerary. Please try again later!",
    },
  },
  FAQ: {
    faqFailure: {
      type: ToastType.ERROR,
      messageTitle: "Sorry",
      message: "Having trouble with getting FAQ. Please try again later!",
    },
  },
  TravelerInfoInputFiledNoData: {
    type: ToastType.ERROR,
    messageTitle: "Sorry",
    message: "No data found",
  },
};
